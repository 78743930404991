import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, Row, Col, Spinner } from 'reactstrap'
import {
    FaArrowAltCircleRight,
    FaPencilAlt,
    FaTrash,
    FaRedoAlt,
    FaAward,
} from 'react-icons/fa'
import { toast } from 'react-toastify'

import { useNavigate } from 'react-router-dom'
import { SelectInterface } from '../../models/select'
import { fetchClubsSelectStart } from '../../store/actions/clubs'
import {
    createChampionshipStart,
    deleteChampionshipStart,
    editChampionshipStart,
    fetchChampionshipsStart,
} from '../../store/actions/championships'
import { IRootState } from '../../store/reducers'
import CustomTable from '../../components/CustomTable'
import Container from '../../components/Container'

import {
    Championship,
    ChampionshipsStateLabels,
    ChampionshipsStructureLabels,
    ChampionshipsTypeBestPigeonLabels,
    ChampionshipsTypeInscriptionLabels,
} from '../../models/championship'
import CustomModal from '../../components/CustomModal'
import useSearch from '../../hooks/useSearch'
import { TypeProfiles, User } from '../../models/user'
import userHelper from '../../utils/userHelper'
import FormChangeStateChampionship from '../../components/FormChangeStateChampionship'
import FormChampionship from '../../components/FormChampionship'
import ModalConfirm from '../../components/ModalConfirm'
import { getPermissions } from '../../utils/permissions'

import { TableButton } from '../../styles/buttons/tableButtons'
import { setChampionshipId, setRaceId } from '../../store/actions/racings'

import {
    FormActions,
    crudMessagesSuccess,
    crudMessagesError,
} from '../../utils/crudHelper'

function Championships() {
    const dispatch = useDispatch()
    const [action, setAction] = useState(FormActions.CREATE)
    const [championships, setChampionships] = useState<Championship[]>([])
    const [championshipEdit, setChampionshipEdit] = useState<Championship>(null)
    const [clubsSelect, setClubsSelect] = useState<SelectInterface[]>([])
    const [modalVisible, setModalVisible] = useState<boolean>(false)
    const [modalConfirmDeleteVisible, setModalConfirmDeleteVisible] =
        useState<boolean>(false)
    const [modalChangeStateVisible, setModalChangeStateVisible] =
        useState<boolean>(false)

    const [listPublic, setListPublic] = useState(false)

    const isLoadingChampionships = useSelector(
        (state: IRootState) => state.championshipsReducer.loadingChampionships
    )
    const userLogged: User = useSelector(
        (state: IRootState) => state.authReducer.user
    )
    const canEditClub = userHelper.canEdit(userLogged)
    const navigate = useNavigate()

    const initialValuesIntersocial = {
        name: '',
        isPublic: false,
        state: 1,
        clubId: userLogged?.clubId ?? null,
        structure: 1,
        typeBestPigeon: 1,
        year: 2022,
        maxPigeons: 0,
        eyePigeonFancier: false,
        runLimited: false,
        runSpecial: false,
        typeInscription: 1,
        intersocialClubs: [],
        isIntersocial: false,
    }

    const fetchClubsSelect = () => {
        dispatch(
            fetchClubsSelectStart((clubsFetched) =>
                setClubsSelect(clubsFetched)
            )
        )
    }
    const fetchChampionships = () =>
        dispatch(
            fetchChampionshipsStart(
                (championshipsFetched) =>
                    setChampionships(championshipsFetched),
                listPublic ?? false
            )
        )

    const onError = (errorMessage: string | null) => {
        const message = errorMessage ?? crudMessagesError[action]
        toast.error(message)
    }

    const toggleModalVisible = () => {
        setModalVisible(!modalVisible)
    }
    const toggleModalChangeStateVisible = () => {
        setModalChangeStateVisible(!modalChangeStateVisible)
    }
    const toggleConfirmDeleteModal = () => {
        setModalConfirmDeleteVisible(!modalConfirmDeleteVisible)
    }
    const openCreateModal = () => {
        setAction(FormActions.CREATE)
        setChampionshipEdit(null)
        toggleModalVisible()
    }
    const { championships: championshipsPermissions } = getPermissions(
        userLogged?.profile
    )
    const {
        create: canCreateChampionship,
        edit: canEditChampionship,
        delete: canDeleteChampionship,
    } = championshipsPermissions

    const goToPigeonMarkedPositions = (championshipId: any) => {
        if (championshipId) {
            dispatch(setRaceId(null))
            dispatch(setChampionshipId([championshipId]))
            navigate('/positions')
        }
    }

    const ActionButton = useCallback(
        ({ row }) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                    color="primary"
                    onClick={() => {
                        setModalVisible(true)
                        setChampionshipEdit(row)
                        setAction(FormActions.EDIT)
                    }}
                    disabled={
                        !canEditChampionship ||
                        row.clubId !== userLogged?.clubId
                    }
                    className="p-1 pt-0 ms-2"
                >
                    <FaPencilAlt />
                </Button>
                <Button
                    color="warning"
                    onClick={() => {
                        setModalChangeStateVisible(true)
                        setChampionshipEdit(row)
                        setAction(FormActions.EDIT)
                    }}
                    disabled={
                        !canEditChampionship ||
                        row.clubId !== userLogged?.clubId
                    }
                    className="p-1 pt-0 ms-2"
                >
                    <FaArrowAltCircleRight />
                </Button>
                <Button
                    color="danger"
                    onClick={() => {
                        setModalConfirmDeleteVisible(true)
                        setChampionshipEdit(row)
                        setAction(FormActions.DELETE)
                    }}
                    disabled={
                        !canDeleteChampionship ||
                        row.clubId !== userLogged?.clubId
                    }
                    className="p-1 pt-0 ms-2"
                >
                    <FaTrash />
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        goToPigeonMarkedPositions(row)
                    }}
                    className="p-1 pt-0 ms-2 me-2"
                >
                    <FaAward color="white" />
                </Button>
            </div>
        ),
        []
    )

    const columns = [
        {
            name: 'Nombre',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Año',
            selector: (row) => row.year,
            sortable: true,
        },
        {
            name: 'Intersocial',
            selector: (row) => (row.isIntersocial ? 'Si' : 'No'),
            sortable: true,
        },
        {
            name: 'Estado',
            selector: (row) => ChampionshipsStateLabels[row.state] as string,
            sortable: true,
        },
        {
            name: 'Estructura',
            selector: (row) =>
                ChampionshipsStructureLabels[row.structure] as string,
            sortable: true,
        },
        {
            name: 'Tipo mejor paloma',
            selector: (row) =>
                ChampionshipsTypeBestPigeonLabels[row.typeBestPigeon] as string,
            sortable: true,
        },
        {
            name: 'Tipo inscripción',
            selector: (row) =>
                ChampionshipsTypeInscriptionLabels[
                    row.typeInscription
                ] as string,
            sortable: true,
        },
        {
            name: 'Acciones',
            cell: (row) =>
                ActionButton({
                    row,
                }),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            minWidth: '156px',
        },
    ]

    React.useEffect(() => {
        fetchClubsSelect()
        fetchChampionships()
    }, [listPublic])

    const createChampionship = (values) => {
        dispatch(
            createChampionshipStart(
                values,
                () => {
                    fetchChampionships()
                    toggleModalVisible()
                    toast.success(crudMessagesSuccess[action])
                },
                onError
            )
        )
    }

    const editChampionship = (values) => {
        dispatch(
            editChampionshipStart(
                values,
                championshipEdit?.id.toString(),
                () => {
                    fetchChampionships()
                    toggleModalVisible()
                    toast.success(crudMessagesSuccess[action])
                },
                onError
            )
        )
    }
    const changeStateChampionship = (values) => {
        dispatch(
            editChampionshipStart(
                values,
                championshipEdit?.id.toString(),
                () => {
                    fetchChampionships()
                    toggleModalChangeStateVisible()
                    toast.success(crudMessagesSuccess[action])
                },
                onError
            )
        )
    }
    const onDeleteChampionship = () => {
        dispatch(
            deleteChampionshipStart(
                championshipEdit?.id.toString(),
                () => {
                    fetchChampionships()
                    toggleConfirmDeleteModal()
                    toast.success(crudMessagesSuccess[action])
                },
                onError
            )
        )
    }

    const [filterText, setFilterText] = React.useState('')
    const translations = {
        state: ChampionshipsStateLabels,
        structure: ChampionshipsStructureLabels,
    }
    const { filteredItems } = useSearch(championships, filterText, translations)

    const SubHeaderComponentMemo = React.useMemo(() => {
        return (
            <div className="d-flex gap-">
                {userLogged.profile !== TypeProfiles.ADMIN && (
                    <div
                        className="d-flex align-items-center me-4 pe-2"
                        onClick={() => setListPublic(!listPublic)}
                        style={{ cursor: 'pointer' }}
                    >
                        Torneos Publicos
                        <Input
                            onChange={() => setListPublic(!listPublic)}
                            checked={listPublic}
                            type="checkbox"
                            className="ms-2"
                        />
                    </div>
                )}

                <div className="d-flex align-items-center me-3">
                    Buscar
                    <Input
                        className="ms-2"
                        onChange={(e) => setFilterText(e.target.value)}
                        value={filterText}
                    />
                </div>
                <div>
                    <TableButton type="button" onClick={fetchChampionships}>
                        <FaRedoAlt />
                    </TableButton>
                </div>
            </div>
        )
    }, [filterText, listPublic])

    return (
        <Container>
            <Row>
                <Col className="d-flex justify-content-between ">
                    <div>
                        <h2>Torneos</h2>
                    </div>
                    <Button
                        color="primary"
                        onClick={openCreateModal}
                        style={{
                            marginBottom: '1rem',
                        }}
                        disabled={!canCreateChampionship}
                    >
                        Crear Torneo
                    </Button>

                    <CustomModal
                        isOpen={modalVisible}
                        toggle={toggleModalVisible}
                        title={
                            championshipEdit ? 'Editar Torneo' : 'Crear Torneo'
                        }
                    >
                        <Row className="mt-3">
                            <FormChampionship
                                createChampionship={
                                    championshipEdit
                                        ? editChampionship
                                        : createChampionship
                                }
                                initialValues={
                                    championshipEdit || initialValuesIntersocial
                                }
                                isEdit={!!championshipEdit}
                                canEdit={canEditClub}
                                clubsSelect={clubsSelect}
                            />
                        </Row>
                    </CustomModal>
                    <CustomModal
                        isOpen={modalChangeStateVisible}
                        toggle={toggleModalChangeStateVisible}
                        title="Cambiar Estado Torneo"
                    >
                        <Row className="mt-3">
                            <FormChangeStateChampionship
                                editChampionship={changeStateChampionship}
                                initialValues={{
                                    state: championshipEdit?.state,
                                }}
                            />
                        </Row>
                    </CustomModal>
                    <ModalConfirm
                        isOpen={modalConfirmDeleteVisible}
                        toggle={toggleConfirmDeleteModal}
                        onConfirm={() => onDeleteChampionship()}
                        onCancel={() => setModalConfirmDeleteVisible(false)}
                        titleHeader={`Eliminar Club ${championshipEdit?.name}?`}
                        title={`¿Está seguro que desea eliminar el torneo ${championshipEdit?.name}?`}
                        confirmButtonText="Eliminar"
                    >
                        <FormChampionship
                            createChampionship={() => null}
                            initialValues={
                                championshipEdit || initialValuesIntersocial
                            }
                            isEdit={!!championshipEdit}
                            canEdit={canEditClub}
                            clubsSelect={clubsSelect}
                            disabled
                        />
                    </ModalConfirm>
                </Col>
            </Row>

            <Row>
                <Col>
                    <CustomTable
                        noHeader
                        striped
                        pagination
                        data={filteredItems}
                        subHeader
                        subHeaderComponent={SubHeaderComponentMemo}
                        progressPending={isLoadingChampionships}
                        progressComponent={
                            <Spinner color="primary" type="grow">
                                Cargando...
                            </Spinner>
                        }
                        columns={columns}
                        dense
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default Championships
