const searchInObject = (items, filterText, translations?: any) => {
    return items.filter((item) => {
        let found = false
        Object.keys(item).forEach((key) => {
            if (
                typeof item[key] === 'string' &&
                item[key].toLowerCase().includes(filterText.toLowerCase())
            ) {
                found = true
            } else if (
                typeof item[key] === 'object' &&
                item[key] &&
                item[key].name?.toLowerCase().includes(filterText.toLowerCase())
            ) {
                found = true
            } else if (
                typeof item[key] === 'number' &&
                translations &&
                Object.keys(translations).includes(key)
            ) {
                const value = item[key]
                const translation = translations[key][value]
                if (
                    translation &&
                    translation.toLowerCase().includes(filterText.toLowerCase())
                ) {
                    found = true
                }
            }
        })
        return found
    })
}

const searchHelper = {
    searchInObject,
}

export default searchHelper
