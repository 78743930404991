import axios from 'axios'
import env from '../env/config'

const { API_URL } = env
export const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        Accept: 'application/json',
    },
})

export const axiosFilesInstance = axios.create({
    baseURL: API_URL,
    responseType: 'blob',
    headers: {
        Accept: 'application/json',
    },
})
