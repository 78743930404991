import styled from 'styled-components'

export const TableButton = styled.button`
    border-radius: 4px;
    border: none;
    background: #008cba;
    color: white;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 10px;
    align-self: normal;
`
