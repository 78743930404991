import { Form, Formik, FormikErrors } from 'formik'
import { Button, Col, FormGroup, Label, Row } from 'reactstrap'
import React from 'react'
import { useSelector } from 'react-redux'
import InputForm from '../InputForm'
import { SelectInterface } from '../../models/select'
import { findSelected, parseSelectOptions } from '../../utils/selectHelper'
import { TypeProfiles, TypesClubUser, User } from '../../models/user'
import Select from '../Select'
import { IRootState } from '../../store/reducers'
import { getPermissions } from '../../utils/permissions'
import { getFormPermissions } from '../../utils/permissions/formPermissions'

export interface CreateUserFormInterface {
    username: string
    password?: string
    password2?: string
    email: string
    firstName: string
    lastName: string
    locationId: number
    clubId: number
    pigeonHouseId: number
    profile: number | string
    typeUserClub: number | string
}

interface FormUserProps {
    createUser: (values: CreateUserFormInterface) => void
    initialValues: CreateUserFormInterface
    locations: SelectInterface[]
    clubsSelect: SelectInterface[]
    pigeonHousesSelect: SelectInterface[]
    editMode: boolean
    disabled?: boolean
}

function FormUser({
    createUser,
    initialValues,
    locations,
    clubsSelect,
    pigeonHousesSelect,
    editMode = false,
    disabled = false,
}: FormUserProps) {
    const locationsMapped = parseSelectOptions(locations)
    const clubsMapped = parseSelectOptions(clubsSelect)
    const pigeonHousesMapped = parseSelectOptions(pigeonHousesSelect)
    const userLogged: User = useSelector(
        (state: IRootState) => state.authReducer.user
    )
    const { usersEdit: usersEditPermissions } = getFormPermissions(
        userLogged?.profile
    )

    const perfilesSelect = [
        {
            label: 'Administrador',
            value: TypeProfiles.ADMIN.toString(),
        },
        {
            label: 'Dueño de club',
            value: TypeProfiles.RACEMAKER.toString(),
        },
        {
            label: 'Socio',
            value: TypeProfiles.USER.toString(),
        },
    ]

    const typesClubsSelect = [
        {
            label: 'Presidente',
            value: TypesClubUser.PRESIDENTE.toString(),
        },
        {
            label: 'Vice Presidente',
            value: TypesClubUser.VICE_PRESIDENTE.toString(),
        },
        {
            label: 'Tesorero',
            value: TypesClubUser.TESORERO.toString(),
        },
        {
            label: 'Contacto',
            value: TypesClubUser.CONTACTO.toString(),
        },
        {
            label: 'Socio',
            value: TypesClubUser.SOCIO.toString(),
        },
    ]

    const validate = (data: CreateUserFormInterface) => {
        const errors: FormikErrors<CreateUserFormInterface> = {}

        if (!data.username) {
            errors.username = 'Campo obligatorio'
        }

        if (!editMode || (data.password && data.password.length > 0)) {
            if (!data.password || (data.password && data.password.length < 4)) {
                errors.password =
                    'La contraseña debe tener al menos 4 caracteres'
            } else if (
                !data.password2 ||
                (data.password2 && data.password2.length < 4)
            ) {
                errors.password2 = 'Campo obligatorio'
            } else if (data.password2 && data.password !== data.password2) {
                errors.password2 = 'La contraseña no coincide'
            }
        }

        if (!data.clubId) {
            errors.clubId = 'Campo obligatorio'
        }

        if (!data.email) {
            errors.email = 'Campo obligatorio'
        }

        if (!data.firstName) {
            errors.firstName = 'Campo obligatorio'
        }

        if (!data.lastName) {
            errors.lastName = 'Campo obligatorio'
        }

        if (!data.profile) {
            errors.profile = 'Campo obligatorio'
        }

        // if (data.clubId !== null) {
        //     if (!data.typeUserClub) {
        //         errors.typeUserClub = 'Campo obligatorio'
        //     }
        // }

        return errors
    }

    return (
        <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={(values, { setSubmitting }) => {
                createUser(values)
                setSubmitting(false)
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
            }) => (
                <Form className="form" onSubmit={handleSubmit}>
                    <Row>
                        <Col xs="12" md="6">
                            <FormGroup>
                                <Label for="username">Nombre de Usuario</Label>
                                <InputForm
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    placeholder="Nombre de Usuario"
                                    values={values}
                                    fieldName="username"
                                    disabled={disabled || editMode}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs="12" md="6">
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <InputForm
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    disabled={disabled || editMode}
                                    touched={touched}
                                    placeholder="Email"
                                    values={values}
                                    fieldName="email"
                                    type="email"
                                />
                            </FormGroup>
                        </Col>

                        <Col xs="12" md="6">
                            <FormGroup>
                                <Label for="firstName">Nombre</Label>
                                <InputForm
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    placeholder="Nombre"
                                    disabled={disabled}
                                    values={values}
                                    fieldName="firstName"
                                />
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="6">
                            <FormGroup>
                                <Label for="lastName">Apellido</Label>
                                <InputForm
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    placeholder="Apellido"
                                    disabled={disabled}
                                    values={values}
                                    fieldName="lastName"
                                />
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="6">
                            <FormGroup>
                                <Label for="locationId">Localidad</Label>
                                <Select
                                    options={locationsMapped}
                                    onChange={({ value }) =>
                                        setFieldValue('locationId', value)
                                    }
                                    fieldName="locationId"
                                    touched={touched}
                                    errors={errors}
                                    required
                                    disabled={
                                        disabled ||
                                        !usersEditPermissions.locationId
                                    }
                                    value={findSelected(
                                        locationsMapped,
                                        values.locationId
                                    )}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="6">
                            <FormGroup>
                                <Label for="profile">Perfil</Label>
                                <Select
                                    options={perfilesSelect}
                                    fieldName="profile"
                                    touched={touched}
                                    errors={errors}
                                    required
                                    disabled={
                                        disabled ||
                                        !usersEditPermissions.profile
                                    }
                                    onChange={({ value }) => {
                                        setFieldValue('profile', value)
                                        if (
                                            value ===
                                                TypeProfiles.USER.toString() &&
                                            values.clubId !== null
                                        ) {
                                            setFieldValue(
                                                'typeUserClub',
                                                TypesClubUser.SOCIO.toString()
                                            )
                                        }
                                    }}
                                    value={findSelected(
                                        perfilesSelect,
                                        values.profile
                                    )}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="6">
                            <FormGroup>
                                <Label for="clubId">Club</Label>
                                <Select
                                    options={clubsMapped}
                                    fieldName="clubId"
                                    touched={touched}
                                    errors={errors}
                                    required
                                    disabled={
                                        disabled || !usersEditPermissions.clubId
                                    }
                                    onChange={({ value }) => {
                                        setFieldValue('clubId', value)
                                        if (
                                            value !== null &&
                                            values.profile ===
                                                TypeProfiles.USER.toString()
                                        ) {
                                            setFieldValue(
                                                'typeUserClub',
                                                TypesClubUser.SOCIO.toString()
                                            )
                                        }
                                    }}
                                    value={findSelected(
                                        clubsMapped,
                                        values.clubId
                                    )}
                                />
                            </FormGroup>
                        </Col>
                        {/* <Col xs="12" md="6">
                            <FormGroup>
                                <Label for="typeUserClub">
                                    Tipo de usuario del club
                                </Label>
                                <Select
                                    options={typesClubsSelect}
                                    fieldName="typeUserClub"
                                    touched={touched}
                                    errors={errors}
                                    required
                                    onChange={({ value }) =>
                                        setFieldValue('typeUserClub', value)
                                    }
                                    disabled={
                                        disabled ||
                                        values.clubId === null ||
                                        values.profile === null ||
                                        values.profile.toString() ===
                                            TypeProfiles.USER.toString()
                                    }
                                    value={findSelected(
                                        typesClubsSelect,
                                        values.typeUserClub
                                    )}
                                />
                            </FormGroup>
                        </Col> */}
                        <Col xs="12" md="6">
                            <FormGroup>
                                <Label for="pigeonHouseId">Palomar</Label>
                                <Select
                                    fieldName="pigeonHouseId"
                                    touched={touched}
                                    errors={errors}
                                    options={pigeonHousesMapped}
                                    onChange={({ value }) =>
                                        setFieldValue('pigeonHouseId', value)
                                    }
                                    disabled={
                                        disabled ||
                                        !usersEditPermissions.pigeonHouseId
                                    }
                                    value={findSelected(
                                        pigeonHousesMapped,
                                        values.pigeonHouseId
                                    )}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" md="6">
                            <FormGroup>
                                <Label for="password">Contraseña</Label>
                                <InputForm
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    placeholder="Contraseña"
                                    values={values}
                                    fieldName="password"
                                    disabled={disabled}
                                    type="password"
                                />
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="6">
                            <FormGroup>
                                <Label for="password2">
                                    Repita la Contraseña
                                </Label>
                                <InputForm
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    placeholder="Contraseña"
                                    values={values}
                                    fieldName="password2"
                                    disabled={disabled}
                                    type="password"
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    {!disabled && (
                        <Row>
                            <Col className="mt-2 d-flex justify-content-end">
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                >
                                    Guardar
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Form>
            )}
        </Formik>
    )
}

FormUser.defaultProps = {
    disabled: false,
}

export default FormUser
