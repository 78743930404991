import React, { useCallback, useState } from 'react'
import { Button, Spinner, Row, Col, Input } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FaTrash, FaPencilAlt, FaRegEye, FaRedoAlt } from 'react-icons/fa'
import { toast } from 'react-toastify'

import { IRootState } from '../../store/reducers'
import { Club } from '../../models/club'
import {
    createClubStart,
    deleteClubStart,
    editClubStart,
    fetchClubsStart,
} from '../../store/actions/clubs'
import FormClub, { CreateClubFormInterface } from '../../components/FormClub'
import { SelectInterface } from '../../models/select'
import { fetchLocationsStart } from '../../store/actions/locations'
import ModalConfirm from '../../components/ModalConfirm'
import useSearch from '../../hooks/useSearch'

import CustomTable from '../../components/CustomTable'
import CustomModal from '../../components/CustomModal'
import Container from '../../components/Container'

import { User } from '../../models/user'
import { getPermissions } from '../../utils/permissions'

import {
    FormActions,
    crudMessagesSuccess,
    crudMessagesError,
} from '../../utils/crudHelper'

import { TableButton } from '../../styles/buttons/tableButtons'

const initialValues: CreateClubFormInterface = {
    name: '',
    email: '',
    address: '',
    establishment: '',
    locationId: 2,
    lat: 0,
    lng: 0,
    foundation: '',
}

function Clubs() {
    const dispatch = useDispatch()
    const [action, setAction] = useState(FormActions.CREATE)
    const [clubs, setClubs] = useState<Club[]>([])
    const [locations, setLocations] = useState<SelectInterface[]>([])
    const [isCreateUserFormOpen, setIsCreateUserFormOpen] = useState(false)
    const [isViewUserFormOpen, setIsViewUserFormOpen] = useState(false)
    const [modalEditVisible, setModalEditVisible] = useState(false)
    const [modalConfirmDeleteVisible, setModalConfirmDeleteVisible] =
        useState(false)
    const [clubEdit, setClubEdit] = useState({} as Club)
    const [clubDelete, setClubDelete] = useState({} as Club)
    const toggleModalEdit = () => setModalEditVisible(!modalEditVisible)
    const toggleModalView = () => setIsViewUserFormOpen(!isViewUserFormOpen)
    const toggleModalCreate = () => {
        setAction(FormActions.CREATE)
        setIsCreateUserFormOpen(!isCreateUserFormOpen)
    }
    const fetchClubs = () =>
        dispatch(fetchClubsStart((clubsFetched) => setClubs(clubsFetched)))
    const fetchLocations = () => {
        dispatch(
            fetchLocationsStart((locationsFetched) =>
                setLocations(locationsFetched)
            )
        )
    }
    const isLoadingClubs = useSelector(
        (state: IRootState) => state.clubsReducer.loadingClubs
    )

    const onError = (errorMessage: string | null) => {
        const message = errorMessage ?? crudMessagesError[action]
        toast.error(message)
    }

    const onClubDeleted = () => {
        setModalConfirmDeleteVisible(false)
        fetchClubs()
        toast.success(crudMessagesSuccess[action])
    }
    const onDeleteClub = (clubId) => {
        dispatch(deleteClubStart(clubId, onClubDeleted, onError))
    }
    const onClubCreated = () => {
        setIsCreateUserFormOpen(false)
        fetchClubs()
        toast.success(crudMessagesSuccess[action])
    }
    const onClubEdited = () => {
        setModalEditVisible(false)
        fetchClubs()
        toast.success(crudMessagesSuccess[action])
    }
    const createClub = (values) => {
        dispatch(createClubStart(values, onClubCreated, onError))
    }
    const editClub = (values) => {
        dispatch(editClubStart(values, clubEdit.id, onClubEdited, onError))
    }
    const openConfirmDeleteModal = (clubDeleteRow: Club) => {
        setClubDelete(clubDeleteRow)
        setModalConfirmDeleteVisible(true)
        setAction(FormActions.DELETE)
    }
    const toggleConfirmDeleteModal = () =>
        setModalConfirmDeleteVisible(!modalConfirmDeleteVisible)
    const userLogged: User = useSelector(
        (state: IRootState) => state.authReducer.user
    )
    const { clubs: clubsPermissions } = getPermissions(userLogged?.profile)
    const {
        create: canCreateClub,
        edit: canEditClub,
        delete: canDeleteClub,
        details: canSeeDetailsClub,
    } = clubsPermissions

    const ActionButton = useCallback(
        ({ row }) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                    color="success"
                    onClick={() => {
                        setIsViewUserFormOpen(true)
                        setClubEdit(row)
                        setAction(FormActions.DETAIL)
                    }}
                    className="p-1 pt-0 me-2"
                    disabled={!canSeeDetailsClub}
                >
                    <FaRegEye />
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        setModalEditVisible(true)
                        setClubEdit(row)
                        setAction(FormActions.EDIT)
                    }}
                    className="p-1 pt-0 me-2"
                    disabled={!canEditClub}
                >
                    <FaPencilAlt />
                </Button>
                <Button
                    color="danger"
                    onClick={() => {
                        openConfirmDeleteModal(row)
                    }}
                    className="p-1 pt-0"
                    disabled={!canDeleteClub}
                >
                    <FaTrash />
                </Button>
            </div>
        ),
        []
    )

    const columns = [
        {
            name: 'Email',
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Dirección',
            selector: (row) => row.address,
            sortable: true,
        },
        {
            name: 'Localidad',
            selector: (row) => (row.location ? row.location.name : ''),
            sortable: true,
        },
        {
            name: 'Establecimiento',
            selector: (row) => row.establishment,
            sortable: true,
        },
        {
            name: 'Latitud',
            selector: (row) => row.lat,
            sortable: true,
        },
        {
            name: 'Longitud',
            selector: (row) => row.lng,
            sortable: true,
        },
        {
            name: 'Acciones',
            cell: (row) =>
                ActionButton({
                    row,
                }),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            minWidth: '156px',
        },
    ]

    React.useEffect(() => {
        fetchClubs()
        fetchLocations()
    }, [])
    const [filterText, setFilterText] = React.useState('')
    const { filteredItems } = useSearch(clubs, filterText)

    const SubHeaderComponentMemo = React.useMemo(() => {
        return (
            <div className="d-flex">
                <div className="d-flex align-items-center me-3">
                    Buscar
                    <Input
                        className="ms-2"
                        onChange={(e) => setFilterText(e.target.value)}
                        value={filterText}
                    />
                </div>
                <div>
                    <TableButton type="button" onClick={fetchClubs}>
                        <FaRedoAlt />
                    </TableButton>
                </div>
            </div>
        )
    }, [filterText])

    return (
        <Container>
            <Row>
                <Col className="d-flex justify-content-between ">
                    <h3>Clubes</h3>
                    <Button
                        color="primary"
                        onClick={() => toggleModalCreate()}
                        style={{
                            marginBottom: '1rem',
                        }}
                        disabled={!canCreateClub}
                    >
                        Crear Club
                    </Button>

                    <CustomModal
                        title="Crear Club"
                        isOpen={isCreateUserFormOpen}
                        toggle={toggleModalCreate}
                    >
                        <FormClub
                            createClub={createClub}
                            initialValues={initialValues}
                            locations={locations}
                            editMode={false}
                        />
                    </CustomModal>
                    <CustomModal
                        isOpen={isViewUserFormOpen}
                        toggle={toggleModalView}
                        title="Ver Club"
                    >
                        <FormClub
                            createClub={createClub}
                            initialValues={clubEdit}
                            locations={locations}
                            editMode={false}
                            disabled
                        />
                    </CustomModal>
                    <ModalConfirm
                        isOpen={modalConfirmDeleteVisible}
                        toggle={toggleConfirmDeleteModal}
                        onConfirm={() => onDeleteClub(clubDelete?.id)}
                        onCancel={() => setModalConfirmDeleteVisible(false)}
                        titleHeader={`Eliminar Club ${clubDelete?.name}?`}
                        title={`¿Está seguro que desea eliminar el club ${clubDelete?.name}?`}
                        confirmButtonText="Eliminar"
                    >
                        <FormClub
                            createClub={createClub}
                            initialValues={clubDelete}
                            locations={locations}
                            editMode={false}
                            disabled
                        />
                    </ModalConfirm>
                    <CustomModal
                        isOpen={modalEditVisible}
                        toggle={toggleModalEdit}
                        title="Editar Club"
                    >
                        <FormClub
                            createClub={editClub}
                            locations={locations}
                            initialValues={clubEdit}
                            editMode
                        />
                    </CustomModal>
                </Col>
            </Row>
            <Row>
                <Col>
                    <CustomTable
                        // title="Clubes"
                        noHeader
                        striped
                        pagination
                        data={filteredItems}
                        subHeader
                        subHeaderComponent={SubHeaderComponentMemo}
                        progressPending={isLoadingClubs}
                        progressComponent={
                            <Spinner color="primary" type="grow">
                                Cargando...
                            </Spinner>
                        }
                        columns={columns}
                        dense
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default Clubs
