import { CreateUserFormInterface } from '../../components/FormUser'
import { User } from '../../models/user'

export const FETCH_PROFILE_START = 'FETCH_PROFILE_START'
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS'
export const FETCH_PROFILE_FAILED = 'FETCH_PROFILE_FAILED'
export const EDIT_PROFILE_START = 'EDIT_PROFILE_START'
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS'
export const EDIT_PROFILE_FAILED = 'EDIT_PROFILE_FAILED'

export const fetchProfileStart = (
    onLoad: (profileFetched: User) => void,
    onError: () => void
) => ({
    type: FETCH_PROFILE_START,
    payload: {
        onLoad,
        onError,
    },
})

export const fetchProfileSuccess = (data: any) => ({
    type: FETCH_PROFILE_SUCCESS,
    payload: data,
})

export const fetchProfileFailed = () => ({
    type: FETCH_PROFILE_FAILED,
})

export const editProfileStart = (
    values: CreateUserFormInterface,
    onSuccess: () => void
) => ({
    type: EDIT_PROFILE_START,
    payload: {
        values,
        onSuccess,
    },
})

export const editProfileSuccess = (data: any) => ({
    type: EDIT_PROFILE_SUCCESS,
    payload: data,
})

export const editProfileFailed = () => ({
    type: EDIT_PROFILE_FAILED,
})
