import React, { useState } from 'react'

import DateTime from 'react-datetime'
import InputMask from 'react-input-mask'
import 'react-datetime/css/react-datetime.css'
import moment from 'moment'

export default function ImpDatePicker(props: any) {
    const [lastDate, setLastDate] = useState(moment())
    const [valueAux, setValueAux] = useState(null)

    const { value, dateTime, time, onChange, disabled } = props

    const formatMaks = {
        format: {
            dateTime: 'DD/MM/YYYY HH:mm',
            date: 'DD/MM/YYYY',
            time: 'HH:mm',
        },
        mask: {
            time: '99:99',
            date: '99/99/9999',
            datetime: '99/99/9999 99:99',
        },
    }

    const hsOrDate = time ? formatMaks.mask.time : formatMaks.mask.date
    const dateTimeMask = dateTime ? formatMaks.mask.datetime : hsOrDate

    const hsOrDateFormat = time
        ? formatMaks.format.time
        : formatMaks.format.date
    const dateTimeFormat = dateTime
        ? formatMaks.format.dateTime
        : hsOrDateFormat

    const formatToMoment = (date: string): moment.Moment => {
        return moment(date, dateTimeFormat)
    }

    const formatToString = (date: moment.Moment): string => {
        return date.format(dateTimeFormat)
    }

    const isValid = (date: string): boolean => {
        return moment(date, dateTimeFormat).isValid()
    }

    // onChange
    const handleOnChange = (data: any) => {
        // Si data es un objeto (moment) lo devuelvo
        if (typeof data === 'object') return onChange(data)

        // Si se ingreso una fecha valida actualizo el estado "lastDate"
        if (data.indexOf('_') === -1 && isValid(data)) {
            setLastDate(formatToMoment(data))
        }
        // Si data contiene un _ se almacena como string en valueAux sino se setea a null
        if (data.indexOf('_') !== -1) {
            setValueAux(data)
        } else {
            setValueAux(null)
        }
        // Si data no es una fecha valida devuelvo la ultima fecha valida almacenada en "lastDate" sino devuelvo la fecha en formato moment()
        if (data.indexOf('_') !== -1) {
            onChange(lastDate)
        } else if (isValid(data)) {
            onChange(formatToMoment(data))
        } else {
            onChange(lastDate)
        }

        return true
    }

    return (
        <DateTime
            {...props}
            onChange={handleOnChange}
            dateFormat={!time}
            timeFormat={dateTime !== undefined || time !== undefined}
            renderInput={(propsDT, openCalendar) => {
                return (
                    <InputMask
                        {...propsDT}
                        disabled={disabled}
                        mask={dateTimeMask}
                        onClick={() => openCalendar(true)}
                        value={valueAux || formatToString(value)}
                        // si al quitar el foco la fecha ingresada no es valida se devuelve la ultima fecha valida almacenada (lastDate)
                        onBlur={() =>
                            handleOnChange(
                                valueAux
                                    ? formatToString(lastDate)
                                    : formatToString(value)
                            )
                        }
                    />
                )
            }}
        />
    )
}
