import { all, fork } from 'redux-saga/effects'
import { watchAuthStart } from './auth.sagas'
import { watchUsersStart } from './users.sagas'
import { watchClubsStart } from './clubs.sagas'
import { watchLocationsStart } from './locations.sagas'
import { watchPigeonHousesStart } from './pingeonHouses.sagas'
import { watchProfileStart } from './profile.sagas'
import { watchChampionshipsStart } from './championship.sagas'
import { watchRacingsStart } from './racing.sagas'

export const rootSaga = function* root() {
    yield all([fork(watchAuthStart)])
    yield all([fork(watchUsersStart)])
    yield all([fork(watchClubsStart)])
    yield all([fork(watchLocationsStart)])
    yield all([fork(watchPigeonHousesStart)])
    yield all([fork(watchProfileStart)])
    yield all([fork(watchChampionshipsStart)])
    yield all([fork(watchRacingsStart)])
}
