import React from 'react'
import DataTable, { createTheme } from 'react-data-table-component'

import styled from 'styled-components'

const ContTable = styled.div`
    background-color: #f9f9f9;
    border: solid 0.8px ${(props) => props.borderColor};
    border-radius: 4px;
`

function CustomTable(props) {
    const borderColor = '#d5d5d5'

    createTheme('customTable', {
        // text: {
        //     primary: '#268bd2',
        //     secondary: '#2aa198',
        // },
        background: {
            default: 'transparent',
        },
        striped: {
            default: '#b1b1b11a',
        },
        // context: {
        //     background: 'red',
        // },
        divider: {
            default: borderColor,
        },
        // button: {
        //     default: '#2aa198',
        //     hover: 'rgba(0,0,0,.08)',
        //     focus: 'rgba(255,255,255,.12)',
        //     disabled: 'rgba(255, 255, 255, .34)',
        // },
        sortFocus: {
            default: 'red',
        },
    })

    return (
        <ContTable borderColor={borderColor}>
            <DataTable
                // title="Clubes"
                // noHeader
                // striped
                // pagination
                // data={clubs}
                // progressPending={isLoadingClubs}
                // progressComponent={
                //     <Spinner color="primary" type="grow">
                //         Cargando...
                //     </Spinner>
                // }
                // columns={columns}
                // dense
                theme="customTable"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
            />
        </ContTable>
    )
}

export default CustomTable
