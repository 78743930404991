import React, { useState } from 'react'
import { Marker as GoogleMarker, InfoWindow } from 'react-google-maps'

import { IGpsPoint } from '../../interfaces'

interface IMarkerProps {
    position: IGpsPoint
    title?: string
    content?: JSX.Element
    defaultIsOpen?: boolean
}

function Marker(props: IMarkerProps) {
    const { position, title, content, defaultIsOpen } = props

    const [isOpen, setIsOpen] = useState(defaultIsOpen || false)

    const toggleOpen = () => {
        setIsOpen(!isOpen)
    }

    return (
        <GoogleMarker position={position} onClick={toggleOpen}>
            {isOpen && (
                <InfoWindow onCloseClick={toggleOpen}>
                    {content || (
                        <div>
                            <h6>{title || ''}</h6>
                            <p>{`Latitude: ${position.lat}`}</p>
                            <p>{`Longitude: ${position.lng}`}</p>
                        </div>
                    )}
                </InfoWindow>
            )}
        </GoogleMarker>
    )
}

Marker.defaultProps = {
    title: undefined,
    content: undefined,
    defaultIsOpen: false,
}

export default Marker
