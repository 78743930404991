import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { FaMap } from 'react-icons/fa'

function MapModal({ children, title }: any) {
    const [isOpen, setIsOpen] = useState(false)
    const toggleIsOpen = () => setIsOpen(!isOpen)

    return (
        <div>
            <Button color="info" onClick={toggleIsOpen}>
                {title && title.length > 0 ? (
                    title
                ) : (
                    <FaMap size="25px" color="white" />
                )}
            </Button>
            <Modal toggle={toggleIsOpen} isOpen={isOpen} size="xl">
                <ModalHeader toggle={toggleIsOpen} />
                <ModalBody className="p-0">{children}</ModalBody>
            </Modal>
        </div>
    )
}

export default MapModal
