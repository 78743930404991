import { Club } from '../../models/club'
import { CreateClubFormInterface } from '../../components/FormClub'
import { SelectInterface } from '../../models/select'

export const FETCH_CLUBS_START = 'FETCH_CLUBS_START'
export const FETCH_CLUBS_SUCCESS = 'FETCH_CLUBS_SUCCESS'
export const FETCH_CLUBS_FAILED = 'FETCH_CLUBS_FAILED'

export const FETCH_CLUBS_SELECT_START = 'FETCH_CLUBS_SELECT_START'
export const FETCH_CLUBS_SELECT_SUCCESS = 'FETCH_CLUBS_SELECT_SUCCESS'
export const FETCH_CLUBS_SELECT_FAILED = 'FETCH_CLUBS_SELECT_FAILED'

export const DELETE_CLUB_START = 'DELETE_CLUB_START'
export const DELETE_CLUB_SUCCESS = 'DELETE_CLUB_SUCCESS'
export const DELETE_CLUB_FAILED = 'DELETE_CLUB_FAILED'

export const CREATE_CLUB_START = 'CREATE_CLUB_START'
export const CREATE_CLUB_SUCCESS = 'CREATE_CLUB_SUCCESS'
export const CREATE_CLUB_FAILED = 'CREATE_CLUB_FAILED'

export const EDIT_CLUB_START = 'EDIT_CLUB_START'
export const EDIT_CLUB_SUCCESS = 'EDIT_CLUB_SUCCESS'
export const EDIT_CLUB_FAILED = 'EDIT_CLUB_FAILED'

export const FETCH_MY_CLUB_START = 'FETCH_MY_CLUB_START'
export const FETCH_MY_CLUB_SUCCESS = 'FETCH_MY_CLUB_SUCCESS'
export const FETCH_MY_CLUB_FAILED = 'FETCH_MY_CLUB_FAILED'

export const EDIT_MY_CLUB_START = 'EDIT_MY_CLUB_START'
export const EDIT_MY_CLUB_SUCCESS = 'EDIT_MY_CLUB_SUCCESS'
export const EDIT_MY_CLUB_FAILED = 'EDIT_MY_CLUB_FAILED'

export const fetchClubsStart = (onLoad: (clubs: Club[]) => void) => ({
    type: FETCH_CLUBS_START,
    payload: {
        onLoad,
    },
})

export const fetchClubsSuccess = (clubs: Array<Club>) => ({
    type: FETCH_CLUBS_SUCCESS,
    payload: clubs,
})

export const fetchClubsFailed = () => ({
    type: FETCH_CLUBS_FAILED,
})

export const fetchClubsSelectStart = (
    onLoad: (clubs: SelectInterface[]) => void
) => ({
    type: FETCH_CLUBS_SELECT_START,
    payload: {
        onLoad,
    },
})

export const fetchClubsSelectSuccess = (clubs: Array<SelectInterface>) => ({
    type: FETCH_CLUBS_SELECT_SUCCESS,
    payload: clubs,
})

export const fetchClubsSelectFailed = () => ({
    type: FETCH_CLUBS_SELECT_FAILED,
})

export const deleteClubStart = (
    clubId: string,
    onSuccess: () => void,
    onError: (message: string | null) => void
) => ({
    type: DELETE_CLUB_START,
    payload: { clubId, onSuccess, onError },
})

export const deleteClubSuccess = () => ({
    type: DELETE_CLUB_SUCCESS,
})

export const deleteClubFailed = () => ({
    type: DELETE_CLUB_FAILED,
})

export const createClubStart = (
    values: CreateClubFormInterface,
    onSuccess: () => void,
    onError: (message: string | null) => void
) => ({
    type: CREATE_CLUB_START,
    payload: {
        values,
        onSuccess,
        onError,
    },
})

export const createClubSuccess = () => ({
    type: CREATE_CLUB_SUCCESS,
})

export const createClubFailed = () => ({
    type: CREATE_CLUB_FAILED,
})

export const editClubStart = (
    values: CreateClubFormInterface,
    clubId: string,
    onSuccess: () => void,
    onError: (message: string | null) => void
) => ({
    type: EDIT_CLUB_START,
    payload: {
        values,
        clubId,
        onSuccess,
        onError,
    },
})

export const editClubSuccess = () => ({
    type: EDIT_CLUB_SUCCESS,
})

export const editClubFailed = () => ({
    type: EDIT_CLUB_FAILED,
})

export const fetchMyClubStart = (onLoad: (club: Club) => void) => ({
    type: FETCH_MY_CLUB_START,
    payload: {
        onLoad,
    },
})

export const fetchMyClubSuccess = (club: Club) => ({
    type: FETCH_MY_CLUB_SUCCESS,
    payload: club,
})

export const fetchMyClubFailed = () => ({
    type: FETCH_MY_CLUB_FAILED,
})

export const editMyClubStart = (
    values: CreateClubFormInterface,
    onSuccess: () => void,
    onError: (message: string | null) => void
) => ({
    type: EDIT_MY_CLUB_START,
    payload: {
        values,
        onSuccess,
        onError,
    },
})

export const editMyClubSuccess = () => ({
    type: EDIT_MY_CLUB_SUCCESS,
})

export const editMyClubFailed = () => ({
    type: EDIT_MY_CLUB_FAILED,
})
