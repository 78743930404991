import React, { ChangeEvent, FocusEventHandler } from 'react'
import { Input, FormFeedback } from 'reactstrap'
import { InputType } from 'reactstrap/types/lib/Input'

interface InputFormProps {
    handleChange: (e: ChangeEvent<any>) => void
    handleBlur: FocusEventHandler<HTMLInputElement>
    values: any
    touched: any
    errors: any
    fieldName: string
    placeholder?: string
    type?: InputType | 'integer'
    disabled?: boolean
    defaultValue?: string
    className?: string
    maxLength?: number
}

function InputForm({
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    fieldName,
    placeholder,
    disabled = false,
    type = 'text',
    defaultValue,
    className,
    maxLength,
}: InputFormProps) {
    return (
        <>
            <Input
                type={type === 'integer' ? 'text' : type}
                id={fieldName}
                name={fieldName}
                onChange={handleChange}
                className={`form-control ${className}`}
                placeholder={placeholder}
                onBlur={handleBlur}
                disabled={disabled}
                value={defaultValue || values[fieldName]}
                invalid={errors[fieldName] && touched[fieldName]}
                maxLength={maxLength}
                onKeyPress={(e) => {
                    if (type === 'integer' && (e.key < '0' || e.key > '9')) {
                        e.preventDefault()
                    }
                }}
            />
            <FormFeedback invalid>
                {errors[fieldName] && touched[fieldName] && errors[fieldName]}
            </FormFeedback>
        </>
    )
}

InputForm.defaultProps = {
    type: 'text',
    placeholder: '',
    disabled: false,
    className: '',
    defaultValue: undefined,
    maxLength: undefined,
}

export default InputForm
