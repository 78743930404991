import { call, put, takeLatest } from 'redux-saga/effects'
import {
    fetchClubsFailed,
    fetchClubsSuccess,
    FETCH_CLUBS_START,
    createClubSuccess,
    createClubFailed,
    CREATE_CLUB_START,
    editClubSuccess,
    editClubFailed,
    deleteClubSuccess,
    deleteClubFailed,
    DELETE_CLUB_START,
    EDIT_CLUB_START,
    fetchClubsSelectSuccess,
    fetchClubsSelectFailed,
    FETCH_CLUBS_SELECT_START,
    fetchMyClubSuccess,
    fetchMyClubFailed,
    FETCH_MY_CLUB_START,
    editMyClubSuccess,
    editMyClubFailed,
    EDIT_MY_CLUB_START,
} from '../actions/clubs'
import {
    createClub,
    deleteClub,
    editClub,
    fetchClubs,
    fetchClubsSelect,
    fetchMyClub,
    editMyClub,
} from '../../services/clubs.service'

function* fetchClubsApi({ payload: { onLoad } }: any) {
    try {
        const {
            data: { data: clubs },
        } = yield call(fetchClubs)
        onLoad(clubs)
        yield put(fetchClubsSuccess(clubs))
    } catch {
        yield put(fetchClubsFailed())
    }
}

function* createClubApi({ payload: { values, onSuccess, onError } }: any) {
    try {
        yield call(createClub, values)
        yield put(createClubSuccess())
        onSuccess()
    } catch (err) {
        yield put(createClubFailed())
        onError(err.response.data.message ?? null)
    }
}

function* deleteClubApi({ payload: { clubId, onSuccess, onError } }: any) {
    try {
        yield call(deleteClub, clubId)
        yield put(deleteClubSuccess())
        onSuccess()
    } catch (err) {
        yield put(deleteClubFailed())
        onError(err.response.data.message ?? null)
    }
}

function* editClubApi({
    payload: { values, clubId, onSuccess, onError },
}: any) {
    try {
        yield call(editClub, clubId, values)
        yield put(editClubSuccess())
        onSuccess()
    } catch (err) {
        yield put(editClubFailed())
        onError(err.response.data.message ?? null)
    }
}

function* fetchClubsSelectApi({ payload: { onLoad } }: any) {
    try {
        const {
            data: { data: locations },
        } = yield call(fetchClubsSelect)
        onLoad(locations)
        yield put(fetchClubsSelectSuccess(locations))
    } catch {
        yield put(fetchClubsSelectFailed())
    }
}

function* fetchMyClubApi({ payload: { onLoad } }: any) {
    try {
        const {
            data: { data: locations },
        } = yield call(fetchMyClub)
        onLoad(locations)
        yield put(fetchMyClubSuccess(locations))
    } catch {
        yield put(fetchMyClubFailed())
    }
}

function* editMyClubApi({ payload: { values, onSuccess, onError } }: any) {
    try {
        yield call(editMyClub, values)
        yield put(editMyClubSuccess())
        onSuccess()
    } catch (err) {
        yield put(editMyClubFailed())
        onError(err.response.data.message ?? null)
    }
}

export function* watchClubsStart() {
    yield takeLatest(FETCH_CLUBS_START, fetchClubsApi)
    yield takeLatest(FETCH_CLUBS_SELECT_START, fetchClubsSelectApi)
    yield takeLatest(CREATE_CLUB_START, createClubApi)
    yield takeLatest(DELETE_CLUB_START, deleteClubApi)
    yield takeLatest(EDIT_CLUB_START, editClubApi)
    yield takeLatest(FETCH_MY_CLUB_START, fetchMyClubApi)
    yield takeLatest(EDIT_MY_CLUB_START, editMyClubApi)
}
