import { User } from '../../models/user'
import { LOGIN_FAILED, LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../actions/auth'

interface InitialStateType {
    user: User | null
    isLoggedIn: boolean
}

const initialState: InitialStateType = {
    user: null,
    isLoggedIn: false,
}

const authReducer = function (
    // eslint-disable-next-line default-param-last
    state: InitialStateType = initialState,
    action: any
) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isLoggedIn: true,
            }
        case LOGIN_FAILED:
            return {
                ...state,
                user: null,
                isLoggedIn: false,
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                user: null,
                isLoggedIn: false,
            }
        default:
            return state
    }
}

export default authReducer
