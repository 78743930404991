import { call, put, takeLatest } from 'redux-saga/effects'
import {
    EDIT_PROFILE_START,
    editProfileFailed,
    editProfileSuccess,
    FETCH_PROFILE_START,
    fetchProfileFailed,
    fetchProfileSuccess,
} from '../actions/profile'
import { editMyProfile, fetchMyProfile } from '../../services/profile.service'

function* fetchProfileApi({ payload: { onLoad, onError } }: any) {
    try {
        const {
            data: { data: profile },
        } = yield call(fetchMyProfile)
        onLoad(profile)
        yield put(fetchProfileSuccess(profile))
    } catch {
        onError()
        yield put(fetchProfileFailed())
    }
}

function* editProfileApi({ payload: { values, onSuccess } }: any) {
    try {
        const {
            data: { data },
        } = yield call(editMyProfile, values)
        yield put(editProfileSuccess(data))
        onSuccess()
    } catch {
        yield put(editProfileFailed())
    }
}

export function* watchProfileStart() {
    yield takeLatest(FETCH_PROFILE_START, fetchProfileApi)
    yield takeLatest(EDIT_PROFILE_START, editProfileApi)
}
