import { call, put, takeLatest } from 'redux-saga/effects'
import {
    CREATE_CHAMPIONSHIP_START,
    createChampionshipSuccess,
    createChampionshipFailed,
    fetchChampionshipsSuccess,
    fetchChampionshipsFailed,
    FETCH_CHAMPIONSHIPS_START,
    editChampionshipSuccess,
    editChampionshipFailed,
    EDIT_CHAMPIONSHIP_START,
    deleteChampionshipSuccess,
    deleteChampionshipFailed,
    DELETE_CHAMPIONSHIP_START,
    FETCH_CHAMPIONSHIPS_SELECT_START,
    fetchChampionshipsSelectSuccess,
    fetchChampionshipsSelectFailed,
} from '../actions/championships'
import {
    createChampionship,
    deleteChampionship,
    editChampionship,
    fetchChampionships,
    fetchChampionshipsSelect,
} from '../../services/championships.service'

function* fetchChampionshipsApi({ payload: { onLoad, listPublic } }: any) {
    try {
        const {
            data: { data: championships },
        } = yield call(fetchChampionships, listPublic)
        onLoad(championships)
        yield put(fetchChampionshipsSuccess(championships))
    } catch {
        yield put(fetchChampionshipsFailed())
    }
}

function* createChampionshipApi({
    payload: { values, onSuccess, onError },
}: any) {
    try {
        const newValues = { ...values }
        if (newValues.isIntersocial) {
            newValues.intersocialClubs = values.intersocialClubs.map(
                (clubId) => ({
                    clubId,
                })
            )
        }
        const {
            data: { data: createdChampionship },
        } = yield call(createChampionship, newValues)
        yield put(createChampionshipSuccess(createdChampionship))
        onSuccess()
    } catch (err) {
        yield put(createChampionshipFailed())
        onError(err.response.data.message ?? null)
    }
}

function* editChampionshipApi({
    payload: { values, championshipId, onSuccess, onError },
}: any) {
    try {
        const {
            data: { data: editedChampionship },
        } = yield call(editChampionship, championshipId, values)
        yield put(editChampionshipSuccess(editedChampionship))
        onSuccess()
    } catch (err) {
        yield put(editChampionshipFailed())
        onError(err.response.data.message ?? null)
    }
}

function* deleteChampionshipApi({
    payload: { championshipId, onSuccess, onError },
}: any) {
    try {
        yield call(deleteChampionship, championshipId)
        yield put(deleteChampionshipSuccess())
        onSuccess()
    } catch (err) {
        yield put(deleteChampionshipFailed())
        onError(err.response.data.message ?? null)
    }
}

function* fetchChampionshipsSelectApi({ payload: { onLoad } }: any) {
    try {
        const {
            data: { data: championships },
        } = yield call(fetchChampionshipsSelect)
        onLoad(championships)
        yield put(fetchChampionshipsSelectSuccess(championships))
    } catch {
        yield put(fetchChampionshipsSelectFailed())
    }
}

export function* watchChampionshipsStart() {
    yield takeLatest(FETCH_CHAMPIONSHIPS_START, fetchChampionshipsApi)
    yield takeLatest(CREATE_CHAMPIONSHIP_START, createChampionshipApi)
    yield takeLatest(EDIT_CHAMPIONSHIP_START, editChampionshipApi)
    yield takeLatest(DELETE_CHAMPIONSHIP_START, deleteChampionshipApi)
    yield takeLatest(
        FETCH_CHAMPIONSHIPS_SELECT_START,
        fetchChampionshipsSelectApi
    )
}
