import React, { useCallback, useState } from 'react'
import { Button, Spinner, Row, Col, Input, Label } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FaTrash, FaPencilAlt, FaRegEye, FaRedoAlt } from 'react-icons/fa'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { IRootState } from '../../store/reducers'
import {
    fetchMarkedPigeonsStart,
    createMarkedPigeonStart,
    editMarkedPigeonStart,
    deleteMarkedPigeonStart,
    setRaceId,
    fetchRaceDetailsStart,
    updateTimesAndPointsStart,
} from '../../store/actions/racings'
import { fetchPigeonHousesSelectStart } from '../../store/actions/pigeonHouses'

import { SelectInterface } from '../../models/select'
import { findSelectedRaw } from '../../utils/selectHelper'

import useSearch from '../../hooks/useSearch'

import CustomTable from '../../components/CustomTable'
import CustomModal from '../../components/CustomModal'
import Container from '../../components/Container'

import { sortNumbers } from '../../utils/tableHelper'
import FormMarkedPigeons, {
    MarkedPigeonInterface,
} from '../../components/FormMarkedPigeons'
import {
    FormActions,
    crudMessagesSuccess,
    crudMessagesError,
} from '../../utils/crudHelper'
import { formatHoursToMinutes } from '../../utils/dateHelper'
import { getPermissions } from '../../utils/permissions'
import { User, TypeProfiles } from '../../models/user'
import { RaceState, Racing, RaceStateLabels } from '../../models/racing'

import { TableButton } from '../../styles/buttons/tableButtons'
import { ColHeader } from '../../styles/conteiners/colHeader'

const initialValues: MarkedPigeonInterface = {
    pigeonHouseId: null,
    pigeonRacingId: null,
    ringNumber: '',
    ringYear: '1999',
    markedDate: moment(),
    markedTime: '00:00:00',
    team: 1,
    distance: 0,
}

function MarkedPigeons() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [markedPigeons, setMarkedPigeons] = useState<MarkedPigeonInterface[]>(
        []
    )
    const [race, setRace] = useState<Racing>({} as Racing)
    const [isFormOpen, setIsFormOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const [action, setAction] = useState(FormActions.CREATE)
    const [markedData, setMarkedData] = useState(initialValues)
    const [pigeonHousesSelect, setPigeonHousesSelect] = useState<
        SelectInterface[]
    >([])

    const [notChampionshipOwner, setNotChampionshipOwner] = useState(false)

    const toggleModalCreate = () => setIsFormOpen(!isFormOpen)

    const raceId = useSelector(
        (state: IRootState) => state.racingsReducer.raceId
    )
    const userLogged: User = useSelector(
        (state: IRootState) => state.authReducer.user
    )

    const fetchPigeonHousesSelect = () => {
        dispatch(
            fetchPigeonHousesSelectStart((pigeonHousesFetched) =>
                setPigeonHousesSelect(pigeonHousesFetched)
            )
        )
    }

    const fetchMarkedPigeons = () => {
        setIsLoading(true)
        dispatch(
            fetchMarkedPigeonsStart(raceId, (markedFetched) => {
                setMarkedPigeons(markedFetched)
                setIsLoading(false)
            })
        )
    }

    const fetchRace = (raceId) =>
        dispatch(
            fetchRaceDetailsStart((raceFetched) => {
                setRace(raceFetched)
                const notChampionshipOwner =
                    !raceFetched?.pigeonRacingChampionships
                        ?.map((championship) => championship.clubId)
                        .includes(userLogged?.clubId)
                setNotChampionshipOwner(notChampionshipOwner)
            }, raceId)
        )

    const onSubmitSuccess = () => {
        setIsFormOpen(false)
        fetchMarkedPigeons()
        toast.success(crudMessagesSuccess[action])
    }

    const onSubmitError = (errorMessage = null) => {
        const message = errorMessage ?? crudMessagesError[action]

        toast.error(message)
    }

    const createMarkedPigeon = (values: MarkedPigeonInterface) => {
        dispatch(
            createMarkedPigeonStart(values, onSubmitSuccess, onSubmitError)
        )
    }

    const editMarkedPigeon = (
        markedId: number,
        values: MarkedPigeonInterface
    ) => {
        dispatch(
            editMarkedPigeonStart(
                markedId,
                values,
                onSubmitSuccess,
                onSubmitError
            )
        )
    }

    const deleteMarkedPigeon = (markedId: number) => {
        dispatch(
            deleteMarkedPigeonStart(markedId, onSubmitSuccess, onSubmitError)
        )
    }

    const { markedPigeonsSuspended, markedPigeonsInRace, markedPigeonsClosed } =
        getPermissions(userLogged?.profile)

    const getRacePermissions = () => {
        let permissions
        switch (race.state) {
            case RaceState.NEXT_RACE:
                permissions = markedPigeonsInRace
                break
            case RaceState.IN_RACE:
                permissions = markedPigeonsInRace
                break
            case RaceState.SUSPENDED:
                permissions = markedPigeonsSuspended
                break
            case RaceState.CLOSED:
                permissions = markedPigeonsClosed
                break
            case RaceState.UNCALCULATED_RACE:
                permissions = markedPigeonsSuspended
                break
            default:
                permissions = markedPigeonsInRace
                break
        }
        return permissions
    }
    const {
        create: canCreateMarkedPigeon,
        edit: canEditMarkedPigeon,
        delete: canDeleteMarkedPigeon,
        details: canSeeDetailsMarkedPigeon,
        updatePoints: canUpdatePoints,
    } = getRacePermissions()

    const ActionButton = useCallback(
        ({ row }) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                    color="primary"
                    onClick={() => {
                        toggleModalCreate()
                        setAction(FormActions.DETAIL)
                        setMarkedData(row)
                    }}
                    disabled={!canSeeDetailsMarkedPigeon}
                    className="p-1 pt-0 ms-2"
                >
                    <FaRegEye />
                </Button>
                <Button
                    color="success"
                    onClick={() => {
                        toggleModalCreate()
                        setAction(FormActions.EDIT)
                        setMarkedData(row)
                    }}
                    disabled={
                        !canEditMarkedPigeon ||
                        (userLogged.profile === TypeProfiles.USER &&
                            userLogged.pigeonHouseId &&
                            row.pigeonHouseId !== userLogged.pigeonHouseId) ||
                        notChampionshipOwner
                    }
                    className="p-1 pt-0 ms-2"
                >
                    <FaPencilAlt />
                </Button>
                <Button
                    color="danger"
                    onClick={() => {
                        toggleModalCreate()
                        setAction(FormActions.DELETE)
                        setMarkedData(row)
                    }}
                    disabled={!canDeleteMarkedPigeon || notChampionshipOwner}
                    className="p-1 pt-0 ms-2"
                >
                    <FaTrash />
                </Button>
            </div>
        ),
        [
            canCreateMarkedPigeon,
            canEditMarkedPigeon,
            canDeleteMarkedPigeon,
            canSeeDetailsMarkedPigeon,
            notChampionshipOwner,
        ]
    )

    const columns = [
        {
            name: 'Posición',
            selector: (row: MarkedPigeonInterface) => row.position ?? 0,
            sortable: true,
            width: '100px',
            sortFunction: sortNumbers('position'),
        },
        // {
        //     name: 'Fecha de marcada',
        //     selector: (row: MarkedPigeonInterface) =>
        //         moment(row.markedDate).format('DD/MM/YYYY'),
        //     sortable: true,
        // },
        {
            name: 'Palomar',
            selector: (row: MarkedPigeonInterface) =>
                findSelectedRaw(pigeonHousesSelect, row.pigeonHouseId)?.name ||
                '',
            sortable: true,
        },
        {
            name: 'Usuario',
            selector: (row: MarkedPigeonInterface) => row.userName,
            sortable: true,
            minWidth: '220px',
        },
        {
            name: 'Numero de anillo',
            selector: (row: MarkedPigeonInterface) => row.ringNumber,
            sortable: true,
            width: '100px',
        },
        {
            name: 'Equipo',
            selector: (row: MarkedPigeonInterface) => row.team ?? '',
            sortable: true,
            width: '100px',
        },
        {
            name: 'Distancia',
            selector: (row: MarkedPigeonInterface) =>
                `${row.distance?.toFixed(3) ?? '0'} km`,
            sortable: true,
            sortFunction: sortNumbers('distance'),
            width: '120px',
        },
        {
            name: 'Hora de marcada',
            selector: (row: MarkedPigeonInterface) =>
                `${row.markedDay ?? '1'}. ${row.markedTime}`,
            sortable: true,
            width: '120px',
        },
        {
            name: 'Tiempo de vuelo (minutos)',
            selector: (row: MarkedPigeonInterface) =>
                row.time ? formatHoursToMinutes(row.time) : '00:00',
            sortable: true,
            sortFunction: sortNumbers('time'),
        },
        {
            name: 'Promedio de vuelo',
            selector: (row: MarkedPigeonInterface) =>
                `${row.speed?.toFixed(2) ?? '0'} km/h`,
            sortable: true,
            sortFunction: sortNumbers('speed'),
        },
        {
            name: 'Puntos',
            selector: (row: MarkedPigeonInterface) =>
                row.points?.toFixed(3) ?? 0,
            sortable: true,
            sortFunction: sortNumbers('points'),
            width: '100px',
        },
        {
            name: 'Acciones',
            cell: (row: MarkedPigeonInterface) =>
                ActionButton({
                    row,
                }),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            minWidth: '156px',
        },
    ]

    React.useEffect(() => {
        fetchMarkedPigeons()
        fetchPigeonHousesSelect()
        fetchRace(raceId)
    }, [raceId])

    const goToRacings = () => {
        dispatch(setRaceId(null))
        navigate('/racing')
    }

    const updatePoints = () => {
        setIsLoading(true)
        dispatch(
            updateTimesAndPointsStart(raceId, () => {
                fetchMarkedPigeons()
            })
        )
    }

    const [filterText, setFilterText] = React.useState('')
    const { filteredItems } = useSearch(markedPigeons, filterText)

    const SubHeaderComponentMemo = React.useMemo(() => {
        return (
            <div className="d-flex">
                <div className="d-flex align-items-center me-3">
                    Buscar
                    <Input
                        className="ms-2"
                        onChange={(e) => setFilterText(e.target.value)}
                        value={filterText}
                    />
                </div>
                <div>
                    <TableButton type="button" onClick={fetchMarkedPigeons}>
                        <FaRedoAlt />
                    </TableButton>
                </div>
            </div>
        )
    }, [filterText])

    if (raceId === null) {
        return <Spinner />
    }
    return (
        <Container>
            <Row>
                <Col className="d-flex justify-content-between ">
                    <h3>Palomas Marcadas</h3>
                    <div>
                        <Button
                            color="secondary"
                            onClick={() => goToRacings()}
                            style={{
                                marginBottom: '1rem',
                            }}
                            className="me-2"
                        >
                            Volver
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => updatePoints()}
                            style={{
                                marginBottom: '1rem',
                            }}
                            className="me-2"
                            disabled={!canUpdatePoints || notChampionshipOwner}
                        >
                            Actualizar Puntajes
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => {
                                setIsFormOpen(!isFormOpen)
                                setAction(FormActions.CREATE)
                            }}
                            disabled={!canCreateMarkedPigeon}
                            style={{
                                marginBottom: '1rem',
                            }}
                        >
                            Cargar Marcada
                        </Button>
                    </div>

                    <CustomModal
                        title="Cargar Marcada"
                        isOpen={isFormOpen}
                        toggle={toggleModalCreate}
                    >
                        <FormMarkedPigeons
                            createMarkedPigeon={createMarkedPigeon}
                            editMarkedPigeon={editMarkedPigeon}
                            deleteMarkedPigeon={deleteMarkedPigeon}
                            action={action}
                            initialValues={{
                                ...initialValues,
                                pigeonRacingId: raceId,
                            }}
                            markedData={markedData}
                            isOpen={isFormOpen}
                            disabled={[
                                FormActions.DETAIL,
                                FormActions.DELETE,
                            ].includes(action)}
                            pigeonHousesSelect={pigeonHousesSelect}
                        />
                    </CustomModal>
                </Col>
            </Row>
            {race && (
                <Row>
                    <ColHeader>
                        <Label className="me-4">
                            Carrera: <span>{race.name}</span>
                        </Label>
                        <Label className="me-4">
                            Fecha:
                            <span>
                                {moment(race.date).format('DD/MM/YYYY')}
                            </span>
                        </Label>
                        <Label className="me-4">
                            Hora largada:
                            <span>{race.startTime}</span>
                        </Label>
                        <Label className="me-4">
                            Estado:
                            <span>
                                {race.state ? RaceStateLabels[race.state] : ''}
                            </span>
                        </Label>
                        {/* // lat // lng */}
                    </ColHeader>
                </Row>
            )}
            <Row>
                <Col>
                    <CustomTable
                        noHeader
                        striped
                        pagination
                        data={filteredItems}
                        subHeader
                        subHeaderComponent={SubHeaderComponentMemo}
                        progressPending={isLoading}
                        progressComponent={
                            <Spinner color="primary" type="grow">
                                Cargando...
                            </Spinner>
                        }
                        columns={columns}
                        dense
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default MarkedPigeons
