export interface Racing {
    id: number
    championshipId: number
    name: string
    line: number
    date: string
    startTime: string
    lat: number
    lng: number
    distance: string
    type: number
    state: number
    categorie: number
    details: string
    pigeonsSended: number
    positions: number
    orientation: string
    typeAccordingToDistance: string
    windDirection: string
    windIntensity: string
    isDeleted: boolean
    createdAt: string
    championship_id: number
    pausedTime?: {
        date: string
        startTime: string
        endTime: string
    }
    pigeonRacingChampionships: any[]
    scoredPerRace: number
}

export const RaceType = {
    SOCIAL: 1, // Social
    TEAMS: 2, // Equipos
}

export const RaceTypeLabels = {
    [RaceType.SOCIAL]: 'Social',
    [RaceType.TEAMS]: 'Equipos',
}

export const RaceTypes = [
    {
        value: '1',
        label: 'Social',
    },
    {
        value: '2',
        label: 'Equipos',
    },
]

export const RaceState = {
    NEXT_RACE: 1, // Próxima carrera
    IN_RACE: 2, // En carrera
    SUSPENDED: 3, // Suspendida
    CLOSED: 4, // Cerrada
    UNCALCULATED_RACE: 5, // Carrera sin calcular
}

export const RaceStateLabels = {
    [RaceState.NEXT_RACE]: 'Próxima carrera',
    [RaceState.IN_RACE]: 'En carrera',
    [RaceState.SUSPENDED]: 'Suspendida',
    [RaceState.CLOSED]: 'Cerrada',
    [RaceState.UNCALCULATED_RACE]: 'Carrera sin calcular',
}

export const RaceStates = [
    {
        value: '1',
        label: 'Próxima carrera',
    },
    {
        value: '2',
        label: 'En carrera',
    },
    {
        value: '3',
        label: 'Suspendida',
    },
    {
        value: '4',
        label: 'Cerrada',
    },
    {
        value: '5',
        label: 'Carrera sin calcular',
    },
]

export const RaceCategory = {
    PIGEONS: 1, // Pichones
    PREPARATION: 2, // Preparación
    SPECIAL: 3, // Especial
    GRATE_BACKGROUND: 4, // Gram fondo
    ADULTS: 5, // Adultas
    MIXED: 6, // Mixtas
    BACKGROUND: 7, // Fondo
    SPEED: 8, // Velocidad
}

export const RaceCategoryLabels = {
    [RaceCategory.PIGEONS]: 'Pichones',
    [RaceCategory.PREPARATION]: 'Preparación',
    [RaceCategory.SPECIAL]: 'Especial',
    [RaceCategory.GRATE_BACKGROUND]: 'Gram fondo',
    [RaceCategory.ADULTS]: 'Adultas',
    [RaceCategory.MIXED]: 'Mixtas',
    [RaceCategory.BACKGROUND]: 'Fondo',
    [RaceCategory.SPEED]: 'Velocidad',
}

export const RaceCategories = [
    {
        value: '1',
        label: 'Pichones',
    },
    {
        value: '2',
        label: 'Preparación',
    },
    {
        value: '3',
        label: 'Especial',
    },
    {
        value: '4',
        label: 'Gram fondo',
    },
    {
        value: '5',
        label: 'Adultas',
    },
    {
        value: '6',
        label: 'Mixtas',
    },
    {
        value: '7',
        label: 'Fondo',
    },
    {
        value: '8',
        label: 'Velocidad',
    },
]
