import adminPermissions from './form/admin.json'
import clubOwnerPermissions from './form/club_owner.json'
import partnerPermissions from './form/partner.json'
import { TypeProfiles } from '../../models/user'

interface PermissionFormUsersInterface {
    username: boolean
    email: boolean
    locationId: boolean
    pigeonHouseId: boolean
    clubId: boolean
    profile: boolean
}

export interface PermissionFormPigeonHousesInterface {
    clubId: boolean
    locationId?: boolean
    isPublic?: boolean
    state?: boolean
    lat?: boolean
    lng?: boolean
}

interface PermissionFormMarkedPigeonsInterface {
    pigeonHouseId: boolean
}

export interface PermissionsFormInterface {
    profileType: string
    usersEdit: PermissionFormUsersInterface
    pigeonHousesCreate: PermissionFormPigeonHousesInterface
    pigeonHousesEdit: PermissionFormPigeonHousesInterface
    markedPigeons: PermissionFormMarkedPigeonsInterface
}

export const getFormPermissions = (
    profile: TypeProfiles
): PermissionsFormInterface => {
    const permissions = {
        [TypeProfiles.ADMIN]: adminPermissions,
        [TypeProfiles.RACEMAKER]: clubOwnerPermissions,
        [TypeProfiles.USER]: partnerPermissions,
    }

    return permissions[profile]
}
