import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner, Row, Col } from 'reactstrap'

import Container from '../../components/Container'

import { SelectInterface } from '../../models/select'
import { fetchLocationsStart } from '../../store/actions/locations'
import {
    fetchProfileStart,
    editProfileStart,
} from '../../store/actions/profile'
import { User } from '../../models/user'

import { ContForm } from '../../styles/conteiners/conteiners'
import FormUser, { CreateUserFormInterface } from '../../components/FormUser'
import { fetchClubsSelectStart } from '../../store/actions/clubs'
import { fetchPigeonHousesSelectStart } from '../../store/actions/pigeonHouses'
import { IRootState } from '../../store/reducers'
import { getPermissions } from '../../utils/permissions'

function MyProfile() {
    const dispatch = useDispatch()
    const [myProfile, setMyProfile] = useState<User>({} as User)
    const [loadingProfile, setLoadingProfile] = useState<boolean>(true)
    const [locations, setLocations] = useState<SelectInterface[]>([])
    const [clubsSelect, setClubsSelect] = useState<SelectInterface[]>([])
    const [pigeonHousesSelect, setPigeonHousesSelect] = useState<
        SelectInterface[]
    >([])
    const isLoadingProfile = useSelector(
        (state: IRootState) => state.profileReducer.loadingProfile
    )
    const fetchLocations = () => {
        dispatch(
            fetchLocationsStart((locationsFetched) =>
                setLocations(locationsFetched)
            )
        )
    }
    const fetchClubsSelect = () => {
        dispatch(
            fetchClubsSelectStart((clubsFetched) =>
                setClubsSelect(clubsFetched)
            )
        )
    }
    const fetchPigeonHousesSelect = () => {
        dispatch(
            fetchPigeonHousesSelectStart((pigeonHousesFetched) =>
                setPigeonHousesSelect(pigeonHousesFetched)
            )
        )
    }

    const fetchProfile = () => {
        dispatch(
            fetchProfileStart(
                (profileFetched) => {
                    setMyProfile(profileFetched)
                    setLoadingProfile(false)
                },
                () => setLoadingProfile(false)
            )
        )
    }

    const editMyProfile = (values: CreateUserFormInterface) => {
        const onSuccess = () => {
            fetchProfile()
        }
        dispatch(editProfileStart(values, onSuccess))
    }
    const userLogged: User = useSelector(
        (state: IRootState) => state.authReducer.user
    )
    const { myProfile: myProfilePermissions } = getPermissions(
        userLogged?.profile
    )
    const { edit: canEditMyProfile } = myProfilePermissions

    React.useEffect(() => {
        fetchProfile()
        fetchLocations()
        fetchClubsSelect()
        fetchPigeonHousesSelect()
    }, [])

    return (
        <div className="main-content">
            {!isLoadingProfile && !loadingProfile ? (
                <Container>
                    <div>
                        <h2>Mi Perfil</h2>
                    </div>
                    <Row className="mt-3">
                        <ContForm>
                            <Col>
                                <FormUser
                                    createUser={editMyProfile}
                                    locations={locations}
                                    initialValues={myProfile}
                                    editMode
                                    disabled={!canEditMyProfile}
                                    clubsSelect={clubsSelect}
                                    pigeonHousesSelect={pigeonHousesSelect}
                                />
                            </Col>
                        </ContForm>
                    </Row>
                </Container>
            ) : (
                <Spinner color="primary" type="grow">
                    Cargando...
                </Spinner>
            )}
        </div>
    )
}

export default MyProfile
