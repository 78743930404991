import React, { useCallback, useEffect } from 'react'
import searchHelper from '../utils/searchHelper'

function useSearch(items, search: string, translations?: any) {
    const [filteredItems, setFilteredItems] = React.useState(items)
    const filterItems = useCallback(() => {
        if (search === '') {
            setFilteredItems(items)
            return
        }
        setFilteredItems(
            searchHelper.searchInObject(items, search, translations)
        )
    }, [search, items])

    useEffect(() => {
        filterItems()
    }, [filterItems, search])

    return {
        filteredItems,
    }
}

export default useSearch
