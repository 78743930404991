import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Form, Button, Col, FormGroup, Label, Row } from 'reactstrap'

import InputForm from '../InputForm'
import { SelectInterface } from '../../models/select'
import { findSelected, parseSelectOptions } from '../../utils/selectHelper'

import MapWrapper, { MapModal } from '../MapWrapper'
import Select from '../Select'
import LblInputGPSPoint from '../LblInputGPSPoint'

export interface CreateClubFormInterface {
    name: string
    email: string
    address: string
    establishment: string
    locationId: number
    lat: number
    lng: number
    foundation: string
}

interface FormUserProps {
    createClub: (values: CreateClubFormInterface) => void
    initialValues: CreateClubFormInterface
    editMode: boolean
    locations: SelectInterface[]
    disabled?: boolean
    btnSaveName?: string
}

function FormClub({
    createClub,
    initialValues,
    locations,
    editMode = false,
    disabled = false,
    btnSaveName,
}: FormUserProps) {
    const [markers, setMarkers] = useState([])

    const locationsMapped = parseSelectOptions(locations)

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        setValues,
    } = useFormik({
        initialValues: {
            ...initialValues,
        },
        onSubmit: (data, { setSubmitting }) => {
            createClub(data)
            setSubmitting(false)
        },
        validate: (data) => {
            const errorsValidate: Partial<CreateClubFormInterface> = {}
            if (!data.name) {
                errorsValidate.name = 'Required'
            }
            return errorsValidate
        },
    })

    useEffect(() => {
        if (initialValues) {
            setValues(initialValues)
            if (initialValues.lat && initialValues.lng) {
                setMarkers([
                    {
                        name: initialValues.name,
                        defaultIsOpen: true,
                        point: {
                            lat: initialValues.lat,
                            lng: initialValues.lng,
                        },
                    },
                ])
            }
        }
    }, [initialValues])

    const handleChangeMarkers = (field: string, value: number) => {
        setMarkers([
            {
                ...markers,
                name: values.name,
                point: {
                    lat: field === 'lat' ? value : values.lat,
                    lng: field === 'lng' ? value : values.lng,
                },
            },
        ])
    }

    return (
        <Form className="form" onSubmit={handleSubmit}>
            <Row>
                <Col xs="6">
                    <FormGroup>
                        <Label for="name">Nombre</Label>
                        <InputForm
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            values={values}
                            disabled={disabled}
                            placeholder="Nombre"
                            fieldName="name"
                        />
                    </FormGroup>
                </Col>
                <Col xs="12">
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <InputForm
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            disabled={disabled}
                            placeholder="Email"
                            values={values}
                            fieldName="email"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs="6">
                    <FormGroup>
                        <Label for="address">Dirección</Label>
                        <InputForm
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            placeholder="Dirección"
                            disabled={disabled}
                            values={values}
                            fieldName="address"
                        />
                    </FormGroup>
                </Col>
                <Col xs="6">
                    <FormGroup>
                        <Label for="establishment">Establecimiento</Label>
                        <InputForm
                            errors={errors}
                            handleChange={handleChange}
                            disabled={disabled}
                            handleBlur={handleBlur}
                            touched={touched}
                            placeholder="Establecimiento"
                            values={values}
                            fieldName="establishment"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs="6">
                    <LblInputGPSPoint
                        label="Latitud"
                        errors={errors}
                        touched={touched}
                        handleFieldValues={(field, value) => {
                            setFieldValue(field, value)
                            handleChangeMarkers(field, value)
                        }}
                        values={values}
                        disabled={disabled}
                        placeholder="Latitud"
                        fieldName="lat"
                    />
                </Col>
                <Col xs="6">
                    <LblInputGPSPoint
                        label="Longitud"
                        errors={errors}
                        touched={touched}
                        handleFieldValues={(field, value) => {
                            setFieldValue(field, value)
                            handleChangeMarkers(field, value)
                        }}
                        values={values}
                        disabled={disabled}
                        placeholder="Longitud"
                        fieldName="lng"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs="6">
                    <FormGroup>
                        <Label for="locationId">Localidad</Label>
                        <Select
                            fieldName="locationId"
                            touched={touched}
                            errors={errors}
                            options={locationsMapped}
                            onChange={({ value }) =>
                                setFieldValue('locationId', value)
                            }
                            disabled={disabled}
                            value={findSelected(
                                locationsMapped,
                                values.locationId
                            )}
                        />
                    </FormGroup>
                </Col>
                <Col xs="6">
                    <FormGroup>
                        <Label for="foundation">Fundación</Label>
                        <InputForm
                            errors={errors}
                            handleChange={handleChange}
                            placeholder="Fundación"
                            handleBlur={handleBlur}
                            disabled={disabled}
                            touched={touched}
                            values={values}
                            fieldName="foundation"
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col className="mt-2 d-flex justify-content-between">
                    <MapModal>
                        <MapWrapper markers={markers} height="80vh" />
                    </MapModal>
                    {!disabled && (
                        <Button
                            type="submit"
                            color="primary"
                            disabled={isSubmitting}
                        >
                            {btnSaveName || 'Guardar'}
                        </Button>
                    )}
                </Col>
            </Row>
        </Form>
    )
}

FormClub.defaultProps = {
    disabled: false,
    btnSaveName: 'Guardar',
}

export default FormClub
