import { User } from '../../models/user'
import { CreateUserFormInterface } from '../../components/FormUser'

export const FETCH_USERS_START = 'FETCH_USERS_START'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED'

export const DELETE_USER_START = 'DELETE_USER_START'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'

export const CREATE_USER_START = 'CREATE_USER_START'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'

export const EDIT_USER_START = 'EDIT_USER_START'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_FAILED = 'EDIT_USER_FAILED'

export const fetchUsersStart = (onLoad: (users: User[]) => void) => ({
    type: FETCH_USERS_START,
    payload: {
        onLoad,
    },
})

export const fetchUsersSuccess = (users: Array<User>) => ({
    type: FETCH_USERS_SUCCESS,
    payload: users,
})

export const fetchUsersFailed = () => ({
    type: FETCH_USERS_FAILED,
})

export const deleteUserStart = (
    userId: string,
    onSuccess: () => void,
    onError: (message: string | null) => void
) => ({
    type: DELETE_USER_START,
    payload: { userId, onSuccess, onError },
})

export const deleteUserSuccess = () => ({
    type: DELETE_USER_SUCCESS,
})

export const deleteUserFailed = () => ({
    type: DELETE_USER_FAILED,
})

export const createUserStart = (
    values: CreateUserFormInterface,
    onSuccess: () => void,
    onError: (message: string | null) => void
) => ({
    type: CREATE_USER_START,
    payload: {
        values,
        onSuccess,
        onError,
    },
})

export const createUserSuccess = () => ({
    type: CREATE_USER_SUCCESS,
})

export const createUserFailed = () => ({
    type: CREATE_USER_FAILED,
})

export const editUserStart = (
    values: CreateUserFormInterface,
    userId: string,
    onSuccess: () => void,
    onError: (message: string | null) => void
) => ({
    type: EDIT_USER_START,
    payload: {
        values,
        userId,
        onSuccess,
        onError,
    },
})

export const editUserSuccess = () => ({
    type: EDIT_USER_SUCCESS,
})

export const editUserFailed = () => ({
    type: EDIT_USER_FAILED,
})
