import { axiosInstance } from './base.service'
import { PigeonHousesFormInterface } from '../components/FormPigeonHouses'

export const fetchPigeonHouses = () => {
    return axiosInstance.get(`/pigeonHouses`)
}

export const fetchPigeonHousesSelect = () => {
    return axiosInstance.get(`/pigeonHouses/select`)
}

export const deletePigeonHouse = (pigeonHouseId: string) => {
    return axiosInstance.delete(`/pigeonHouses/${pigeonHouseId}`)
}

export const createPigeonHouse = (values: PigeonHousesFormInterface) => {
    return axiosInstance.post(`/pigeonHouses`, values)
}

export const editPigeonHouse = (
    pigeonHouseId: string,
    values: PigeonHousesFormInterface
) => {
    return axiosInstance.put(`/pigeonHouses/${pigeonHouseId}`, values)
}

export const fetchMyPigeonHouse = () => {
    return axiosInstance.get(`/pigeonHouses/mypigeonhouse`)
}

export const editMyPigeonHouse = (values: PigeonHousesFormInterface) => {
    return axiosInstance.put(`/pigeonHouses/mypigeonhouse`, values)
}
