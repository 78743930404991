import React from 'react'
import RSelect from 'react-select'
import { FormFeedback } from 'reactstrap'
import { basicStyles, customStyles, fixedHeightStyles } from './styles'

interface SelectProps {
    onChange: (e: any) => void
    touched: any
    errors: any
    value: any
    options: any[]
    fieldName: string
    disabled?: boolean
    required?: boolean
    className?: string
    isMulti?: boolean
    isClearable?: boolean
}

function Select({
    onChange,
    value,
    options,
    errors,
    touched,
    fieldName,
    disabled = false,
    required = false,
    isMulti = false,
    className,
    isClearable,
}: SelectProps) {
    const styles =
        required && errors[fieldName] && touched[fieldName]
            ? customStyles
            : basicStyles
    const isFixedHeightStyles = isMulti ? fixedHeightStyles : {}

    return (
        <div>
            <RSelect
                isMulti={isMulti}
                options={options}
                onChange={onChange}
                styles={{ ...styles, ...isFixedHeightStyles }}
                isDisabled={disabled}
                menuPlacement="auto"
                menuPosition="fixed"
                value={value}
                className={className}
                isClearable={isClearable}
            />
            <FormFeedback invalid>
                {errors[fieldName] && touched[fieldName] && errors[fieldName]}
            </FormFeedback>
        </div>
    )
}

Select.defaultProps = {
    disabled: false,
    required: false,
    isMulti: false,
    className: '',
    isClearable: false,
}

export default Select
