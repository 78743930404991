import {
    FETCH_RACINGS_FAILED,
    FETCH_RACINGS_START,
    FETCH_RACINGS_SUCCESS,
    SET_CHAMPIONSHIP_ID,
    SET_RACE_ID,
} from '../actions/racings'

interface InitialStateType {
    loadingRacings: boolean
    raceId: number | null
}

const initialState: InitialStateType = {
    loadingRacings: false,
    raceId: null,
}

const racingsReducer = function (
    // eslint-disable-next-line default-param-last
    state: InitialStateType = initialState,
    action: any
) {
    switch (action.type) {
        case FETCH_RACINGS_START:
            return {
                ...state,
                loadingRacings: true,
            }
        case FETCH_RACINGS_SUCCESS:
            return {
                ...state,
                loadingRacings: false,
            }
        case FETCH_RACINGS_FAILED:
            return {
                ...state,
                loadingRacings: false,
            }
        case SET_RACE_ID: {
            return {
                ...state,
                raceId: action.payload.raceId ?? null,
            }
        }
        case SET_CHAMPIONSHIP_ID: {
            return {
                ...state,
                championshipId: action.payload.championships ?? null,
            }
        }
        default:
            return state
    }
}

export default racingsReducer
