import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, Row, Col, Spinner } from 'reactstrap'
import {
    FaPencilAlt,
    FaTrash,
    FaMapMarkedAlt,
    FaRedoAlt,
    FaRegEye,
    FaSortNumericUpAlt,
    FaAward,
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import moment from 'moment'
import { SelectInterface } from '../../models/select'
import { IRootState } from '../../store/reducers'

import Select from '../../components/Select'
import CustomTable from '../../components/CustomTable'
import CustomModal from '../../components/CustomModal'
import Container from '../../components/Container'

import useSearch from '../../hooks/useSearch'
import { User } from '../../models/user'
import FormRace from '../../components/FormRace'
import ModalConfirm from '../../components/ModalConfirm'
import {
    createRacingStart,
    deleteRacingStart,
    editRacingStart,
    fetchRacingsStart,
    setChampionshipId,
    setRaceId,
} from '../../store/actions/racings'
import {
    RaceCategoryLabels,
    RaceStateLabels,
    RaceTypeLabels,
    Racing,
} from '../../models/racing'
import { fetchChampionshipsSelectStart } from '../../store/actions/championships'
import { getPermissions } from '../../utils/permissions'
import { findSelected, parseSelectOptions } from '../../utils/selectHelper'

import {
    FormActions,
    crudMessagesSuccess,
    crudMessagesError,
} from '../../utils/crudHelper'

import { TableButton } from '../../styles/buttons/tableButtons'
import { WrapFilters } from '../../styles/conteiners/filters'

const initialValues = {
    championshipId: 1,
    name: '',
    line: 1,
    date: moment(),
    startTime: '00:00:00',
    lat: 0,
    lng: 0,
    distance: '0',
    type: 1,
    state: 1,
    categorie: 1,
    details: '',
    pigeonsSended: 0,
    positions: 0,
    orientation: '',
    typeAccordingToDistance: '',
    windDirection: '',
    windIntensity: '',
    pausedTime: null,
    pigeonRacingChampionships: [],
    scoredPerRace: 0,
}

function RacingScreen() {
    const dispatch = useDispatch()

    const [action, setAction] = useState(FormActions.CREATE)
    const [racings, setRacings] = useState<Racing[]>([])
    const [raceEdit, setRaceEdit] = useState<Racing>(null)
    const [championshipsSelect, setChampionshipsSelect] = useState<
        SelectInterface[]
    >([])
    const [modalVisible, setModalVisible] = useState<boolean>(false)
    const [modalDetailsVisible, setModalDetailsVisible] =
        useState<boolean>(false)
    const [modalConfirmDeleteVisible, setModalConfirmDeleteVisible] =
        useState<boolean>(false)

    const [championshipIdFiltered, setChampionshipIdFiltered] = useState(null)
    const [championshipsMapped, setChampionshipsMapped] = useState([])

    const isLoadingRacings = useSelector(
        (state: IRootState) => state.racingsReducer.loadingRacings
    )
    const userLogged: User = useSelector(
        (state: IRootState) => state.authReducer.user
    )
    const navigate = useNavigate()

    const fetchChampionshipsSelect = () => {
        dispatch(
            fetchChampionshipsSelectStart((championshipsFetched) => {
                setChampionshipsSelect(championshipsFetched)
                setChampionshipsMapped(parseSelectOptions(championshipsFetched))
            })
        )
    }
    const fetchRacings = () =>
        dispatch(
            fetchRacingsStart(
                (racingsFetched) => setRacings(racingsFetched),
                championshipIdFiltered
            )
        )
    const toggleModalVisible = () => setModalVisible(!modalVisible)
    const toggleModalDetailsVisible = () =>
        setModalDetailsVisible(!modalDetailsVisible)
    const toggleConfirmDeleteModal = () =>
        setModalConfirmDeleteVisible(!modalConfirmDeleteVisible)

    const openCreateModal = () => {
        setRaceEdit(null)
        toggleModalVisible()
    }

    const goToPigeonMarked = (raceId: number) => {
        if (raceId) {
            dispatch(setRaceId(raceId))
            navigate('/markedPigeons')
        }
    }

    const goToPigeonMarkedPositions = (
        raceId: number,
        championships: any[]
    ) => {
        if (raceId) {
            dispatch(setRaceId(raceId))
            dispatch(setChampionshipId(championships))
            navigate('/positions')
        }
    }
    const { racing: racingPermissions } = getPermissions(userLogged?.profile)
    const {
        create: canCreateRace,
        edit: canEditRace,
        delete: canDeleteRace,
    } = racingPermissions

    const ActionButton = useCallback(({ row }) => {
        const notChampionshipOwner = !row?.pigeonRacingChampionships
            ?.map((championship) => championship.clubId)
            .includes(userLogged?.clubId)
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Button
                    color="primary"
                    onClick={() => {
                        setModalVisible(true)
                        setRaceEdit({
                            ...row,
                            date: moment(row.date),
                            pausedTime:
                                row.pausedTime && row.pausedTime.length > 0
                                    ? {
                                          startTime:
                                              row.pausedTime[0].startTime,
                                          endTime: row.pausedTime[0].endTime,
                                      }
                                    : null,
                        })
                        setAction(FormActions.EDIT)
                    }}
                    disabled={!canEditRace || notChampionshipOwner}
                    className="p-1 pt-0 ms-2"
                >
                    <FaPencilAlt />
                </Button>
                <Button
                    color="danger"
                    onClick={() => {
                        setModalConfirmDeleteVisible(true)
                        setRaceEdit({ ...row, date: moment(row.date) })
                        setAction(FormActions.DELETE)
                    }}
                    disabled={!canDeleteRace || notChampionshipOwner}
                    className="p-1 pt-0 ms-2"
                >
                    <FaTrash />
                </Button>
                <Button
                    color="success"
                    onClick={() => {
                        setModalDetailsVisible(true)
                        setRaceEdit({ ...row, date: moment(row.date) })
                        setAction(FormActions.DETAIL)
                    }}
                    className="p-1 pt-0 ms-2"
                >
                    <FaRegEye color="white" />
                </Button>
                <Button
                    color="info"
                    onClick={() => {
                        goToPigeonMarked(row.id)
                    }}
                    className="p-1 pt-0 ms-2"
                >
                    <FaMapMarkedAlt color="white" />
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        goToPigeonMarkedPositions(
                            row.id,
                            row.pigeonRacingChampionships
                        )
                    }}
                    className="p-1 pt-0 ms-2 me-2"
                >
                    <FaAward color="white" />
                </Button>
            </div>
        )
    }, [])

    const columns = [
        {
            name: 'Nombre',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Torneo',
            selector: (row) =>
                row?.pigeonRacingChampionships
                    ?.map((championship) => championship.name)
                    ?.join(', ') ?? '',
            sortable: true,
        },
        {
            name: 'Fecha',
            selector: (row) => moment(row.date).format('DD/MM/YYYY'),
            sortable: true,
            width: '130px',
        },
        {
            name: 'Hora Inicio',
            selector: (row) => row.startTime,
            sortable: true,
            width: '100px',
        },
        {
            name: 'Distancia',
            selector: (row) => row.distance,
            sortable: true,
            width: '100px',
        },
        {
            name: 'Estado',
            selector: (row) => RaceStateLabels[row.state] as string,
            sortable: true,
        },
        {
            name: 'Categoria',
            selector: (row) => RaceCategoryLabels[row.categorie] as string,
            sortable: true,
        },
        {
            name: 'Tipo Carrera',
            selector: (row) => RaceTypeLabels[row.type] as string,
            sortable: true,
        },
        // {
        //     name: 'Detalles',
        //     selector: (row) => row.details as string,
        //     sortable: true,
        // },
        {
            name: 'Acciones',
            cell: (row) =>
                ActionButton({
                    row,
                }),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            minWidth: '200px',
        },
    ]

    React.useEffect(() => {
        fetchChampionshipsSelect()
        fetchRacings()
    }, [])

    React.useEffect(() => {
        fetchRacings()
    }, [championshipIdFiltered])

    const onError = (errorMessage: string | null) => {
        const message = errorMessage ?? crudMessagesError[action]
        toast.error(message)
    }

    const createRace = (values) => {
        dispatch(
            createRacingStart(
                values,
                () => {
                    fetchRacings()
                    toggleModalVisible()
                    toast.success(crudMessagesSuccess[action])
                },
                onError
            )
        )
    }

    const editRace = (values) => {
        dispatch(
            editRacingStart(
                {
                    ...values,
                    pigeonRacingChampionships:
                        values.pigeonRacingChampionships.map((championship) =>
                            typeof championship === 'number'
                                ? championship
                                : championship.id
                        ),
                },
                raceEdit?.id.toString(),
                () => {
                    fetchRacings()
                    toggleModalVisible()
                    toast.success(crudMessagesSuccess[action])
                },
                onError
            )
        )
    }
    const onDeleteChampionship = () => {
        dispatch(
            deleteRacingStart(
                raceEdit?.id.toString(),
                () => {
                    fetchRacings()
                    toggleConfirmDeleteModal()
                    toast.success(crudMessagesSuccess[action])
                },
                onError
            )
        )
    }

    const [filterText, setFilterText] = React.useState('')
    const translations = {
        state: RaceStateLabels,
        categorie: RaceCategoryLabels,
        type: RaceTypeLabels,
    }
    const { filteredItems } = useSearch(racings, filterText, translations)

    const SubHeaderComponentMemo = React.useMemo(() => {
        return (
            <WrapFilters>
                <div className="d-flex align-items-center me-3">
                    Torneo
                    <div style={{ minWidth: '250px', maxWidth: '100vw' }}>
                        <Select
                            options={championshipsMapped}
                            onChange={(e) => {
                                return e
                                    ? setChampionshipIdFiltered(e.value)
                                    : setChampionshipIdFiltered(null)
                            }}
                            required
                            fieldName="championshipId"
                            touched={{}}
                            errors={{}}
                            value={
                                championshipIdFiltered
                                    ? findSelected(
                                          championshipsMapped,
                                          championshipIdFiltered
                                      )
                                    : null
                            }
                            className="ms-2"
                            isClearable
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center me-3">
                    Buscar
                    <Input
                        className="ms-2"
                        onChange={(e) => setFilterText(e.target.value)}
                        value={filterText}
                    />
                </div>
                <div>
                    <TableButton type="button" onClick={fetchRacings}>
                        <FaRedoAlt />
                    </TableButton>
                </div>
            </WrapFilters>
        )
    }, [filterText, championshipsMapped, championshipIdFiltered])

    return (
        <Container>
            <Row>
                <Col className="d-flex justify-content-between ">
                    <div>
                        <h2>Carreras</h2>
                    </div>
                    <Button
                        color="primary"
                        onClick={openCreateModal}
                        disabled={!canCreateRace}
                        style={{
                            marginBottom: '1rem',
                        }}
                    >
                        Crear Carrera
                    </Button>
                    <CustomModal
                        isOpen={modalVisible}
                        toggle={toggleModalVisible}
                        title={raceEdit ? 'Editar Carrera' : 'Crear Carrera'}
                        className="modal-xl"
                    >
                        <Row className="mt-3">
                            <FormRace
                                createRace={raceEdit ? editRace : createRace}
                                initialValues={raceEdit || initialValues}
                                isEdit={!!raceEdit}
                                canEdit={raceEdit ? canEditRace : canCreateRace}
                                championshipsSelect={championshipsSelect}
                                isOpen={modalVisible}
                            />
                        </Row>
                    </CustomModal>
                </Col>
                <ModalConfirm
                    isOpen={modalConfirmDeleteVisible}
                    toggle={toggleConfirmDeleteModal}
                    onConfirm={() => onDeleteChampionship()}
                    onCancel={() => setModalConfirmDeleteVisible(false)}
                    titleHeader={`Eliminar Club ${raceEdit?.name}?`}
                    title={`¿Está seguro que desea eliminar la carrera ${raceEdit?.name}?`}
                    confirmButtonText="Eliminar"
                >
                    <FormRace
                        createRace={() => null}
                        initialValues={raceEdit || initialValues}
                        isEdit={!!raceEdit}
                        canEdit={canEditRace}
                        championshipsSelect={championshipsSelect}
                        disabled
                        isOpen={modalConfirmDeleteVisible}
                    />
                </ModalConfirm>
                <CustomModal
                    isOpen={modalDetailsVisible}
                    toggle={toggleModalDetailsVisible}
                    title="Ver Carrera"
                    className="modal-xl"
                >
                    <Row className="mt-3">
                        <FormRace
                            createRace={() => null}
                            initialValues={raceEdit || initialValues}
                            isEdit={!!raceEdit}
                            isOpen={modalDetailsVisible}
                            canEdit={canEditRace}
                            championshipsSelect={championshipsSelect}
                            disabled
                        />
                    </Row>
                </CustomModal>
            </Row>
            <Row>
                <Col>
                    <CustomTable
                        noHeader
                        striped
                        pagination
                        data={filteredItems}
                        subHeader
                        subHeaderComponent={SubHeaderComponentMemo}
                        progressPending={isLoadingRacings}
                        progressComponent={
                            <Spinner color="primary" type="grow">
                                Cargando...
                            </Spinner>
                        }
                        columns={columns}
                        dense
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default RacingScreen
