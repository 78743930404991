import {
    FETCH_PROFILE_FAILED,
    FETCH_PROFILE_START,
    FETCH_PROFILE_SUCCESS,
} from '../actions/profile'

interface InitialStateType {
    loadingProfile: boolean
}

const initialState: InitialStateType = {
    loadingProfile: false,
}

const profileReducer = function (
    // eslint-disable-next-line default-param-last
    state: InitialStateType = initialState,
    action: any
) {
    switch (action.type) {
        case FETCH_PROFILE_START:
            return {
                ...state,
                loadingProfile: true,
            }
        case FETCH_PROFILE_SUCCESS:
            return {
                ...state,
                loadingProfile: false,
            }
        case FETCH_PROFILE_FAILED:
            return {
                ...state,
                loadingProfile: false,
            }
        default:
            return state
    }
}

export default profileReducer
