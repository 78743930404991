import { Championship } from '../../models/championship'
import { SelectInterface } from '../../models/select'

export const FETCH_CHAMPIONSHIPS_START = 'FETCH_CHAMPIONSHIPS_START'
export const FETCH_CHAMPIONSHIPS_SUCCESS = 'FETCH_CHAMPIONSHIPS_SUCCESS'
export const FETCH_CHAMPIONSHIPS_FAILED = 'FETCH_CHAMPIONSHIPS_FAILED'
export const CREATE_CHAMPIONSHIP_START = 'CREATE_CHAMPIONSHIP_START'
export const CREATE_CHAMPIONSHIP_SUCCESS = 'CREATE_CHAMPIONSHIP_SUCCESS'
export const CREATE_CHAMPIONSHIP_FAILED = 'CREATE_CHAMPIONSHIP_FAILED'
export const EDIT_CHAMPIONSHIP_START = 'EDIT_CHAMPIONSHIP_START'
export const EDIT_CHAMPIONSHIP_SUCCESS = 'EDIT_CHAMPIONSHIP_SUCCESS'
export const EDIT_CHAMPIONSHIP_FAILED = 'EDIT_CHAMPIONSHIP_FAILED'
export const DELETE_CHAMPIONSHIP_START = 'DELETE_CHAMPIONSHIP_START'
export const DELETE_CHAMPIONSHIP_SUCCESS = 'DELETE_CHAMPIONSHIP_SUCCESS'
export const DELETE_CHAMPIONSHIP_FAILED = 'DELETE_CHAMPIONSHIP_FAILED'
export const FETCH_CHAMPIONSHIPS_SELECT_START =
    'FETCH_CHAMPIONSHIPS_SELECT_START'
export const FETCH_CHAMPIONSHIPS_SELECT_SUCCESS =
    'FETCH_CHAMPIONSHIPS_SELECT_SUCCESS'
export const FETCH_CHAMPIONSHIPS_SELECT_FAILED =
    'FETCH_CHAMPIONSHIPS_SELECT_FAILED'

export const fetchChampionshipsStart = (
    onLoad: (championships: Championship[]) => void,
    listPublic: boolean
) => ({
    type: FETCH_CHAMPIONSHIPS_START,
    payload: {
        onLoad,
        listPublic,
    },
})

export const fetchChampionshipsSuccess = (championships: Championship[]) => ({
    type: FETCH_CHAMPIONSHIPS_SUCCESS,
    payload: championships,
})

export const fetchChampionshipsFailed = () => ({
    type: FETCH_CHAMPIONSHIPS_FAILED,
})

export const createChampionshipStart = (
    values: Championship,
    onSuccess: () => void,
    onError: (message: string | null) => void
) => ({
    type: CREATE_CHAMPIONSHIP_START,
    payload: {
        values,
        onSuccess,
        onError,
    },
})

export const createChampionshipSuccess = (
    createdChampionship: Championship
) => ({
    type: CREATE_CHAMPIONSHIP_SUCCESS,
    payload: createdChampionship,
})

export const createChampionshipFailed = () => ({
    type: CREATE_CHAMPIONSHIP_FAILED,
})

export const editChampionshipStart = (
    values: Championship,
    championshipId: string,
    onSuccess: () => void,
    onError: (message: string | null) => void
) => ({
    type: EDIT_CHAMPIONSHIP_START,
    payload: {
        values,
        championshipId,
        onSuccess,
        onError,
    },
})

export const editChampionshipSuccess = (editedChampionship: Championship) => ({
    type: EDIT_CHAMPIONSHIP_SUCCESS,
    payload: editedChampionship,
})

export const editChampionshipFailed = () => ({
    type: EDIT_CHAMPIONSHIP_FAILED,
})

export const deleteChampionshipStart = (
    championshipId: string,
    onSuccess: () => void,
    onError: (message: string | null) => void
) => ({
    type: DELETE_CHAMPIONSHIP_START,
    payload: {
        championshipId,
        onSuccess,
        onError,
    },
})

export const deleteChampionshipSuccess = () => ({
    type: DELETE_CHAMPIONSHIP_SUCCESS,
})

export const deleteChampionshipFailed = () => ({
    type: DELETE_CHAMPIONSHIP_FAILED,
})

export const fetchChampionshipsSelectStart = (
    onLoad: (championships: SelectInterface[]) => void
) => ({
    type: FETCH_CHAMPIONSHIPS_SELECT_START,
    payload: {
        onLoad,
    },
})

export const fetchChampionshipsSelectSuccess = (
    championships: SelectInterface[]
) => ({
    type: FETCH_CHAMPIONSHIPS_SELECT_SUCCESS,
    payload: championships,
})

export const fetchChampionshipsSelectFailed = () => ({
    type: FETCH_CHAMPIONSHIPS_SELECT_FAILED,
})
