import { SelectInterface } from '../models/select'

export const parseSelectOptions = (selectOptions: SelectInterface[]) => {
    return selectOptions.map(
        ({ name, id }) =>
            ({
                label: name,
                value: id.toString(),
            } as SelectInterface)
    )
}

export const findSelected = (
    selectOptions: SelectInterface[],
    value: number | string
) => {
    return selectOptions.find((option) => option.value === value?.toString())
}

export const findSelectedMultiple = (
    selectOptions: SelectInterface[],
    value: number[] | string[]
) => {
    return value.map((val) => {
        const newVal = typeof val === 'object' ? val.id : val
        return findSelected(selectOptions, newVal)
    })
}

export const findSelectedRaw = (
    selectOptions: SelectInterface[],
    value: number
) => {
    return selectOptions.find(
        (option) => option.id.toString() === value?.toString()
    )
}
