import { call, put, takeLatest } from 'redux-saga/effects'
import {
    FETCH_USERS_START,
    fetchUsersSuccess,
    fetchUsersFailed,
    DELETE_USER_START,
    deleteUserSuccess,
    deleteUserFailed,
    CREATE_USER_START,
    createUserSuccess,
    createUserFailed,
    EDIT_USER_START,
    editUserSuccess,
    editUserFailed,
} from '../actions/users'
import {
    createUser,
    deleteUser,
    editUser,
    fetchUsers,
} from '../../services/users.service'

function* fetchUsersApi({ payload: { onLoad } }: any) {
    try {
        const {
            data: { data: users },
        } = yield call(fetchUsers)
        onLoad(users)
        yield put(fetchUsersSuccess(users))
    } catch {
        yield put(fetchUsersFailed())
    }
}

function* deleteUserApi({ payload: { userId, onSuccess, onError } }: any) {
    try {
        yield call(deleteUser, userId)
        yield put(deleteUserSuccess())
        onSuccess()
    } catch (err) {
        yield put(deleteUserFailed())
        onError(err.response.data.message ?? null)
    }
}

function* createUserApi({ payload: { values, onSuccess, onError } }: any) {
    try {
        yield call(createUser, values)
        yield put(createUserSuccess())
        onSuccess()
    } catch (err) {
        yield put(createUserFailed())
        onError(err.response.data.message ?? null)
    }
}

function* editUserApi({
    payload: { values, userId, onSuccess, onError },
}: any) {
    try {
        yield call(editUser, userId, values)
        yield put(editUserSuccess())
        onSuccess()
    } catch (err) {
        yield put(editUserFailed())
        onError(err.response.data.message ?? null)
    }
}

export function* watchUsersStart() {
    yield takeLatest(FETCH_USERS_START, fetchUsersApi)
    yield takeLatest(DELETE_USER_START, deleteUserApi)
    yield takeLatest(CREATE_USER_START, createUserApi)
    yield takeLatest(EDIT_USER_START, editUserApi)
}
