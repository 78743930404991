import { Racing } from '../../models/racing'
import { MarkedPigeonInterface } from '../../components/FormMarkedPigeons'

export const FETCH_RACINGS_START = 'FETCH_RACINGS_START'
export const FETCH_RACINGS_SUCCESS = 'FETCH_RACINGS_SUCCESS'
export const FETCH_RACINGS_FAILED = 'FETCH_RACINGS_FAILED'
export const CREATE_RACING_START = 'CREATE_RACING_START'
export const CREATE_RACING_SUCCESS = 'CREATE_RACING_SUCCESS'
export const CREATE_RACING_FAILED = 'CREATE_RACING_FAILED'
export const EDIT_RACING_START = 'EDIT_RACING_START'
export const EDIT_RACING_SUCCESS = 'EDIT_RACING_SUCCESS'
export const EDIT_RACING_FAILED = 'EDIT_RACING_FAILED'
export const DELETE_RACING_START = 'DELETE_RACING_START'
export const DELETE_RACING_SUCCESS = 'DELETE_RACING_SUCCESS'
export const DELETE_RACING_FAILED = 'DELETE_RACING_FAILED'

export const CREATE_MARKED_PIGEON_START = 'CREATE_MARKED_PIGEON_START'
export const CREATE_MARKED_PIGEON_SUCCESS = 'CREATE_MARKED_PIGEON_SUCCESS'
export const CREATE_MARKED_PIGEON_FAILED = 'CREATE_MARKED_PIGEON_FAILED'

export const FETCH_MARKED_PIGEON_START = 'FETCH_MARKED_PIGEON_START'
export const FETCH_MARKED_PIGEON_SUCCESS = 'FETCH_MARKED_PIGEON_SUCCESS'
export const FETCH_MARKED_PIGEON_FAILED = 'FETCH_MARKED_PIGEON_FAILED'

export const EDIT_MARKED_PIGEON_START = 'EDIT_MARKED_PIGEON_START'
export const EDIT_MARKED_PIGEON_SUCCESS = 'EDIT_MARKED_PIGEON_SUCCESS'
export const EDIT_MARKED_PIGEON_FAILED = 'EDIT_MARKED_PIGEON_FAILED'

export const DELETE_MARKED_PIGEON_START = 'DELETE_MARKED_PIGEON_START'
export const DELETE_MARKED_PIGEON_SUCCESS = 'DELETE_MARKED_PIGEON_SUCCESS'
export const DELETE_MARKED_PIGEON_FAILED = 'DELETE_MARKED_PIGEON_FAILED'

export const SET_RACE_ID = 'SET_RACE_ID'
export const SET_CHAMPIONSHIP_ID = 'SET_CHAMPIONSHIP_ID'

export const FETCH_RACE_DETAILS_START = 'FETCH_RACE_DETAILS_START'
export const FETCH_RACE_DETAILS_SUCCESS = 'FETCH_RACE_DETAILS_SUCCESS'
export const FETCH_RACE_DETAILS_FAILED = 'FETCH_RACE_DETAILS_FAILED'

export const FETCH_MARKED_PIGEON_GROUPED_START =
    'FETCH_MARKED_PIGEON_GROUPED_START'
export const FETCH_MARKED_PIGEON_GROUPED_SUCCESS =
    'FETCH_MARKED_PIGEON_GROUPED_SUCCESS'
export const FETCH_MARKED_PIGEON_GROUPED_FAILED =
    'FETCH_MARKED_PIGEON_GROUPED_FAILED'

export const UPDATE_TIMES_AND_POINTS_START = 'UPDATE_TIMES_AND_POINTS_START'
export const UPDATE_TIMES_AND_POINTS_SUCCESS = 'UPDATE_TIMES_AND_POINTS_SUCCESS'
export const UPDATE_TIMES_AND_POINTS_FAILED = 'UPDATE_TIMES_AND_POINTS_FAILED'

export const fetchRacingsStart = (
    onLoad: (racings: Racing[]) => void,
    championshipId: number | null
) => ({
    type: FETCH_RACINGS_START,
    payload: {
        onLoad,
        championshipId,
    },
})

export const fetchRacingsSuccess = (racings: Racing[]) => ({
    type: FETCH_RACINGS_SUCCESS,
    payload: racings,
})

export const fetchRacingsFailed = () => ({
    type: FETCH_RACINGS_FAILED,
})

export const createRacingStart = (
    values: Racing,
    onSuccess: () => void,
    onError: (message: string | null) => void
) => ({
    type: CREATE_RACING_START,
    payload: {
        values,
        onSuccess,
        onError,
    },
})

export const createRacingSuccess = (createdRacing: Racing) => ({
    type: CREATE_RACING_SUCCESS,
    payload: createdRacing,
})

export const createRacingFailed = () => ({
    type: CREATE_RACING_FAILED,
})

export const editRacingStart = (
    values: Racing,
    raceId: string,
    onSuccess: () => void,
    onError: (message: string | null) => void
) => ({
    type: EDIT_RACING_START,
    payload: {
        values,
        raceId,
        onSuccess,
        onError,
    },
})

export const editRacingSuccess = (editedRacing: Racing) => ({
    type: EDIT_RACING_SUCCESS,
    payload: editedRacing,
})

export const editRacingFailed = () => ({
    type: EDIT_RACING_FAILED,
})

export const deleteRacingStart = (
    raceId: string,
    onSuccess: () => void,
    onError: (message: string | null) => void
) => ({
    type: DELETE_RACING_START,
    payload: {
        raceId,
        onSuccess,
        onError,
    },
})

export const deleteRacingSuccess = () => ({
    type: DELETE_RACING_SUCCESS,
})

export const deleteRacingFailed = () => ({
    type: DELETE_RACING_FAILED,
})

export const createMarkedPigeonStart = (
    values: MarkedPigeonInterface,
    onSuccess: () => void,
    onError: (message: string | null) => void
) => ({
    type: CREATE_MARKED_PIGEON_START,
    payload: {
        values,
        onSuccess,
        onError,
    },
})

export const createMarkedPigeonSuccess = (
    createdMarkedPigeon: MarkedPigeonInterface
) => ({
    type: CREATE_MARKED_PIGEON_SUCCESS,
    payload: createdMarkedPigeon,
})

export const createMarkedPigeonFailed = () => ({
    type: CREATE_MARKED_PIGEON_FAILED,
})

export const editMarkedPigeonStart = (
    markedId: number,
    values: MarkedPigeonInterface,
    onSuccess: () => void,
    onError: (message: string | null) => void
) => ({
    type: EDIT_MARKED_PIGEON_START,
    payload: {
        markedId,
        values,
        onSuccess,
        onError,
    },
})

export const editMarkedPigeonSuccess = (
    editedMarkedPigeon: MarkedPigeonInterface
) => ({
    type: EDIT_MARKED_PIGEON_SUCCESS,
    payload: editedMarkedPigeon,
})

export const editMarkedPigeonFailed = () => ({
    type: EDIT_MARKED_PIGEON_FAILED,
})

export const fetchMarkedPigeonsStart = (
    raceId: number,
    onLoad: (markedPigeons: MarkedPigeonInterface[]) => void
) => ({
    type: FETCH_MARKED_PIGEON_START,
    payload: {
        raceId,
        onLoad,
    },
})

export const fetchMarkedPigeonsSuccess = (
    markedPigeons: MarkedPigeonInterface[]
) => ({
    type: FETCH_MARKED_PIGEON_SUCCESS,
    payload: markedPigeons,
})

export const fetchMarkedPigeonsFailed = () => ({
    type: FETCH_MARKED_PIGEON_FAILED,
})

export const deleteMarkedPigeonStart = (
    markedId: number,
    onSuccess: () => void,
    onError: (message: string | null) => void
) => ({
    type: DELETE_MARKED_PIGEON_START,
    payload: {
        markedId,
        onSuccess,
        onError,
    },
})

export const deleteMarkedPigeonSuccess = () => ({
    type: DELETE_MARKED_PIGEON_SUCCESS,
})

export const deleteMarkedPigeonFailed = () => ({
    type: DELETE_MARKED_PIGEON_FAILED,
})

export const setRaceId = (raceId: number) => ({
    type: SET_RACE_ID,
    payload: { raceId },
})

export const setChampionshipId = (championships: any[]) => ({
    type: SET_CHAMPIONSHIP_ID,
    payload: { championships },
})

export const fetchRaceDetailsStart = (
    onLoad: (race: Racing) => void,
    raceId
) => ({
    type: FETCH_RACE_DETAILS_START,
    payload: {
        raceId,
        onLoad,
    },
})

export const fetchRaceDetailsSuccess = (race: Racing) => ({
    type: FETCH_RACE_DETAILS_SUCCESS,
    payload: race,
})

export const fetchRaceDetailsFailed = () => ({
    type: FETCH_RACE_DETAILS_FAILED,
})

export const fetchMarkedPigeonsGroupedStart = (
    raceId: number,
    onLoad: (markedPigeons: MarkedPigeonInterface[]) => void,
    filter: string,
    groupTeam?: boolean,
    championshipId?: number
) => ({
    type: FETCH_MARKED_PIGEON_GROUPED_START,
    payload: {
        raceId,
        onLoad,
        filter,
        groupTeam,
        championshipId,
    },
})

export const fetchMarkedPigeonsGroupedSuccess = (
    markedPigeons: MarkedPigeonInterface[]
) => ({
    type: FETCH_MARKED_PIGEON_GROUPED_SUCCESS,
    payload: markedPigeons,
})

export const fetchMarkedPigeonsGroupedFailed = () => ({
    type: FETCH_MARKED_PIGEON_GROUPED_FAILED,
})

export const updateTimesAndPointsStart = (
    raceId: number,
    onSuccess: () => void
) => ({
    type: UPDATE_TIMES_AND_POINTS_START,
    payload: {
        raceId,
        onSuccess,
    },
})

export const updateTimesAndPointsSuccess = () => ({
    type: UPDATE_TIMES_AND_POINTS_SUCCESS,
})

export const updateTimesAndPointsFailed = () => ({
    type: UPDATE_TIMES_AND_POINTS_FAILED,
})
