import {
    FETCH_CLUBS_FAILED,
    FETCH_CLUBS_START,
    FETCH_CLUBS_SUCCESS,
} from '../actions/clubs'

interface InitialStateType {
    loadingClubs: boolean
}

const initialState: InitialStateType = {
    loadingClubs: false,
}

const clubsReducer = function (
    // eslint-disable-next-line default-param-last
    state: InitialStateType = initialState,
    action: any
) {
    switch (action.type) {
        case FETCH_CLUBS_START:
            return {
                ...state,
                loadingClubs: true,
            }
        case FETCH_CLUBS_SUCCESS:
            return {
                ...state,
                loadingClubs: false,
            }
        case FETCH_CLUBS_FAILED:
            return {
                ...state,
                loadingClubs: false,
            }
        default:
            return state
    }
}

export default clubsReducer
