import React, { useEffect, useLayoutEffect, useState } from 'react'

import { Provider } from 'react-redux'
import { HashRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { store, persistor } from './store'
import Login from './containers/Login'
import Dashboard from './containers/Dashboard'
import Users from './containers/Users'
import Clubs from './containers/Clubs'
import MyClub from './containers/MyClub'
import MyPigeonHouse from './containers/MyPigeonHouse'
import PigeonHouse from './containers/PigeonHouse'

import Navbar from './components/Navbar'

import './App.scss'
import MyProfile from './containers/MyProfile'
import Championships from './containers/Championships'
import Racing from './containers/Racing'
import MarkedPigeons from './containers/MarkedPigeons'
import { triggerAxiosInterceptors } from './utils/triggerAxiosInterceptors'
// import Home from './containers/Home'
import Positions from './containers/Positions'

interface PrivateRouteProps {
    children: React.ReactElement
}

function PrivateRoute({ children }: PrivateRouteProps) {
    const [loading, setLoading] = useState(true)
    const [loggedIn, setLoggedIn] = useState(false)

    const handleLogOut = async () => {
        setLoading(true)
        setLoggedIn(false)
        await localStorage.removeItem('tokenId')
        setLoading(false)
    }

    useLayoutEffect(() => {
        setTimeout(async () => {
            const logged = !!localStorage.getItem('tokenId')
            setLoggedIn(logged)
            setLoading(false)
        })
        triggerAxiosInterceptors(handleLogOut)
    }, [])

    useEffect(() => {
        if (!loading) {
            triggerAxiosInterceptors(handleLogOut)
        }
    }, [handleLogOut, loading, loggedIn])

    if (!loading && !loggedIn) {
        return <Navigate to="/login" />
    }

    return (
        <>
            <Navbar />
            {children}
        </>
    )
}

function AppWrapper() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <Routes>
                        {/* <Route path="/*" element={<Home />} /> */}
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="/dashboard"
                            element={
                                <PrivateRoute>
                                    <Dashboard />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/users"
                            element={
                                <PrivateRoute>
                                    <Users />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/clubs"
                            element={
                                <PrivateRoute>
                                    <Clubs />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/myclub"
                            element={
                                <PrivateRoute>
                                    <MyClub />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/myprofile"
                            element={
                                <PrivateRoute>
                                    <MyProfile />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/mypigeonhouse"
                            element={
                                <PrivateRoute>
                                    <MyPigeonHouse />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/pigeonhouse"
                            element={
                                <PrivateRoute>
                                    <PigeonHouse />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/championships"
                            element={
                                <PrivateRoute>
                                    <Championships />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/racing"
                            element={
                                <PrivateRoute>
                                    <Racing />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/markedPigeons"
                            element={
                                <PrivateRoute>
                                    <MarkedPigeons />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/positions"
                            element={
                                <PrivateRoute>
                                    <Positions />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/*"
                            element={
                                localStorage.getItem('tokenId') !== undefined &&
                                localStorage.getItem('tokenId') !== null ? (
                                    <Navigate replace to="/dashboard" />
                                ) : (
                                    <Navigate replace to="/login" />
                                )
                            }
                        />
                    </Routes>
                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        theme="colored"
                    />
                </Router>
            </PersistGate>
        </Provider>
    )
}

export default AppWrapper
