import React from 'react'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'

import Container from '../../components/Container'

const Image = styled.img`
    width: 100%;
    max-width: 300px;
    object-fit: contain;

    height: auto;
`

const ImageLogo = styled.img`
    padding: 45px;

    width: 100%;
    max-width: 450px;
    object-fit: contain;

    height: auto;

    justify-self: center;
    align-self: flex-start;
`

const Title = styled.h2`
    font-weight: 700;
    font-size: 4.5em;
    color: #008cba;
    text-shadow: -2px 2px 5px #00688b70;
`

const images = [
    'assets/6.jpeg',
    'assets/5.jpeg',
    'assets/3.jpeg',
    'assets/2.jpeg',
    'assets/4.jpeg',
]
function Dashboard() {
    return (
        <div className="main-content">
            <Container>
                <Row className="text-center mt-4">
                    <Col>
                        <Title>Columba</Title>
                    </Col>
                </Row>
                <Row
                    style={{ maxWidth: '1200px' }}
                    className="mx-auto mt-2"
                    xs={1}
                    md={2}
                >
                    <Col className="mt-3 p-2 d-flex justify-content-center">
                        <ImageLogo width="100%" src="assets/1.jpeg" alt="1" />
                    </Col>
                    <Col>
                        <Row xs={1} md={2} lg={2} className="p-5">
                            {images.map((image, index) => (
                                <Col key={image.length} className="p-3">
                                    <Image
                                        width="100%"
                                        src={image}
                                        alt={index}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Dashboard
