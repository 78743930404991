import React, { useCallback, useState } from 'react'
import { Button, Spinner, Row, Col, Input } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FaTrash, FaPencilAlt, FaRegEye, FaRedoAlt } from 'react-icons/fa'
import { toast } from 'react-toastify'

import { IRootState } from '../../store/reducers'
import { IPigeonHouse, PigeonHouseStates } from '../../models/pigeonHouse'
import {
    createPigeonHouseStart,
    deletePigeonHouseStart,
    editPigeonHouseStart,
    fetchPigeonHousesStart,
} from '../../store/actions/pigeonHouses'
import FormPigeonHouses, {
    PigeonHousesFormInterface,
} from '../../components/FormPigeonHouses'
import { SelectInterface } from '../../models/select'
import { fetchLocationsStart } from '../../store/actions/locations'
import { fetchClubsSelectStart } from '../../store/actions/clubs'
import ModalConfirm from '../../components/ModalConfirm'
import useSearch from '../../hooks/useSearch'

import CustomTable from '../../components/CustomTable'
import CustomModal from '../../components/CustomModal'
import Container from '../../components/Container'

import { User } from '../../models/user'
import { getPermissions } from '../../utils/permissions'

import {
    FormActions,
    crudMessagesSuccess,
    crudMessagesError,
} from '../../utils/crudHelper'

import { TableButton } from '../../styles/buttons/tableButtons'

const initialValues: PigeonHousesFormInterface = {
    name: '',
    lat: 0,
    lng: 0,
    address: '',
    isPublic: false,
    state: 1,
    clubId: null,
    locationId: null,
}

function PigeonHouse() {
    const dispatch = useDispatch()
    const [action, setAction] = useState(FormActions.CREATE)
    const [pigeonHouses, setPigeonHouses] = useState<IPigeonHouse[]>([])
    const [locations, setLocations] = useState<SelectInterface[]>([])
    const [clubs, setClubs] = useState<SelectInterface[]>([])

    const [isCreateUserFormOpen, setIsCreateUserFormOpen] = useState(false)
    const [isViewUserFormOpen, setIsViewUserFormOpen] = useState(false)
    const [modalEditVisible, setModalEditVisible] = useState(false)
    const [modalConfirmDeleteVisible, setModalConfirmDeleteVisible] =
        useState(false)
    const [pigeonHouseEdit, setPigeonHouseEdit] = useState({} as IPigeonHouse)
    const [pigeonHouseDelete, setPigeonHouseDelete] = useState(
        {} as IPigeonHouse
    )
    const toggleModalEdit = () => setModalEditVisible(!modalEditVisible)
    const toggleModalView = () => setIsViewUserFormOpen(!isViewUserFormOpen)
    const toggleModalCreate = () => {
        setAction(FormActions.CREATE)
        setIsCreateUserFormOpen(!isCreateUserFormOpen)
    }
    const fetchPigeonHouses = () =>
        dispatch(
            fetchPigeonHousesStart((pigeonHousesFetched) =>
                setPigeonHouses(pigeonHousesFetched)
            )
        )
    const fetchLocations = () => {
        dispatch(
            fetchLocationsStart((locationsFetched) =>
                setLocations(locationsFetched)
            )
        )
    }

    const fetchClubsSelect = () => {
        dispatch(
            fetchClubsSelectStart((clubsFetched) => setClubs(clubsFetched))
        )
    }
    const isLoadingPigeonHouses = useSelector(
        (state: IRootState) => state.pigeonHousesReducer.loadingPigeonHouses
    )

    const onError = (errorMessage: string | null) => {
        const message = errorMessage ?? crudMessagesError[action]
        toast.error(message)
    }

    const onPigeonHouseDeleted = () => {
        setModalConfirmDeleteVisible(false)
        fetchPigeonHouses()
        toast.success(crudMessagesSuccess[action])
    }
    const onDeletePigeonHouse = (pigeonHouseId: string) => {
        dispatch(
            deletePigeonHouseStart(pigeonHouseId, onPigeonHouseDeleted, () =>
                onError('No se puede eliminar el palomar')
            )
        )
    }
    const onPigeonHouseCreated = () => {
        setIsCreateUserFormOpen(false)
        fetchPigeonHouses()
        toast.success(crudMessagesSuccess[action])
    }
    const onPigeonHouseEdited = () => {
        setModalEditVisible(false)
        fetchPigeonHouses()
        toast.success(crudMessagesSuccess[action])
    }
    const createPigeonHouse = (values: IPigeonHouse) => {
        dispatch(createPigeonHouseStart(values, onPigeonHouseCreated, onError))
    }
    const editPigeonHouse = (values: IPigeonHouse) => {
        dispatch(
            editPigeonHouseStart(
                values,
                pigeonHouseEdit.id,
                onPigeonHouseEdited,
                onError
            )
        )
    }
    const openConfirmDeleteModal = (clubDeleteRow: IPigeonHouse) => {
        setPigeonHouseDelete(clubDeleteRow)
        setModalConfirmDeleteVisible(true)
        setAction(FormActions.DELETE)
    }
    const toggleConfirmDeleteModal = () =>
        setModalConfirmDeleteVisible(!modalConfirmDeleteVisible)
    const userLogged: User = useSelector(
        (state: IRootState) => state.authReducer.user
    )
    const { pigeonHouses: pigeonHousesPermissions } = getPermissions(
        userLogged?.profile
    )
    const {
        create: canCreatePigeonHouse,
        edit: canEditPigeonHouse,
        delete: canDeletePigeonHouse,
        details: canSeeDetailsPigeonHouse,
    } = pigeonHousesPermissions
    const ActionButton = useCallback(
        ({ row }) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                    color="success"
                    onClick={() => {
                        setIsViewUserFormOpen(true)
                        setPigeonHouseEdit(row)
                        setAction(FormActions.DETAIL)
                    }}
                    className="p-1 pt-0 me-2"
                    disabled={!canSeeDetailsPigeonHouse}
                >
                    <FaRegEye />
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        setModalEditVisible(true)
                        setPigeonHouseEdit(row)
                        setAction(FormActions.EDIT)
                    }}
                    className="p-1 pt-0 me-2"
                    disabled={!canEditPigeonHouse}
                >
                    <FaPencilAlt />
                </Button>
                <Button
                    color="danger"
                    onClick={() => openConfirmDeleteModal(row)}
                    className="p-1 pt-0"
                    disabled={!canDeletePigeonHouse}
                >
                    <FaTrash />
                </Button>
            </div>
        ),
        []
    )

    const columns = [
        {
            name: 'Name',
            selector: (row: IPigeonHouse) => row.name,
            sortable: true,
        },
        {
            name: 'Usuario',
            selector: (row: IPigeonHouse) =>
                row.user ? `${row.user.firstName} ${row.user.lastName}` : '',
            sortable: true,
        },
        {
            name: 'Dirección',
            selector: (row: IPigeonHouse) => row.address,
            sortable: true,
        },
        {
            name: 'Localidad',
            selector: (row: IPigeonHouse) =>
                row.location ? row.location.name : '',
            sortable: true,
        },
        {
            name: 'Club',
            selector: (row: IPigeonHouse) => (row.club ? row.club.name : ''),
            sortable: true,
        },
        {
            name: 'Estado',
            selector: (row: IPigeonHouse) =>
                PigeonHouseStates[Number(row.state) - 1],
            sortable: true,
        },
        {
            name: 'Es publico',
            selector: (row: IPigeonHouse) => (row.isPublic ? 'Si' : 'No'),
            sortable: true,
        },
        {
            name: 'Acciones',
            cell: (row: IPigeonHouse) =>
                ActionButton({
                    row,
                }),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            minWidth: '156px',
        },
    ]

    React.useEffect(() => {
        fetchPigeonHouses()
        fetchLocations()
        fetchClubsSelect()
    }, [])
    const [filterText, setFilterText] = React.useState('')
    const { filteredItems } = useSearch(pigeonHouses, filterText)

    const SubHeaderComponentMemo = React.useMemo(() => {
        return (
            <div className="d-flex">
                <div className="d-flex align-items-center me-3">
                    Buscar
                    <Input
                        className="ms-2"
                        onChange={(e) => setFilterText(e.target.value)}
                        value={filterText}
                    />
                </div>
                <div>
                    <TableButton type="button" onClick={fetchPigeonHouses}>
                        <FaRedoAlt />
                    </TableButton>
                </div>
            </div>
        )
    }, [filterText])

    return (
        <Container>
            <Row>
                <Col className="d-flex justify-content-between ">
                    <h3>Palomares</h3>
                    <Button
                        color="primary"
                        onClick={() =>
                            setIsCreateUserFormOpen(!isCreateUserFormOpen)
                        }
                        disabled={!canCreatePigeonHouse}
                        style={{
                            marginBottom: '1rem',
                        }}
                    >
                        Crear Palomar
                    </Button>

                    <CustomModal
                        title="Crear Palomar"
                        isOpen={isCreateUserFormOpen}
                        toggle={toggleModalCreate}
                    >
                        <FormPigeonHouses
                            onSubmit={createPigeonHouse}
                            initialValues={initialValues}
                            locations={locations}
                            isEdit={false}
                            clubs={clubs}
                        />
                    </CustomModal>
                    <CustomModal
                        isOpen={isViewUserFormOpen}
                        toggle={toggleModalView}
                        title="Ver Palomar"
                    >
                        <FormPigeonHouses
                            initialValues={pigeonHouseEdit}
                            locations={locations}
                            clubs={clubs}
                            isEdit={false}
                            disabled
                        />
                    </CustomModal>
                    <ModalConfirm
                        isOpen={modalConfirmDeleteVisible}
                        toggle={toggleConfirmDeleteModal}
                        onConfirm={() =>
                            onDeletePigeonHouse(pigeonHouseDelete?.id)
                        }
                        onCancel={() => setModalConfirmDeleteVisible(false)}
                        titleHeader={`Eliminar Palomar ${pigeonHouseDelete?.name}?`}
                        title={`¿Está seguro que desea eliminar el palomar ${pigeonHouseDelete?.name}?`}
                        confirmButtonText="Eliminar"
                    >
                        <FormPigeonHouses
                            onSubmit={createPigeonHouse}
                            initialValues={pigeonHouseDelete}
                            locations={locations}
                            clubs={clubs}
                            isEdit={false}
                            disabled
                        />
                    </ModalConfirm>
                    <CustomModal
                        isOpen={modalEditVisible}
                        toggle={toggleModalEdit}
                        title="Editar Palomar"
                    >
                        <FormPigeonHouses
                            onSubmit={editPigeonHouse}
                            locations={locations}
                            clubs={clubs}
                            isEdit
                            initialValues={pigeonHouseEdit}
                        />
                    </CustomModal>
                </Col>
            </Row>
            <Row>
                <Col>
                    <CustomTable
                        // title="Clubes"
                        noHeader
                        striped
                        pagination
                        data={filteredItems}
                        subHeader
                        subHeaderComponent={SubHeaderComponentMemo}
                        progressPending={isLoadingPigeonHouses}
                        progressComponent={
                            <Spinner color="primary" type="grow">
                                Cargando...
                            </Spinner>
                        }
                        columns={columns}
                        dense
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default PigeonHouse
