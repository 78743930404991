import axios from 'axios'
import env from '../env/config'

const { API_URL } = env

export const login = (username: string, password: string) => {
    return axios.post(`${API_URL}/auth/login`, {
        username,
        password,
    })
}
