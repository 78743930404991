import React from 'react'

import styled from 'styled-components'
import { Col } from 'reactstrap'

export const ColHeader = styled(Col)`
    label {
        font-size: 1.2rem;
        font-weight: 700;

        span {
            font-weight: 500;
            margin-left: 5px;
        }
    }
`
