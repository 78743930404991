import { SelectInterface } from '../../models/select'

export const FETCH_LOCATIONS_START = 'FETCH_LOCATIONS_START'
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS'
export const FETCH_LOCATIONS_FAILED = 'FETCH_LOCATIONS_FAILED'

export const fetchLocationsStart = (
    onLoad: (locations: SelectInterface[]) => void
) => ({
    type: FETCH_LOCATIONS_START,
    payload: {
        onLoad,
    },
})

export const fetchLocationsSuccess = (locations: SelectInterface[]) => ({
    type: FETCH_LOCATIONS_SUCCESS,
    payload: locations,
})

export const fetchLocationsFailed = () => ({
    type: FETCH_LOCATIONS_FAILED,
})
