import { createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import allReducers from './reducers'
import { rootSaga } from './sagas'

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
    }
}

const composeEnhancer =
    (process.env.NODE_ENV !== 'production' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, allReducers)

const sagaMiddleware = createSagaMiddleware()
export const store = createStore(
    persistedReducer,
    {},
    composeEnhancer(applyMiddleware(sagaMiddleware))
)
sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)
