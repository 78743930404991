import { Form, Formik } from 'formik'
import { Button, Col, Label, Row } from 'reactstrap'
import React from 'react'
import { findSelected } from '../../utils/selectHelper'
import { ContFormNonFlex } from '../../styles/conteiners/conteiners'
import { ChampionshipsStates } from '../../models/championship'
import Select from '../Select'

export interface ChangeStateChampionshipFormInterface {
    state: number
}

export interface ChangeStateChampionshipFormErrorsInterface {
    state: string
}

interface FormChangeStateChampionshipProps {
    editChampionship: (values: ChangeStateChampionshipFormInterface) => void
    initialValues: ChangeStateChampionshipFormInterface
}

function FormChangeStateChampionship({
    editChampionship,
    initialValues,
}: FormChangeStateChampionshipProps) {
    return (
        <Formik
            initialValues={initialValues}
            validate={(values) => {
                const errors: Partial<ChangeStateChampionshipFormErrorsInterface> =
                    {}
                if (!values.state) {
                    errors.state = 'Required'
                }
                return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
                editChampionship(values)
                setSubmitting(false)
            }}
        >
            {({
                values,
                errors,
                touched,
                handleSubmit,
                isSubmitting,
                setFieldValue,
            }) => (
                <Form className="form" onSubmit={handleSubmit}>
                    <ContFormNonFlex>
                        <div>
                            <Label for="state">Estado</Label>
                            <Select
                                fieldName="state"
                                touched={touched}
                                errors={errors}
                                options={ChampionshipsStates}
                                onChange={({ value }) =>
                                    setFieldValue('state', Number(value))
                                }
                                value={findSelected(
                                    ChampionshipsStates,
                                    values.state
                                )}
                            />
                        </div>
                    </ContFormNonFlex>
                    <Row>
                        <Col className="mt-2 d-flex justify-content-end">
                            <Button
                                type="submit"
                                color="primary"
                                disabled={isSubmitting}
                            >
                                Guardar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    )
}

export default FormChangeStateChampionship
