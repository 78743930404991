/* eslint-disable react/require-default-props */
import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

interface ICustomModal {
    title: string
    isOpen: boolean
    toggle: () => void
    children: any
    className?: string
}

function CustomModal(props: ICustomModal) {
    const { title, isOpen, toggle, className, children } = props
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            className={className || 'modal-lg'}
        >
            <ModalHeader isOpen={isOpen} toggle={toggle}>
                {title}
            </ModalHeader>
            <ModalBody>{children}</ModalBody>
        </Modal>
    )
}

export default CustomModal
