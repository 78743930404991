export const sortNumbers = (field: string) => {
    return (rowA, rowB) => {
        const a = rowA[field] ?? 0
        const b = rowB[field] ?? 0

        if (a > b) return 1

        if (b > a) return -1

        return 0
    }
}
