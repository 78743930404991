import {
    CREATE_CHAMPIONSHIP_FAILED,
    CREATE_CHAMPIONSHIP_START,
    CREATE_CHAMPIONSHIP_SUCCESS,
} from '../actions/championships'

interface InitialStateType {
    loadingChampionships: boolean
}

const initialState: InitialStateType = {
    loadingChampionships: false,
}

const championshipsReducer = function (
    // eslint-disable-next-line default-param-last
    state: InitialStateType = initialState,
    action: any
) {
    switch (action.type) {
        case CREATE_CHAMPIONSHIP_START:
            return {
                ...state,
                loadingChampionships: true,
            }
        case CREATE_CHAMPIONSHIP_SUCCESS:
            return {
                ...state,
                loadingChampionships: false,
            }
        case CREATE_CHAMPIONSHIP_FAILED:
            return {
                ...state,
                loadingChampionships: false,
            }
        default:
            return state
    }
}

export default championshipsReducer
