import React, { useState } from 'react'
import {
    Navbar as NavbarComponent,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavbarBrand,
    NavbarToggler,
    UncontrolledDropdown,
    NavLink,
    NavItem,
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'

import styled from 'styled-components'

import { GiDove } from 'react-icons/gi'

import { logout } from '../../store/actions/auth'

function Navbar(props: any) {
    const [isOpenNav, setIsOpenNav] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const logoutSuccess = () => navigate('/login')
    const onLogout = () => dispatch(logout(logoutSuccess))
    const goToEditProfile = () => navigate('/myprofile')

    const primary = '#008CBA'
    const txtPrimary = 'white'

    const ContNav = styled.div`
        .navbar {
            background-color: ${primary};
            color: ${txtPrimary};
        }

        .navbar-brand {
            color: ${txtPrimary};
            font-weight: 600;
        }

        .dropdown {
            a {
                color: ${txtPrimary} !important;
            }
        }
    `

    const navLinkStyle = {
        color: 'inherit',
        textDecoration: 'none',
    }

    const user = useSelector((state: any) => state.authReducer.user)

    return (
        <ContNav>
            <NavbarComponent expand="md" light>
                <NavbarBrand href="/">
                    {/* DovesRace */}
                    <GiDove size="30px" color="white" />
                </NavbarBrand>

                <NavbarToggler onClick={() => setIsOpenNav(!isOpenNav)} />
                <Collapse navbar isOpen={isOpenNav}>
                    <NavItem style={{ listStyle: 'none' }}>
                        <NavLink style={{ color: 'inherit' }}>
                            <Link style={navLinkStyle} to="/users">
                                Usuarios
                            </Link>
                        </NavLink>
                    </NavItem>
                    <NavItem style={{ listStyle: 'none' }}>
                        <NavLink style={{ color: 'inherit' }}>
                            <Link style={navLinkStyle} to="/clubs">
                                Clubes
                            </Link>
                        </NavLink>
                    </NavItem>
                    <NavItem style={{ listStyle: 'none' }}>
                        <NavLink style={{ color: 'inherit' }}>
                            <Link style={navLinkStyle} to="/myclub">
                                Mi Club
                            </Link>
                        </NavLink>
                    </NavItem>
                    <NavItem style={{ listStyle: 'none' }}>
                        <NavLink style={{ color: 'inherit' }}>
                            <Link style={navLinkStyle} to="/pigeonhouse">
                                Palomares
                            </Link>
                        </NavLink>
                    </NavItem>
                    <NavItem style={{ listStyle: 'none' }}>
                        <NavLink style={{ color: 'inherit' }}>
                            <Link style={navLinkStyle} to="/mypigeonhouse">
                                Mi Palomar
                            </Link>
                        </NavLink>
                    </NavItem>
                    <NavItem style={{ listStyle: 'none' }}>
                        <NavLink style={{ color: 'inherit' }}>
                            <Link style={navLinkStyle} to="/championships">
                                Torneos
                            </Link>
                        </NavLink>
                    </NavItem>
                    <NavItem style={{ listStyle: 'none' }}>
                        <NavLink style={{ color: 'inherit' }}>
                            <Link style={navLinkStyle} to="/racing">
                                Carreras
                            </Link>
                        </NavLink>
                    </NavItem>

                    <Nav className="ms-auto me-2" navbar>
                        <UncontrolledDropdown inNavbar nav>
                            <DropdownToggle caret nav>
                                {user
                                    ? `${user.lastName ?? ''} ${
                                          user.firstName ?? ''
                                      }`
                                    : 'Cuenta'}
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem onClick={goToEditProfile}>
                                    Editar perfil
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={onLogout}>
                                    Cerrar sesión
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Collapse>
            </NavbarComponent>
        </ContNav>
    )
}

export default Navbar
