import { combineReducers, Reducer } from 'redux'
import clubsReducer from './clubs.reducer'
import usersReducer from './users.reducer'
import authReducer from './auth.reducer'
import pigeonHousesReducer from './pigeonHouses.reducer'
import profileReducer from './profile.reducer'
import championshipsReducer from './championships.reducer'
import racingsReducer from './racings.reducer'

export type GetStateFromReducers<T> = T extends (...args: any[]) => infer Ret
    ? Ret
    : T extends Record<any, any>
    ? {
          [K in keyof T]: GetStateFromReducers<T[K]>
      }
    : T

const allReducers: Reducer<any> = combineReducers({
    authReducer,
    usersReducer,
    profileReducer,
    clubsReducer,
    championshipsReducer,
    pigeonHousesReducer,
    racingsReducer,
})

export type GlobalState = GetStateFromReducers<typeof allReducers>

export type IRootState = ReturnType<typeof allReducers>

export default allReducers
