import { Button, Input, Col, Row, Spinner } from 'reactstrap'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaTrash, FaPencilAlt, FaRegEye, FaRedoAlt } from 'react-icons/fa'
import { toast } from 'react-toastify'

import {
    createUserStart,
    deleteUserStart,
    editUserStart,
    fetchUsersStart,
} from '../../store/actions/users'
import { IRootState } from '../../store/reducers'
import {
    User,
    TypeProfiles,
    TypeProfilesText,
    TypesClubUser,
    TypesClubUserText,
} from '../../models/user'
import FormUser, { CreateUserFormInterface } from '../../components/FormUser'
import { fetchLocationsStart } from '../../store/actions/locations'
import { SelectInterface } from '../../models/select'
import { fetchClubsSelectStart } from '../../store/actions/clubs'
import { fetchPigeonHousesSelectStart } from '../../store/actions/pigeonHouses'
import ModalConfirm from '../../components/ModalConfirm'
import { findSelectedRaw } from '../../utils/selectHelper'
import useSearch from '../../hooks/useSearch'

import CustomTable from '../../components/CustomTable'
import CustomModal from '../../components/CustomModal'
import Container from '../../components/Container'

import { getPermissions } from '../../utils/permissions'

import {
    FormActions,
    crudMessagesSuccess,
    crudMessagesError,
} from '../../utils/crudHelper'

import { TableButton } from '../../styles/buttons/tableButtons'

const initialValues: CreateUserFormInterface = {
    username: '',
    password: '',
    password2: '',
    email: '',
    firstName: '',
    lastName: '',
    locationId: null,
    clubId: null,
    pigeonHouseId: null,
    profile: null,
    typeUserClub: null,
}

function Users() {
    const dispatch = useDispatch()
    const [action, setAction] = useState(FormActions.CREATE)
    const [users, setUsers] = useState<User[]>([])
    const [locations, setLocations] = useState<SelectInterface[]>([])
    const [clubsSelect, setClubsSelect] = useState<SelectInterface[]>([])
    const [pigeonHousesSelect, setPigeonHousesSelect] = useState<
        SelectInterface[]
    >([])
    const [isCreateUserFormOpen, setIsCreateUserFormOpen] = useState(false)
    const [isViewUserFormOpen, setIsViewUserFormOpen] = useState(false)
    const [modalEditVisible, setModalEditVisible] = useState(false)
    const [userEdit, setUserEdit] = useState({} as User)
    const [userDelete, setUserDelete] = useState({} as User)
    const [modalConfirmDeleteVisible, setModalConfirmDeleteVisible] =
        useState(false)
    const toggleModalEdit = () => setModalEditVisible(!modalEditVisible)
    const toggleModalView = () => setIsViewUserFormOpen(!isViewUserFormOpen)
    const toggleModalCreate = () => {
        setAction(FormActions.CREATE)
        setIsCreateUserFormOpen(!isCreateUserFormOpen)
    }
    const fetchUsers = () =>
        dispatch(fetchUsersStart((usersFetched) => setUsers(usersFetched)))
    const fetchLocations = () => {
        dispatch(
            fetchLocationsStart((locationsFetched) =>
                setLocations(locationsFetched)
            )
        )
    }
    const fetchClubsSelect = () => {
        dispatch(
            fetchClubsSelectStart((clubsFetched) =>
                setClubsSelect(clubsFetched)
            )
        )
    }
    const fetchPigeonHousesSelect = () => {
        dispatch(
            fetchPigeonHousesSelectStart((pigeonHousesFetched) =>
                setPigeonHousesSelect(pigeonHousesFetched)
            )
        )
    }
    const isLoadingUsers = useSelector(
        (state: IRootState) => state.usersReducer.loadingUsers
    )

    const onError = (errorMessage: string | null) => {
        const message = errorMessage ?? crudMessagesError[action]
        toast.error(message)
    }

    const openConfirmDeleteModal = (userDeleteRow: User) => {
        setUserDelete(userDeleteRow)
        setModalConfirmDeleteVisible(true)
    }
    const toggleConfirmDeleteModal = () =>
        setModalConfirmDeleteVisible(!modalConfirmDeleteVisible)
    const onClubDeleted = () => {
        setModalConfirmDeleteVisible(false)
        fetchUsers()
        toast.success(crudMessagesSuccess[action])
    }
    const onDeleteUser = (userId) => {
        dispatch(deleteUserStart(userId, onClubDeleted, onError))
    }

    const onUserCreated = () => {
        setIsCreateUserFormOpen(false)
        fetchUsers()
        toast.success(crudMessagesSuccess[action])
    }
    const onUserEdited = () => {
        setModalEditVisible(false)
        fetchUsers()
        toast.success(crudMessagesSuccess[action])
    }
    const createUser = (values) => {
        dispatch(createUserStart(values, onUserCreated, onError))
    }
    const editUser = (values) => {
        dispatch(editUserStart(values, userEdit.id, onUserEdited, onError))
    }
    const userLogged: User = useSelector(
        (state: IRootState) => state.authReducer.user
    )
    const { users: usersPermission } = getPermissions(userLogged?.profile)
    const {
        create: canCreateUser,
        edit: canEditUser,
        delete: canDeleteUser,
        details: canSeeDetailsUser,
    } = usersPermission
    const ActionButton = useCallback(
        ({ row }) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                    color="success"
                    onClick={() => {
                        setIsViewUserFormOpen(true)
                        setUserEdit(row)
                        setAction(FormActions.DETAIL)
                    }}
                    className="p-1 pt-0 me-2"
                    disabled={!canSeeDetailsUser}
                >
                    <FaRegEye />
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        setModalEditVisible(true)
                        setUserEdit(row)
                        setAction(FormActions.EDIT)
                    }}
                    className="p-1 pt-0 me-2"
                    disabled={!canEditUser}
                >
                    <FaPencilAlt />
                </Button>
                <Button
                    color="danger"
                    onClick={() => {
                        openConfirmDeleteModal(row)
                        setAction(FormActions.DELETE)
                    }}
                    className="p-1 pt-0"
                    disabled={!canDeleteUser}
                >
                    <FaTrash />
                </Button>
            </div>
        ),
        []
    )

    const columns = [
        {
            name: 'Email',
            selector: (row) => row.email,
        },
        {
            name: 'Nombre de usuario',
            selector: (row) => row.username,
        },
        {
            name: 'Nombre y Apellido',
            selector: (row) => `${row.lastName ?? ''} ${row.firstName ?? ''}`,
        },
        {
            name: 'Localidad',
            selector: (row) => row.location?.name ?? '',
        },
        {
            name: 'Club',
            selector: (row) => row.club?.name ?? '',
        },
        // {
        //     name: 'Tipo de usuario club',
        //     selector: (row) =>
        //         row.typeUserClub
        //             ? TypesClubUserText[TypesClubUser[row.typeUserClub]]
        //             : '',
        // },
        {
            name: 'Palomar',
            selector: (row) =>
                findSelectedRaw(pigeonHousesSelect, row.pigeonHouseId)?.name ??
                '',
        },
        {
            name: 'Perfil',
            selector: (row) =>
                row.profile ? TypeProfilesText[TypeProfiles[row.profile]] : '',
        },
        {
            name: 'Acciones',
            cell: (row) =>
                ActionButton({
                    row,
                }),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            minWidth: '156px',
        },
    ]
    React.useEffect(() => {
        fetchUsers()
        fetchLocations()
        fetchClubsSelect()
        fetchPigeonHousesSelect()
    }, [])

    const [filterText, setFilterText] = React.useState('')
    const { filteredItems } = useSearch(users, filterText)

    const SubHeaderComponentMemo = React.useMemo(() => {
        return (
            <div className="d-flex">
                <div className="d-flex align-items-center me-3">
                    Buscar
                    <Input
                        className="ms-2"
                        onChange={(e) => setFilterText(e.target.value)}
                        value={filterText}
                    />
                </div>
                <div>
                    <TableButton type="button" onClick={fetchUsers}>
                        <FaRedoAlt />
                    </TableButton>
                </div>
            </div>
        )
    }, [filterText])

    return (
        <Container>
            <Row>
                <Col className="d-flex justify-content-between ">
                    <h3>Usuarios</h3>
                    <Button
                        color="primary"
                        onClick={() => toggleModalCreate()}
                        style={{
                            marginBottom: '1rem',
                        }}
                        disabled={!canCreateUser}
                    >
                        Crear Usuario
                    </Button>
                    <CustomModal
                        title="Crear Usuario"
                        isOpen={isCreateUserFormOpen}
                        toggle={toggleModalCreate}
                    >
                        <FormUser
                            createUser={createUser}
                            initialValues={initialValues}
                            locations={locations}
                            pigeonHousesSelect={pigeonHousesSelect}
                            clubsSelect={clubsSelect}
                            editMode={false}
                        />
                    </CustomModal>
                    <CustomModal
                        title="Ver Usuario"
                        isOpen={isViewUserFormOpen}
                        toggle={toggleModalView}
                    >
                        <FormUser
                            createUser={editUser}
                            locations={locations}
                            clubsSelect={clubsSelect}
                            pigeonHousesSelect={pigeonHousesSelect}
                            initialValues={userEdit}
                            editMode
                            disabled
                        />
                    </CustomModal>
                    <ModalConfirm
                        isOpen={modalConfirmDeleteVisible}
                        toggle={toggleConfirmDeleteModal}
                        onConfirm={() => onDeleteUser(userDelete?.id)}
                        onCancel={() => setModalConfirmDeleteVisible(false)}
                        titleHeader="Eliminar Usuario"
                        title={`¿Está seguro que desea eliminar el usuario ${userDelete.firstName} - ${userDelete.lastName}?`}
                        confirmButtonText="Eliminar"
                    >
                        <FormUser
                            createUser={editUser}
                            locations={locations}
                            clubsSelect={clubsSelect}
                            pigeonHousesSelect={pigeonHousesSelect}
                            initialValues={userDelete}
                            editMode
                            disabled
                        />
                    </ModalConfirm>
                    <CustomModal
                        isOpen={modalEditVisible}
                        toggle={toggleModalEdit}
                        title="Editar Usuario"
                    >
                        <FormUser
                            createUser={editUser}
                            locations={locations}
                            clubsSelect={clubsSelect}
                            pigeonHousesSelect={pigeonHousesSelect}
                            initialValues={userEdit}
                            editMode
                        />
                    </CustomModal>
                </Col>
            </Row>
            <Row>
                <Col>
                    <CustomTable
                        // title="Usuarios"
                        striped
                        pagination
                        data={filteredItems}
                        progressPending={isLoadingUsers}
                        subHeader
                        subHeaderComponent={SubHeaderComponentMemo}
                        progressComponent={
                            <Spinner color="primary" type="grow">
                                Cargando...
                            </Spinner>
                        }
                        columns={columns}
                        dense
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default Users
