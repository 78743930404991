function padTo2Digits(num) {
    return num.toString().padStart(2, '0')
}

export const formatDate = (date: Date) => {
    return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
    ].join('-')
}

export const formatHoursToTime = (hours: number) => {
    const hour = Math.floor(hours)
    const minuts = Math.floor((hours - hour) * 60)
    const seconds = Math.floor(((hours - hour) * 60 - minuts) * 60)

    return [
        padTo2Digits(hour),
        padTo2Digits(minuts),
        padTo2Digits(seconds),
    ].join(':')
}

export const formatHoursToMinutes = (totalSeconds: number) => {
    // Parsea a minutos si ingresa horas
    // const minuts = Math.floor(hours * 60)
    // const seconds = Math.floor((hours * 60 - minuts) * 60)

    // Parsea a minutos si ingresa segundos
    const minuts = Math.floor(totalSeconds / 60)
    const seconds = Math.floor(totalSeconds - minuts * 60)

    return [padTo2Digits(minuts), padTo2Digits(seconds)].join(':')
}
