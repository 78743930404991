import React, { useState } from 'react'
import { FormikErrors, useFormik } from 'formik'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'

import { useSelector } from 'react-redux'
import { SelectInterface } from '../../models/select'

import InputForm from '../InputForm'

import { findSelected, parseSelectOptions } from '../../utils/selectHelper'

import MapWrapper, { MapModal } from '../MapWrapper'
import Select from '../Select'
import { User } from '../../models/user'
import { IRootState } from '../../store/reducers'
import {
    getFormPermissions,
    PermissionFormPigeonHousesInterface,
} from '../../utils/permissions/formPermissions'

import LblInputGPSPoint from '../LblInputGPSPoint'

export enum PigeonHousesState {
    ACTIVE = '1',
    DISABLED = '2',
    DECEASED = '3',
}

const states = [
    {
        value: PigeonHousesState.ACTIVE,
        label: 'Activo',
    },
    {
        value: PigeonHousesState.DISABLED,
        label: 'Deshabilitado',
    },
    {
        value: PigeonHousesState.DECEASED,
        label: 'Fallecido',
    },
]

export interface PigeonHousesFormInterface {
    name: string
    lat: number
    lng: number
    address: string
    isPublic: boolean
    state: number
    clubId: number
    locationId: number
}

interface FormUserProps {
    onSubmit?: (values: PigeonHousesFormInterface) => void
    initialValues: PigeonHousesFormInterface
    locations: SelectInterface[]
    clubs: SelectInterface[]
    disabled?: boolean
    btnSaveName?: string
    isEdit?: boolean
}

function FormPigeonHouses({
    onSubmit,
    initialValues,
    locations,
    clubs,
    disabled = false,
    btnSaveName,
    isEdit = false,
}: FormUserProps) {
    const [markers, setMarkers] = useState([])

    const locationsMapped = parseSelectOptions(locations)
    const clubsMapped = parseSelectOptions(clubs)
    const userLogged: User = useSelector(
        (state: IRootState) => state.authReducer.user
    )
    const {
        pigeonHousesCreate: pigeonHousesCreatePermissions,
        pigeonHousesEdit: pigeonHousesEditPermissions,
    } = getFormPermissions(userLogged?.profile)

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setValues,
    } = useFormik({
        initialValues: {
            ...initialValues,
        },
        onSubmit: (data, { setSubmitting }) => {
            if (onSubmit !== null) onSubmit(data)
            setSubmitting(false)
        },
        validate: (data) => {
            const errorsValidate: FormikErrors<PigeonHousesFormInterface> = {}
            if (!data.name) {
                errorsValidate.name = 'Complete este campo'
            }

            if (!data.clubId) {
                errorsValidate.clubId = 'Complete este campo'
            }

            if (!data.state) {
                errorsValidate.state = 'Complete este campo'
            }

            if (data.lat === 0) {
                errorsValidate.lat = 'Complete este campo'
            }

            if (data.lng === 0) {
                errorsValidate.lng = 'Complete este campo'
            }

            return errorsValidate
        },
    })

    React.useEffect(() => {
        if (initialValues) {
            const clubIdLogged = userLogged?.clubId ?? null

            setValues({
                ...initialValues,
                clubId: initialValues.clubId ?? clubIdLogged,
            })

            if (initialValues.lat && initialValues.lng) {
                setMarkers([
                    {
                        name: initialValues.name,
                        defaultIsOpen: true,
                        point: {
                            lat: initialValues.lat,
                            lng: initialValues.lng,
                        },
                    },
                ])
            }
        }
    }, [initialValues, userLogged])

    const pigeonHousesPermissions: PermissionFormPigeonHousesInterface = isEdit
        ? pigeonHousesEditPermissions
        : pigeonHousesCreatePermissions

    const handleChangeMarkers = (field: string, value: number) => {
        setMarkers([
            {
                ...markers,
                name: values.name,
                point: {
                    lat: field === 'lat' ? value : values.lat,
                    lng: field === 'lng' ? value : values.lng,
                },
            },
        ])
    }

    return (
        <Form className="form" onSubmit={handleSubmit}>
            <Row>
                <Col xs="6">
                    <FormGroup>
                        <Label for="name">Nombre</Label>
                        <InputForm
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            values={values}
                            disabled={disabled}
                            placeholder="Nombre"
                            fieldName="name"
                        />
                    </FormGroup>
                </Col>
                <Col xs="6">
                    <FormGroup>
                        <Label for="address">Dirección</Label>
                        <InputForm
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            values={values}
                            disabled={disabled}
                            placeholder="Dirección"
                            fieldName="address"
                        />
                    </FormGroup>
                </Col>

                <Col xs="6">
                    <FormGroup>
                        <Label for="locationId">Localidad</Label>
                        <Select
                            fieldName="locationId"
                            touched={touched}
                            errors={errors}
                            options={locationsMapped}
                            onChange={({ value }) =>
                                setFieldValue('locationId', value)
                            }
                            disabled={
                                disabled ||
                                (isEdit && !pigeonHousesPermissions.locationId)
                            }
                            value={findSelected(
                                locationsMapped,
                                values.locationId
                            )}
                        />
                    </FormGroup>
                </Col>

                <Col xs="6">
                    <FormGroup>
                        <Label for="clubId">Club</Label>
                        <Select
                            fieldName="clubId"
                            touched={touched}
                            errors={errors}
                            options={clubsMapped}
                            onChange={({ value }) =>
                                setFieldValue('clubId', value)
                            }
                            required
                            disabled={
                                disabled || !pigeonHousesPermissions.clubId
                            }
                            value={findSelected(clubsMapped, values.clubId)}
                            className={
                                errors.clubId && touched.clubId
                                    ? 'is-invalid '
                                    : ''
                            }
                        />
                    </FormGroup>
                </Col>

                <Col xs="6">
                    <FormGroup className="row">
                        <Label
                            for="isPublic"
                            onClick={() =>
                                setFieldValue('isPublic', !values.isPublic)
                            }
                            className="col-12"
                        >
                            Es público
                        </Label>
                        <Col>
                            <Input
                                onChange={() =>
                                    setFieldValue('isPublic', !values.isPublic)
                                }
                                checked={values.isPublic}
                                type="checkbox"
                                disabled={
                                    disabled ||
                                    (isEdit &&
                                        !pigeonHousesPermissions.isPublic)
                                }
                            />
                        </Col>
                    </FormGroup>
                </Col>

                <Col xs="6">
                    <FormGroup>
                        <Label for="state">Estado</Label>
                        <Select
                            fieldName="state"
                            touched={touched}
                            errors={errors}
                            options={states}
                            onChange={({ value }) =>
                                setFieldValue('state', value)
                            }
                            disabled={
                                disabled ||
                                (isEdit && !pigeonHousesPermissions.state)
                            }
                            value={findSelected(states, values.state)}
                        />
                    </FormGroup>
                </Col>

                <Col xs="6">
                    <LblInputGPSPoint
                        label="Latitud"
                        errors={errors}
                        touched={touched}
                        handleFieldValues={(field, value) => {
                            setFieldValue(field, value)
                            handleChangeMarkers(field, value)
                        }}
                        values={values}
                        disabled={
                            disabled || (isEdit && !pigeonHousesPermissions.lat)
                        }
                        placeholder="Latitud"
                        fieldName="lat"
                    />
                </Col>
                <Col xs="6">
                    <LblInputGPSPoint
                        label="Longitud"
                        errors={errors}
                        touched={touched}
                        handleFieldValues={(field, value) => {
                            setFieldValue(field, value)
                            handleChangeMarkers(field, value)
                        }}
                        values={values}
                        disabled={
                            disabled || (isEdit && !pigeonHousesPermissions.lng)
                        }
                        placeholder="Longitud"
                        fieldName="lng"
                    />
                </Col>
            </Row>

            <Row>
                <Col className="mt-2 d-flex justify-content-between">
                    <MapModal>
                        <MapWrapper markers={markers} height="80vh" />
                    </MapModal>
                    {!disabled && (
                        <Button
                            type="submit"
                            color="primary"
                            disabled={isSubmitting}
                        >
                            {btnSaveName || 'Guardar'}
                        </Button>
                    )}
                </Col>
            </Row>
        </Form>
    )
}

FormPigeonHouses.defaultProps = {
    disabled: false,
    btnSaveName: 'Guardar',
    onSubmit: null,
    isEdit: false,
}

export default FormPigeonHouses
