import { call, put, takeLatest } from 'redux-saga/effects'
import { login } from '../../services/auth.service'
import {
    LOGIN_START,
    loginFailed,
    loginSuccess,
    LOGOUT,
    logoutSuccess,
} from '../actions/auth'

function* loginStart({ payload }: any) {
    try {
        const { username, password, onSuccess } = payload
        const { data } = yield call(login, username, password)
        localStorage.setItem('tokenId', data.token)
        yield put(loginSuccess(data.data))
        onSuccess()
    } catch (err) {
        const { onError } = payload
        yield put(loginFailed())
        onError(err.response.data.message ?? null)
    }
}

function* logoutStart({ payload }: any) {
    const { logoutSuccess: onLogoutSuccess } = payload
    localStorage.removeItem('tokenId')
    onLogoutSuccess()
    yield put(logoutSuccess())
}

export function* watchAuthStart() {
    yield takeLatest(LOGIN_START, loginStart)
    yield takeLatest(LOGOUT, logoutStart)
}
