import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner, Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'

import Container from '../../components/Container'

import { Club } from '../../models/club'
import { fetchMyClubStart, editMyClubStart } from '../../store/actions/clubs'
import FormClub, { CreateClubFormInterface } from '../../components/FormClub'
import { SelectInterface } from '../../models/select'
import { fetchLocationsStart } from '../../store/actions/locations'
import { IRootState } from '../../store/reducers'
import { User } from '../../models/user'
import userHelper from '../../utils/userHelper'

import { ContForm } from '../../styles/conteiners/conteiners'
import { getPermissions } from '../../utils/permissions'

import {
    crudMessagesError,
    crudMessagesSuccess,
    FormActions,
} from '../../utils/crudHelper'

function MyClub() {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [myClub, setMyClub] = useState<Club>(null)
    const [locations, setLocations] = useState<SelectInterface[]>([])
    const userLogged: User = useSelector(
        (state: IRootState) => state.authReducer.user
    )
    const { myClub: myClubPermissions } = getPermissions(userLogged?.profile)
    const { edit: canEditMyClub } = myClubPermissions

    const fetchLocations = () => {
        dispatch(
            fetchLocationsStart((locationsFetched) =>
                setLocations(locationsFetched)
            )
        )
    }

    const getData = () => {
        setIsLoading(true)
        dispatch(
            fetchMyClubStart((clubsFetched) => {
                setMyClub(clubsFetched)
            })
        )
        setIsLoading(false)
    }

    const editMyClub = (values: CreateClubFormInterface) => {
        const onSuccess = () => {
            getData()
            toast.success(crudMessagesSuccess[FormActions.EDIT])
        }

        const onError = (errorMessage: string | null) => {
            const message = errorMessage ?? crudMessagesError[FormActions.EDIT]
            toast.error(message)
        }
        dispatch(editMyClubStart(values, onSuccess, onError))
    }

    React.useEffect(() => {
        getData()
        fetchLocations()
    }, [])

    return (
        <Container>
            <Row>
                <Col>
                    <h2>Mi Club</h2>
                </Col>
            </Row>
            <Row className="mt-3">
                <ContForm>
                    {!isLoading ? (
                        <Col>
                            {myClub ? (
                                <FormClub
                                    createClub={editMyClub}
                                    locations={locations}
                                    initialValues={myClub}
                                    editMode={false}
                                    disabled={!canEditMyClub}
                                    btnSaveName="Modificar"
                                />
                            ) : (
                                <h3 className="text-danger text-center">
                                    No posee un club en su cuenta
                                    <br />
                                    consulte con un administrador
                                </h3>
                            )}
                        </Col>
                    ) : (
                        <Spinner
                            color="primary"
                            type="grow"
                            id="loader_spinner"
                        >
                            Cargando...
                        </Spinner>
                    )}
                </ContForm>
            </Row>
        </Container>
    )
}

export default MyClub
