import adminPermissions from './profiles/admin.json'
import clubOwnerPermissions from './profiles/club_owner.json'
import partnerPermissions from './profiles/partner.json'
import { TypeProfiles } from '../../models/user'

interface PermissionABMInterface {
    create: boolean
    edit: boolean
    delete: boolean
    list: boolean
    details: boolean
}

interface PermissionMyConfigInterface {
    edit: boolean
}

export interface PermissionsInterface {
    profileType: string
    users: PermissionABMInterface
    clubs: PermissionABMInterface
    pigeonHouses: PermissionABMInterface
    myProfile: PermissionMyConfigInterface
    myPigeonHouse: PermissionMyConfigInterface
    myClub: PermissionMyConfigInterface
    racing: PermissionABMInterface
    championships: PermissionABMInterface
    markedPigeonsInRace: PermissionABMInterface
    markedPigeonsSuspended: PermissionABMInterface
    markedPigeonsClosed: PermissionABMInterface
}

export const getPermissions = (profile: TypeProfiles): PermissionsInterface => {
    const permissions = {
        [TypeProfiles.ADMIN]: adminPermissions,
        [TypeProfiles.RACEMAKER]: clubOwnerPermissions,
        [TypeProfiles.USER]: partnerPermissions,
    }

    return permissions[profile]
}
