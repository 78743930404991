import React, { useEffect, useState } from 'react'
import { useFormik, FormikErrors } from 'formik'
import { Form, Button, Col, FormGroup, Label, Row, Input } from 'reactstrap'
import moment, { Moment } from 'moment'
import InputMask from 'react-input-mask'

import { useSelector } from 'react-redux'
import Select from '../Select'
import InputForm from '../InputForm'
import { SelectInterface } from '../../models/select'
import { findSelected, parseSelectOptions } from '../../utils/selectHelper'

import MapWrapper, { MapModal } from '../MapWrapper'

import DatePicker from '../DatePicker'

import { FormActions, buttonNames } from '../../utils/crudHelper'
import { TypeProfiles, User } from '../../models/user'
import { IRootState } from '../../store/reducers'
import { getFormPermissions } from '../../utils/permissions/formPermissions'

interface GpsPoint {
    lat: number
    lng: number
}

export interface MarkedPigeonInterface {
    id?: number
    pigeonHouseId: number
    pigeonRacingId: number
    ringNumber: string
    ringYear: string
    markedDate: Date | Moment
    markedDay?: string
    markedTime: string
    team: number
    distance?: number
    time?: number
    speed?: number
    position?: number
    points?: number
    times?: number
    distances?: number
    avgSpeed?: number
    racePoint?: GpsPoint
    pigeonHousePoint?: GpsPoint
    user?: User
    userName?: string
    countMarked?: number
}

export interface MarkedPigeonPostionInterface {
    id?: number
    pigeonHouseId: number
    pigeonHouse: string
    position: number
    user: string
    points?: number
    ringNumber?: string
}

interface FormMarkedPigonsProps {
    createMarkedPigeon: (values: MarkedPigeonInterface) => void
    editMarkedPigeon: (markedId: number, values: MarkedPigeonInterface) => void
    deleteMarkedPigeon: (markedId: number) => void
    initialValues: MarkedPigeonInterface
    disabled?: boolean
    action: FormActions
    markedData: MarkedPigeonInterface
    isOpen: boolean
    pigeonHousesSelect: SelectInterface[]
}

function FormMarkedPigeons({
    createMarkedPigeon,
    editMarkedPigeon,
    deleteMarkedPigeon,
    initialValues,
    disabled = false,
    action,
    markedData,
    isOpen,
    pigeonHousesSelect,
}: FormMarkedPigonsProps) {
    const [racePoint, setRacePoint] = useState(null)
    const [pigeonHousePoint, setPigeonHousePoint] = useState(null)
    const pigeonHousesMapped = parseSelectOptions(pigeonHousesSelect)

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        setValues,
    } = useFormik({
        initialValues: {
            ...initialValues,
        },
        onSubmit: (data, { setSubmitting }) => {
            if (action === FormActions.CREATE) {
                createMarkedPigeon(data)
            } else if (action === FormActions.EDIT) {
                editMarkedPigeon(markedData.id, data)
            } else if (action === FormActions.DELETE) {
                deleteMarkedPigeon(markedData.id)
            }
            setSubmitting(false)
        },
        validate: (data) => {
            const errorsValidate: FormikErrors<MarkedPigeonInterface> = {}

            if (action !== FormActions.DELETE) {
                if (
                    !data.ringNumber ||
                    data.ringNumber === '0' ||
                    data.ringNumber === '0000000'
                ) {
                    errorsValidate.ringNumber = 'Required'
                }
                if (!data.pigeonHouseId) {
                    errorsValidate.pigeonHouseId = 'Required'
                }
            }
            return errorsValidate
        },
    })

    const userLogged: User = useSelector(
        (state: IRootState) => state.authReducer.user
    )
    const { markedPigeons: markedPigeonsPermissions } = getFormPermissions(
        userLogged?.profile
    )

    useEffect(() => {
        if (isOpen === true) {
            if (action === FormActions.CREATE) {
                const pigeonHouseIdLogged = userLogged?.pigeonHouseId ?? null
                setValues({
                    ...initialValues,
                    pigeonHouseId:
                        initialValues.pigeonHouseId ?? pigeonHouseIdLogged,
                })
            } else {
                setRacePoint(markedData.racePoint || null)

                setPigeonHousePoint(markedData.pigeonHousePoint || null)

                setValues({
                    ...markedData,
                    markedDate: markedData.markedDate
                        ? moment(markedData.markedDate)
                        : moment(),
                })
            }
        }
    }, [isOpen, action, markedData, userLogged])

    return (
        <Form className="form" onSubmit={handleSubmit}>
            <Row>
                <Col xs="6">
                    <FormGroup>
                        <Label for="name">Numero del anillo</Label>
                        <InputForm
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={(e) => {
                                handleBlur(e)
                                setFieldValue(
                                    'ringNumber',
                                    e.target.value.padStart(7, '0')
                                )
                            }}
                            touched={touched}
                            values={values}
                            disabled={disabled}
                            placeholder="Anillo"
                            fieldName="ringNumber"
                            maxLength={7}
                            type="integer"
                        />
                    </FormGroup>
                </Col>
                <Col xs="6">
                    <FormGroup>
                        <Label for="name">Año del anillo</Label>
                        <InputMask
                            disabled={disabled}
                            mask="9999"
                            value={values.ringYear}
                            className="form-control"
                            onChange={(e) => {
                                setFieldValue('ringYear', e.target.value)
                            }}
                            maskChar="0"
                        />
                    </FormGroup>
                </Col>
                <Col xs="6">
                    <FormGroup>
                        <Label for="name">Fecha de marcada</Label>
                        <DatePicker
                            id="markedDate"
                            name="markedDate"
                            onChange={(value) =>
                                setFieldValue('markedDate', value)
                            }
                            value={values.markedDate}
                            disabled={disabled}
                        />
                    </FormGroup>
                </Col>
                <Col xs="6">
                    <FormGroup>
                        <Label for="name">Hora de marcada</Label>
                        <InputMask
                            disabled={disabled}
                            mask="99:99:99"
                            value={values.markedTime}
                            className="form-control"
                            onChange={(e) => {
                                setFieldValue('markedTime', e.target.value)
                            }}
                            maskChar="0"
                        />
                    </FormGroup>
                </Col>
                <Col xs="6">
                    <FormGroup>
                        <Label for="pigeonHouseId">Palomar</Label>
                        <Select
                            fieldName="pigeonHouseId"
                            touched={touched}
                            errors={errors}
                            options={pigeonHousesMapped}
                            onChange={({ value }) =>
                                setFieldValue('pigeonHouseId', value)
                            }
                            disabled={
                                disabled ||
                                !markedPigeonsPermissions.pigeonHouseId ||
                                action !== FormActions.CREATE
                            }
                            value={findSelected(
                                pigeonHousesMapped,
                                values.pigeonHouseId
                            )}
                            required
                        />
                    </FormGroup>
                </Col>
                <Col xs="6">
                    <FormGroup>
                        <Label for="name">Equipo</Label>
                        <InputForm
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            values={values}
                            disabled={disabled}
                            placeholder="Fecha"
                            fieldName="team"
                            type="number"
                        />
                    </FormGroup>
                </Col>
            </Row>
            {action !== FormActions.CREATE && (
                <Row>
                    <Col xs="6">
                        <FormGroup>
                            <Label for="name">Distancia</Label>
                            <Input
                                id="distance"
                                name="distance"
                                className="form-control"
                                disabled
                                value={`${
                                    values.distance?.toFixed(3) ?? '0'
                                } km`}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs="6">
                        <FormGroup>
                            <Label for="name">Tiempo</Label>
                            <Input
                                id="time"
                                name="time"
                                className="form-control"
                                disabled
                                value={`${
                                    values.time?.toFixed(2) ?? '0'
                                } horas`}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs="6">
                        <FormGroup>
                            <Label for="name">Promedio de vuelo</Label>
                            <Input
                                id="speed"
                                name="speed"
                                className="form-control"
                                disabled
                                value={`${
                                    values.speed?.toFixed(2) ?? '0'
                                } km/h`}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs="6">
                        <FormGroup>
                            <Label for="name">Puntos</Label>
                            <Input
                                id="points"
                                name="points"
                                className="form-control"
                                disabled
                                value={`${values.points?.toFixed(3) ?? '0'}`}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            )}

            <Row className="mt-2 d-flex justify-content-between">
                <Col>
                    {racePoint !== null && pigeonHousePoint !== null && (
                        <MapModal>
                            <MapWrapper
                                markers={[
                                    {
                                        name: 'Largada',
                                        point: racePoint,
                                    },
                                    {
                                        name: 'Palomar',
                                        point: pigeonHousePoint,
                                    },
                                ]}
                                polylines={[
                                    {
                                        route: [racePoint, pigeonHousePoint],
                                        withoutMarker: true,
                                    },
                                ]}
                                height="80vh"
                            />
                        </MapModal>
                    )}
                </Col>
                <Col className="d-flex justify-content-end">
                    {action !== FormActions.DETAIL && (
                        <Button
                            type="submit"
                            color={
                                action === FormActions.DELETE
                                    ? 'danger'
                                    : 'primary'
                            }
                            disabled={isSubmitting}
                        >
                            {buttonNames[action] || 'Guardar'}
                        </Button>
                    )}
                </Col>
            </Row>
        </Form>
    )
}

FormMarkedPigeons.defaultProps = {
    disabled: false,
}

export default FormMarkedPigeons
