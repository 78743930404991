import { LocationInterface } from './location'
import { Club } from './club'
import { User } from './user'

export interface IPigeonHouse {
    id: string
    name: string
    lat: number
    lng: number
    address: string
    isPublic: boolean
    state: number
    club: Club
    clubId: number
    location: LocationInterface
    locationId: number
    createdAt: string
    user?: User
}

export const PigeonHouseStates = ['Activo', 'Deshabilitado', 'Fallecido']
