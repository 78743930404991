import React from 'react'

function Container({ children }: any) {
    return (
        <div className="my-3 mx-2 my-md-3 mx-md-4 my-lg-3 mx-lg-5">
            {children}
        </div>
    )
}

export default Container
