import styled from 'styled-components'

export const WrapFilters = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;

    @media (max-width: 576px) {
        flex-direction: column;
        row-gap: 12px;
    }
`
