export interface Championship {
    id: number
    name: string
    isPublic: boolean
    clubId: number
    state: number
    structure: number
    typeBestPigeon: number
    year: number
    maxPigeons: number
    eyePigeonFancier: boolean
    runLimited: boolean
    runSpecial: boolean
    isIntersocial: boolean
    intersocialClubs?: number[]
    typeInscription: number
}

export const ChampionshipsState = {
    REGISTRATION: 1, // En inscripcion
    ACTIVE: 2, // Activo
    FINALIZED: 3, // Finalizado
    SUSPENDED: 4, // Suspendido
}

export const ChampionshipsStateLabels = {
    [ChampionshipsState.REGISTRATION]: 'En inscripción',
    [ChampionshipsState.ACTIVE]: 'Activo',
    [ChampionshipsState.FINALIZED]: 'Finalizado',
    [ChampionshipsState.SUSPENDED]: 'Suspendido',
}

export const ChampionshipsStates = [
    {
        value: '1',
        label: 'En inscripción',
    },
    {
        value: '2',
        label: 'Activo',
    },
    {
        value: '3',
        label: 'Finalizado',
    },
    {
        value: '4',
        label: 'Suspendido',
    },
]

export const ChampionshipsTypeBestPigeon = {
    WITHOUT_BEST: 1, // Sin mejor paloma
    NUMBER_OF_POSTS: 2, // Por cantidad de puestos sin importar equipos
}

export const ChampionshipsTypeBestPigeonLabels = {
    [ChampionshipsTypeBestPigeon.WITHOUT_BEST]: 'Sin mejor paloma',
    [ChampionshipsTypeBestPigeon.NUMBER_OF_POSTS]: 'Por cantidad de puestos',
}

export const ChampionshipsBestPigeonValues = [
    {
        value: '1',
        label: 'Sin mejor paloma',
    },
    {
        value: '2',
        label: 'Por cantidad de puestos',
    },
]

export const ChampionshipsStructure = { NORMAL: 1 }

export const ChampionshipsStructureLabels = {
    [ChampionshipsStructure.NORMAL]: 'Normal',
}

export const ChampionshipsStructureValues = [
    {
        value: '1',
        label: 'Normal',
    },
]

export const ChampionshipsTypeInscription = {
    WITHOUT_INSCRIPTIONS: 1, // Sin inscripciones
    INSCRIPTION_REQUIRED: 2, // Inscripciones obligatorias
    OPTIONAL_INSCRIPTION: 3, // Inscripciones opcionales
}

export const ChampionshipsTypeInscriptionLabels = {
    [ChampionshipsTypeInscription.WITHOUT_INSCRIPTIONS]: 'Sin inscripciones',
    [ChampionshipsTypeInscription.INSCRIPTION_REQUIRED]:
        'Inscripciones obligatorias',
    [ChampionshipsTypeInscription.OPTIONAL_INSCRIPTION]:
        'Inscripciones opcionales',
}

export const ChampionshipsTypeInscriptionValues = [
    {
        value: '1',
        label: 'Sin inscripciones',
    },
    {
        value: '2',
        label: 'Inscripciones obligatorias',
    },
    {
        value: '3',
        label: 'Inscripciones opcionales',
    },
]
