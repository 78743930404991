/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react'
import { Button, FormGroup, Input, Label, FormFeedback } from 'reactstrap'
import styled from 'styled-components'

interface ILblInputGPSPointProps {
    handleFieldValues?: (field: string, value: any) => void
    label: string
    values: any
    errors: any
    touched: any
    fieldName: string
    placeholder?: string
    disabled?: boolean
}

enum CoordinatesTypes {
    DECIMAL = 'DECIMAL',
    DEGREES = 'DEGREES',
}

const ConteinerInputs = styled.div`
    display: flex;
    align-items: end;
    position: relative;

    .invalid-feedback {
        position: absolute;
        bottom: -20px;
    }
`

function LblInputGPSPoint(props: ILblInputGPSPointProps) {
    const {
        label,
        handleFieldValues,
        values,
        errors,
        touched,
        fieldName,
        placeholder,
        disabled,
    } = props

    const [degreesValues, setDegreesValues] = useState({
        degrees: 0,
        minutes: 0,
        seconds: 0,
    })

    const [coordinatesType, setCoordinatesType] = useState<CoordinatesTypes>(
        CoordinatesTypes.DECIMAL
    )

    const handleChangeType = () => {
        setCoordinatesType(
            coordinatesType === CoordinatesTypes.DECIMAL
                ? CoordinatesTypes.DEGREES
                : CoordinatesTypes.DECIMAL
        )
    }

    const handleChange = (value) => {
        if (
            (/^-{0,1}\d*\.{0,1}\d*$/.test(value) &&
                value[value.length - 1] === '.') ||
            value === '' ||
            value === '-'
        ) {
            handleFieldValues(fieldName, value)
        } else if (!isNaN(parseFloat(value))) {
            handleFieldValues(fieldName, parseFloat(value))
        }
    }

    const convertDegreesToDesimal = (degreesValues: any) => {
        const { degrees, minutes, seconds } = degreesValues
        const degreesValue = parseInt(degrees, 10)
        const minutesValue = parseInt(minutes, 10)
        const secondsValue = parseInt(seconds, 10)

        const decimalValue =
            degreesValue + minutesValue / 60 + secondsValue / 3600
        handleChange(parseFloat(decimalValue.toFixed(6)) * -1)
    }

    const convertDesimalToDegrees = (decimal: number) => {
        const decimalValue = decimal < 0 ? decimal * -1 : decimal

        const degrees = Math.floor(decimalValue)
        const minutes = Math.floor((decimalValue - degrees) * 60)
        const seconds = Math.floor(
            (decimalValue - degrees - minutes / 60) * 3600
        )
        setDegreesValues({
            degrees,
            minutes,
            seconds,
        })
    }

    const handleChangeDegrees = (type: string) => {
        return (e) => {
            const { value } = e.target
            const dataValue = {
                ...degreesValues,
                [type]: value,
            }
            setDegreesValues(dataValue)

            convertDegreesToDesimal(dataValue)
        }
    }

    useEffect(() => {
        if (coordinatesType === CoordinatesTypes.DECIMAL)
            convertDesimalToDegrees(values[fieldName])
    }, [values[fieldName]])

    const isInvalid = errors[fieldName] && touched[fieldName]

    return (
        <FormGroup>
            <Label for={fieldName}>
                {label}{' '}
                <small>
                    {coordinatesType === CoordinatesTypes.DECIMAL
                        ? '(decimales)'
                        : '(grados min. y seg.)'}
                </small>
            </Label>

            <ConteinerInputs>
                {coordinatesType === CoordinatesTypes.DECIMAL ? (
                    <Input
                        id={fieldName}
                        name={fieldName}
                        className="form-control"
                        type="text"
                        placeholder={placeholder}
                        onChange={(e) => {
                            handleChange(e.target.value)
                        }}
                        value={values[fieldName]}
                        disabled={disabled}
                        invalid={isInvalid}
                    />
                ) : (
                    <>
                        <Input
                            id={fieldName}
                            name={fieldName}
                            className="form-control"
                            type="number"
                            placeholder={placeholder}
                            onChange={handleChangeDegrees('degrees')}
                            value={degreesValues.degrees}
                            disabled={disabled}
                            invalid={isInvalid}
                            max="360"
                        />
                        <Input
                            id={fieldName}
                            name={fieldName}
                            className="form-control ms-2"
                            type="number"
                            placeholder={placeholder}
                            onChange={handleChangeDegrees('minutes')}
                            value={degreesValues.minutes}
                            disabled={disabled}
                            invalid={isInvalid}
                            max="59"
                        />
                        <Input
                            id={fieldName}
                            name={fieldName}
                            className="form-control ms-2"
                            type="number"
                            placeholder={placeholder}
                            onChange={handleChangeDegrees('seconds')}
                            value={degreesValues.seconds}
                            disabled={disabled}
                            invalid={isInvalid}
                            max="59"
                        />
                    </>
                )}
                <Button
                    onClick={handleChangeType}
                    className="ms-xs-2 ms-3"
                    color="primary"
                >
                    {coordinatesType === CoordinatesTypes.DECIMAL
                        ? 'Grados'
                        : 'Decimales'}
                </Button>
                <FormFeedback invalid>
                    {isInvalid && errors[fieldName]}
                </FormFeedback>
            </ConteinerInputs>
        </FormGroup>
    )
}

LblInputGPSPoint.defaultProps = {
    placeholder: '',
    disabled: false,
    handleFieldValues: undefined,
}

export default LblInputGPSPoint
