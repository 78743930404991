import { CreateClubFormInterface } from '../components/FormClub'
import { axiosInstance } from './base.service'

export const createChampionship = (values: CreateClubFormInterface) => {
    return axiosInstance.post('/championships', values)
}

export const editChampionship = (
    championshipId: string,
    values: CreateClubFormInterface
) => {
    return axiosInstance.put(`/championships/${championshipId}`, values)
}

export const fetchChampionships = (listPublic = true) => {
    return axiosInstance.get(
        `/championships?isPublic=${listPublic ? '1' : '0'}`
    )
}

export const deleteChampionship = (championshipId: string) => {
    return axiosInstance.delete(`/championships/${championshipId}`)
}

export const fetchChampionshipsSelect = () => {
    return axiosInstance.get('/championships/select?isPublic=1')
}
