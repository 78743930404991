import styled from 'styled-components'

export const ContForm = styled.div`
    border: solid 1px #d7d7d7;
    border-radius: 10px;
    background: whitesmoke;
    padding: 25px;
    margin-top: 8px;
    min-height: 400px;
    /* display: flex; */

    #loader_spinner {
        align-self: center;
        margin: 0 auto;
    }
`

export const ContFormNonFlex = styled.div`
    border: solid 1px #d7d7d7;
    border-radius: 10px;
    background: whitesmoke;
    padding: 25px;
    margin-top: 8px;
    min-height: 400px;

    #loader_spinner {
        align-self: center;
        margin: 0 auto;
    }
`
