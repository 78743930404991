import { Form, Formik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, FormGroup, Input, Label, Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'

import { loginStart } from '../../store/actions/auth'
import './styles.scss'

interface InitialValuesInterface {
    username: string
    password: string
}

function Login() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onSuccess = () => {
        navigate('/dashboard')
    }
    const initialValues: InitialValuesInterface = { username: '', password: '' }

    const onError = (errorMessage: string | null) => {
        const message = errorMessage ?? 'Error al iniciar sesion'
        toast.error(message)
    }

    return (
        <div className="App">
            <Row className="my-4">
                <Col>
                    <h2 className="text-center">Iniciar sesión</h2>
                </Col>
            </Row>
            <Formik
                initialValues={initialValues}
                validate={(values) => {
                    const errors: Partial<InitialValuesInterface> = {}
                    if (!values.username) {
                        errors.username = 'Required'
                    }
                    if (!values.password) {
                        errors.password = 'Required'
                    }
                    return errors
                }}
                onSubmit={(values, { setSubmitting }) => {
                    dispatch(
                        loginStart(
                            values.username,
                            values.password,
                            onSuccess,
                            onError
                        )
                    )
                    setSubmitting(false)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <Form className="form" onSubmit={handleSubmit}>
                        <Row xs={1}>
                            <Col>
                                <FormGroup>
                                    <Label for="username">
                                        Nombre de Usuario
                                    </Label>
                                    <Input
                                        type="text"
                                        id="username"
                                        name="username"
                                        onChange={handleChange}
                                        className="form-control"
                                        placeholder="Nombre de Usuario"
                                        onBlur={handleBlur}
                                        value={values.username}
                                        invalid={
                                            errors.username && touched.username
                                        }
                                    />
                                    {errors.username &&
                                        touched.username &&
                                        errors.username}
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="password">Contraseña</Label>
                                    <Input
                                        type="password"
                                        name="password"
                                        onChange={handleChange}
                                        id="password"
                                        onBlur={handleBlur}
                                        value={values.password}
                                        placeholder="Contraseña"
                                        invalid={
                                            errors.password && touched.password
                                        }
                                    />
                                    {errors.password &&
                                        touched.password &&
                                        errors.password}
                                </FormGroup>
                            </Col>
                            <Col className="mt-3">
                                <Button
                                    type="submit"
                                    color="primary"
                                    block
                                    disabled={isSubmitting}
                                >
                                    Entrar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default Login
