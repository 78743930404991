export const LOGIN_START = 'LOGIN_START'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const loginStart = (
    username: string,
    password: string,
    onSuccess: () => void,
    onError: (message: string | null) => void
) => ({
    type: LOGIN_START,
    payload: {
        username,
        password,
        onSuccess,
        onError,
    },
})

export const loginSuccess = (data: any) => ({
    type: LOGIN_SUCCESS,
    payload: data,
})

export const loginFailed = () => ({
    type: LOGIN_FAILED,
})

export const logout = (logoutSuccess) => ({
    type: LOGOUT,
    payload: {
        logoutSuccess,
    },
})

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
})
