export enum FormActions {
    CREATE = 'CREATE',
    EDIT = 'EDIT',
    DETAIL = 'DETAIL',
    DELETE = 'DELETE',
}

export const buttonNames = {
    [FormActions.CREATE]: 'Guardar',
    [FormActions.EDIT]: 'Editar',
    [FormActions.DELETE]: 'Eliminar',
}

export const crudMessagesSuccess = {
    [FormActions.CREATE]: 'Se ha creado el registro correctamente',
    [FormActions.EDIT]: 'Se ha editado el registro correctamente',
    [FormActions.DELETE]: 'Se ha eliminado el registro correctamente',
}

export const crudMessagesError = {
    [FormActions.CREATE]: 'Error al crear el archivo',
    [FormActions.EDIT]: 'No se puede editar el registro',
    [FormActions.DELETE]: 'No se puede eliminar el registro',
}
