import { axiosInstance } from './base.service'
import { CreateUserFormInterface } from '../components/FormUser'

export const fetchMyProfile = () => {
    return axiosInstance.get('/users/myprofile')
}

export const editMyProfile = (values: CreateUserFormInterface) => {
    return axiosInstance.put(`/users/myprofile`, values)
}
