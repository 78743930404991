import React, { useState, useEffect } from 'react'
import { FormikErrors, useFormik } from 'formik'
import { Button, Col, FormGroup, Label, Row, Form, Input } from 'reactstrap'
import { Moment } from 'moment'
import InputMask from 'react-input-mask'
import { MultiValue } from 'react-select'

import InputForm from '../InputForm'
import { SelectInterface } from '../../models/select'
import {
    findSelected,
    parseSelectOptions,
    findSelectedMultiple,
} from '../../utils/selectHelper'

import Select from '../Select'
import { RaceCategories, RaceStates, RaceTypes } from '../../models/racing'
import DatePicker from '../DatePicker'
import MapWrapper, { MapModal } from '../MapWrapper'
import LblInputGPSPoint from '../LblInputGPSPoint'

export interface CreateRaceFormInterface {
    championshipId: number
    name: string
    line: number
    date: Moment | string
    startTime: string
    lat: number
    lng: number
    distance: string
    type: number
    state: number
    categorie: number
    details: string
    pigeonsSended: number
    positions: number
    orientation: string
    typeAccordingToDistance: string
    windDirection: string
    windIntensity: string
    pausedTime?: {
        date?: Moment | string
        startTime: string
        endTime: string
    }
    pigeonRacingChampionships: number[]
    scoredPerRace: number
}

export interface CreateRaceFormErrorsInterface {
    championshipId: string
    name: string
    line: string
    date: string
    startTime: string
    lat: number
    lng: number
    distance: string
    type: string
    state: string
    categorie: string
    details: string
    pigeonsSended: string
    positions: string
    orientation: string
    typeAccordingToDistance: string
    windDirection: string
    windIntensity: string
    pigeonRacingChampionships: string
    scoredPerRace: number
}

interface FormRaceProps {
    createRace: (values: CreateRaceFormInterface) => void
    initialValues: CreateRaceFormInterface
    championshipsSelect: SelectInterface[]
    canEdit?: boolean
    disabled?: boolean
    isEdit?: boolean
    isOpen: boolean
}

function FormRace({
    createRace,
    initialValues,
    championshipsSelect,
    canEdit = false,
    disabled = false,
    isEdit = false,
    isOpen,
}: FormRaceProps) {
    const [markers, setMarkers] = useState([])
    const championshipsMapped = parseSelectOptions(championshipsSelect)

    const [discountTime, setDiscountTime] = useState(false)

    const getDefaultValueDate = (values) => {
        if (isEdit) {
            return (
                values.date &&
                values.date.split('T').length > 0 &&
                values.date.split('T')[0]
            )
        }
        return values.date
    }

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setValues,
    } = useFormik({
        initialValues: { ...initialValues },
        validate: (values) => {
            const errors: FormikErrors<CreateRaceFormErrorsInterface> = {}
            if (!values.name) {
                errors.name = 'Required'
            }
            if (values.pigeonRacingChampionships.length === 0) {
                errors.pigeonRacingChampionships = 'Required'
            }
            if (values.lat === 0) {
                errors.lat = 'Required'
            }
            if (values.lng === 0) {
                errors.lng = 'Required'
            }
            return errors
        },
        onSubmit: (values, { setSubmitting }) => {
            createRace({
                ...values,
                pausedTime: discountTime ? values.pausedTime : null,
            })
            setSubmitting(false)
        },
    })

    useEffect(() => {
        if (initialValues) {
            if (initialValues.pausedTime !== null) {
                setDiscountTime(true)
            } else {
                setDiscountTime(false)
            }

            setValues({
                ...initialValues,
            })
            if (initialValues.lat && initialValues.lng) {
                setMarkers([
                    {
                        name: initialValues.name,
                        defaultIsOpen: true,
                        point: {
                            lat: initialValues.lat,
                            lng: initialValues.lng,
                        },
                    },
                ])
            }
        }
    }, [isOpen, initialValues])

    const handleChangeMarkers = (field: string, value: number) => {
        setMarkers([
            {
                ...markers,
                name: values.name,
                point: {
                    lat: field === 'lat' ? value : values.lat,
                    lng: field === 'lng' ? value : values.lng,
                },
            },
        ])
    }

    const getChampionshipsIds = (e: MultiValue<SelectInterface>) => {
        return e && e.length ? e.map((club) => Number(club.value)) : []
    }

    return (
        <Form className="form" onSubmit={handleSubmit}>
            {/* <ContForm> */}
            <Row>
                <Col xs="12" md="6" lg="4">
                    <FormGroup>
                        <Label for="name">Nombre</Label>
                        <InputForm
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            placeholder="Nombre"
                            values={values}
                            fieldName="name"
                            disabled={disabled}
                        />
                    </FormGroup>
                </Col>
                <Col xs="12" md="6" lg="4">
                    <FormGroup>
                        <Label for="pigeonRacingChampionships">Torneo</Label>
                        <Select
                            options={championshipsMapped}
                            onChange={(e) =>
                                setFieldValue(
                                    'pigeonRacingChampionships',
                                    getChampionshipsIds(e)
                                )
                            }
                            required
                            isMulti
                            fieldName="pigeonRacingChampionships"
                            touched={touched}
                            errors={errors}
                            disabled={disabled || !canEdit}
                            value={findSelectedMultiple(
                                championshipsMapped,
                                values.pigeonRacingChampionships
                            )}
                        />
                    </FormGroup>
                </Col>
                {isEdit && (
                    <Col xs="12" md="6" lg="12">
                        <FormGroup>
                            <Label for="type">Estado</Label>
                            <Select
                                options={RaceStates}
                                onChange={({ value }) =>
                                    setFieldValue('state', value)
                                }
                                required
                                fieldName="state"
                                touched={touched}
                                errors={errors}
                                disabled={disabled || !canEdit}
                                value={findSelected(RaceStates, values.state)}
                            />
                        </FormGroup>
                    </Col>
                )}
            </Row>
            <Row>
                <Col xs="12" md="6" lg="3">
                    <FormGroup>
                        <Label for="startTime">Fecha Inicio</Label>
                        <DatePicker
                            id="date"
                            name="date"
                            onChange={(value) => setFieldValue('date', value)}
                            value={values.date}
                            disabled={disabled}
                        />
                    </FormGroup>
                </Col>
                <Col xs="12" md="6" lg="3">
                    <FormGroup>
                        <Label for="startTime">Hora Inicio</Label>
                        <InputMask
                            disabled={disabled}
                            mask="99:99:99"
                            value={values.startTime}
                            className="form-control"
                            onChange={(e) => {
                                setFieldValue('startTime', e.target.value)
                            }}
                            maskChar="0"
                        />
                    </FormGroup>
                </Col>
                <Col xs="12" md="6" lg="3">
                    <FormGroup>
                        <Label for="distance">Distancia</Label>
                        <InputForm
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            placeholder="Distancia"
                            values={values}
                            type="number"
                            fieldName="distance"
                            disabled={disabled}
                        />
                    </FormGroup>
                </Col>
                <Col xs="12" md="6" lg="3">
                    <FormGroup>
                        <Label for="scoredPerRace">Cantidad Puntuadas</Label>
                        <InputForm
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            placeholder="0"
                            values={values}
                            type="number"
                            fieldName="scoredPerRace"
                            disabled={disabled}
                        />
                    </FormGroup>
                </Col>

                <Col xs="12" md="6" lg="4">
                    <LblInputGPSPoint
                        label="Latitud"
                        errors={errors}
                        touched={touched}
                        handleFieldValues={(field, value) => {
                            setFieldValue(field, value)
                            handleChangeMarkers(field, value)
                        }}
                        values={values}
                        disabled={disabled}
                        placeholder="Latitud"
                        fieldName="lat"
                    />
                </Col>

                <Col xs="12" md="6" lg="4">
                    <LblInputGPSPoint
                        label="Longitud"
                        errors={errors}
                        touched={touched}
                        handleFieldValues={(field, value) => {
                            setFieldValue(field, value)
                            handleChangeMarkers(field, value)
                        }}
                        values={values}
                        disabled={disabled}
                        placeholder="Longitud"
                        fieldName="lng"
                    />
                </Col>

                <Col xs="12" md="12" lg="4">
                    <FormGroup>
                        <Label for="categorie">Categoría</Label>
                        <Select
                            options={RaceCategories}
                            onChange={({ value }) =>
                                setFieldValue('categorie', value)
                            }
                            required
                            fieldName="categorie"
                            disabled={disabled || !canEdit}
                            touched={touched}
                            errors={errors}
                            value={findSelected(
                                RaceCategories,
                                values.categorie
                            )}
                        />
                    </FormGroup>
                </Col>

                <hr />

                <Col xs="12" md="6" lg="4">
                    <FormGroup>
                        <Label for="distance">Orientación</Label>
                        <InputForm
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            // placeholder="Orientación"
                            values={values}
                            fieldName="orientation"
                            disabled={disabled}
                        />
                    </FormGroup>
                </Col>

                <Col xs="12" md="6" lg="4">
                    <FormGroup>
                        <Label for="distance">Posiciones</Label>
                        <InputForm
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            placeholder="Posiciones"
                            values={values}
                            fieldName="positions"
                            disabled={disabled}
                        />
                    </FormGroup>
                </Col>

                <Col xs="12" md="6" lg="4">
                    <FormGroup>
                        <Label for="type">Tipo de Carrera</Label>
                        <Select
                            options={RaceTypes}
                            onChange={({ value }) =>
                                setFieldValue('type', value)
                            }
                            required
                            fieldName="type"
                            touched={touched}
                            errors={errors}
                            disabled={disabled || !canEdit}
                            value={findSelected(RaceTypes, values.type)}
                        />
                    </FormGroup>
                </Col>

                <Col xs="12" md="6" lg="4">
                    <FormGroup className="row">
                        <Label
                            for="discountTime"
                            onClick={() => setDiscountTime(!discountTime)}
                            className="col-12"
                        >
                            Horario nocturno
                        </Label>
                        <Col>
                            <Input
                                onChange={() => setDiscountTime(!discountTime)}
                                checked={discountTime}
                                type="checkbox"
                                disabled={disabled || !canEdit}
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col xs="12" md="6" lg="4">
                    <FormGroup>
                        <Label for="startTime">Hora Inicio Nocturno</Label>
                        <InputMask
                            disabled={disabled || !discountTime}
                            mask="99:99:99"
                            value={values.pausedTime?.startTime ?? '00:00:00'}
                            className="form-control"
                            onChange={(e) => {
                                setFieldValue('pausedTime', {
                                    ...values.pausedTime,
                                    startTime: e.target.value,
                                })
                            }}
                            maskChar="0"
                        />
                    </FormGroup>
                </Col>
                <Col xs="12" md="6" lg="4">
                    <FormGroup>
                        <Label for="endTime">Hora Fin Nocturno</Label>
                        <InputMask
                            disabled={disabled || !discountTime}
                            mask="99:99:99"
                            value={values.pausedTime?.endTime ?? '00:00:00'}
                            className="form-control"
                            onChange={(e) => {
                                setFieldValue('pausedTime', {
                                    ...values.pausedTime,
                                    endTime: e.target.value,
                                })
                            }}
                            maskChar="0"
                        />
                    </FormGroup>
                </Col>

                <Col xs="12">
                    <FormGroup>
                        <Label for="distance">Detalle</Label>
                        <InputForm
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            placeholder="Detalle"
                            values={values}
                            fieldName="details"
                            disabled={disabled}
                            type="textarea"
                        />
                    </FormGroup>
                </Col>
            </Row>
            {/* </ContForm> */}
            <Row>
                <Col className="mt-2 d-flex justify-content-between">
                    <MapModal>
                        <MapWrapper markers={markers} height="80vh" />
                    </MapModal>
                    {!disabled && (
                        <Button
                            type="submit"
                            color="primary"
                            disabled={isSubmitting}
                        >
                            Guardar
                        </Button>
                    )}
                </Col>
            </Row>
        </Form>
    )
}

FormRace.defaultProps = {
    disabled: false,
    canEdit: false,
    isEdit: false,
}

export default FormRace
