import {
    FETCH_USERS_FAILED,
    FETCH_USERS_START,
    FETCH_USERS_SUCCESS,
} from '../actions/users'

interface InitialStateType {
    loadingUsers: boolean
}

const initialState: InitialStateType = {
    loadingUsers: false,
}

const usersReducer = function (
    // eslint-disable-next-line default-param-last
    state: InitialStateType = initialState,
    action: any
) {
    switch (action.type) {
        case FETCH_USERS_START:
            return {
                ...state,
                loadingUsers: true,
            }
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                loadingUsers: false,
            }
        case FETCH_USERS_FAILED:
            return {
                ...state,
                loadingUsers: false,
            }
        default:
            return state
    }
}

export default usersReducer
