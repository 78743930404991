import { CreateUserFormInterface } from '../components/FormUser'
import { axiosInstance } from './base.service'

export const fetchUsers = () => {
    return axiosInstance.get(`/users`)
}

export const deleteUser = (userId: string) => {
    return axiosInstance.delete(`/users/${userId}`)
}

export const createUser = (values: CreateUserFormInterface) => {
    return axiosInstance.post(`/users`, values)
}

export const editUser = (userId: string, values: CreateUserFormInterface) => {
    return axiosInstance.put(`/users/${userId}`, values)
}
