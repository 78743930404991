import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner, Row, Col, Button } from 'reactstrap'
import { toast } from 'react-toastify'

import Container from '../../components/Container'

import { IPigeonHouse } from '../../models/pigeonHouse'
import {
    fetchMyPigeonHouseStart,
    editMyPigeonHouseStart,
} from '../../store/actions/pigeonHouses'
import FormPigeonHouses, {
    PigeonHousesFormInterface,
} from '../../components/FormPigeonHouses'
import { SelectInterface } from '../../models/select'
import { fetchLocationsStart } from '../../store/actions/locations'
import { fetchClubsSelectStart } from '../../store/actions/clubs'

import { ContForm } from '../../styles/conteiners/conteiners'
import { User } from '../../models/user'
import { IRootState } from '../../store/reducers'
import { getPermissions } from '../../utils/permissions'

import {
    FormActions,
    crudMessagesSuccess,
    crudMessagesError,
} from '../../utils/crudHelper'

function MyPigeonHouse() {
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)

    const [myPigeonHouse, setMyPigeonHouse] = useState<IPigeonHouse>(null)

    const [locations, setLocations] = useState<SelectInterface[]>([])
    const [clubs, setClubs] = useState<SelectInterface[]>([])

    const fetchLocations = () => {
        dispatch(
            fetchLocationsStart((locationsFetched) =>
                setLocations(locationsFetched)
            )
        )
    }

    const fetchClubsSelect = () => {
        dispatch(
            fetchClubsSelectStart((clubsFetched) => setClubs(clubsFetched))
        )
    }

    const getData = () => {
        setIsLoading(true)
        dispatch(
            fetchMyPigeonHouseStart((pigeonHousesFetched) => {
                setMyPigeonHouse(pigeonHousesFetched)
            })
        )
        setIsLoading(false)
    }

    const editMyPigeonHouse = (values: PigeonHousesFormInterface) => {
        const onSuccess = () => {
            toast.success(crudMessagesSuccess[FormActions.EDIT])
            getData()
        }

        const onError = (errorMessage: string | null) => {
            const message = errorMessage ?? crudMessagesError[FormActions.EDIT]
            toast.error(message)
        }

        dispatch(editMyPigeonHouseStart(values, onSuccess, onError))
    }
    const userLogged: User = useSelector(
        (state: IRootState) => state.authReducer.user
    )
    const { myPigeonHouse: myPigeonHousePermissions } = getPermissions(
        userLogged?.profile
    )
    const { edit: canEditMyPigeonHouse } = myPigeonHousePermissions

    React.useEffect(() => {
        getData()
        fetchLocations()
        fetchClubsSelect()
    }, [])

    return (
        <Container>
            <Row>
                <Col>
                    <h2>Mi Palomar</h2>
                </Col>
            </Row>
            <Row>
                <ContForm>
                    {!isLoading ? (
                        <Col>
                            {myPigeonHouse ? (
                                <FormPigeonHouses
                                    onSubmit={editMyPigeonHouse}
                                    locations={locations}
                                    clubs={clubs}
                                    initialValues={myPigeonHouse}
                                    isEdit
                                    disabled={!canEditMyPigeonHouse}
                                    btnSaveName="Modificar"
                                />
                            ) : (
                                <h3 className="text-danger text-center">
                                    No posee un palomar en su cuenta
                                    <br />
                                    consulte con un administrador
                                </h3>
                            )}
                        </Col>
                    ) : (
                        <Spinner
                            color="primary"
                            type="grow"
                            id="loader_spinner"
                        >
                            Cargando...
                        </Spinner>
                    )}
                </ContForm>
            </Row>
        </Container>
    )
}

export default MyPigeonHouse
