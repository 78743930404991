import { call, put, takeLatest } from 'redux-saga/effects'
import { fetchLocationsSelect } from '../../services/locations.service'
import {
    FETCH_LOCATIONS_START,
    fetchLocationsFailed,
    fetchLocationsSuccess,
} from '../actions/locations'
import {
    FETCH_PIGEON_HOUSES_START,
    fetchPigeonHousesFailed,
    fetchPigeonHousesSuccess,
    FETCH_PIGEON_HOUSES_SELECT_START,
    fetchPigeonHousesSelectFailed,
    fetchPigeonHousesSelectSuccess,
    CREATE_PIGEON_HOUSE_START,
    createPigeonHouseSuccess,
    createPigeonHouseFailed,
    EDIT_PIGEON_HOUSE_START,
    editPigeonHouseSuccess,
    editPigeonHouseFailed,
    DELETE_PIGEON_HOUSE_START,
    deletePigeonHouseSuccess,
    deletePigeonHouseFailed,
    FETCH_MY_PIGEON_HOUSE_START,
    fetchMyPigeonHouseSuccess,
    fetchMyPigeonHouseFailed,
    EDIT_MY_PIGEON_HOUSE_START,
    editMyPigeonHouseSuccess,
    editMyPigeonHouseFailed,
} from '../actions/pigeonHouses'
import {
    fetchPigeonHouses,
    fetchPigeonHousesSelect,
    deletePigeonHouse,
    editPigeonHouse,
    createPigeonHouse,
    fetchMyPigeonHouse,
    editMyPigeonHouse,
} from '../../services/pigeonHouses.service'

function* fetchPigeonHousesApi({ payload: { onLoad } }: any) {
    try {
        const {
            data: { data: pigeonHouses },
        } = yield call(fetchPigeonHouses)
        onLoad(pigeonHouses)
        yield put(fetchPigeonHousesSuccess(pigeonHouses))
    } catch {
        yield put(fetchPigeonHousesFailed())
    }
}

function* fetchPigeonHousesSelectApi({ payload: { onLoad } }: any) {
    try {
        const {
            data: { data: pigeonHouses },
        } = yield call(fetchPigeonHousesSelect)
        onLoad(pigeonHouses)
        yield put(fetchPigeonHousesSelectSuccess(pigeonHouses))
    } catch {
        yield put(fetchPigeonHousesSelectFailed())
    }
}

function* createPigeonHouseApi({
    payload: { values, onSuccess, onError },
}: any) {
    try {
        yield call(createPigeonHouse, values)
        yield put(createPigeonHouseSuccess())
        onSuccess()
    } catch (err) {
        yield put(createPigeonHouseFailed())
        onError(err.response.data.message ?? null)
    }
}

function* deletePigeonHouseApi({
    payload: { pigeonHouseId, onSuccess, onError },
}: any) {
    try {
        yield call(deletePigeonHouse, pigeonHouseId)
        yield put(deletePigeonHouseSuccess())
        onSuccess()
    } catch (err) {
        yield put(deletePigeonHouseFailed())
        onError(err.response.data.message ?? null)
    }
}

function* editPigeonHouseApi({
    payload: { values, pigeonHouseId, onSuccess, onError },
}: any) {
    try {
        yield call(editPigeonHouse, pigeonHouseId, values)
        yield put(editPigeonHouseSuccess())
        onSuccess()
    } catch (err) {
        yield put(editPigeonHouseFailed())
        onError(err.response.data.message ?? null)
    }
}

function* fetchMyPigeonHouseApi({ payload: { onLoad } }: any) {
    try {
        const {
            data: { data: myPigeonHouse },
        } = yield call(fetchMyPigeonHouse)
        onLoad(myPigeonHouse)
        yield put(fetchMyPigeonHouseSuccess(myPigeonHouse))
    } catch (err) {
        yield put(fetchMyPigeonHouseFailed())
    }
}

function* editMyPigeonHouseApi({
    payload: { values, onSuccess, onError },
}: any) {
    try {
        yield call(editMyPigeonHouse, values)
        yield put(editMyPigeonHouseSuccess())
        onSuccess()
    } catch (err) {
        yield put(editMyPigeonHouseFailed())
        onError(err.response.data.message ?? null)
    }
}

export function* watchPigeonHousesStart() {
    yield takeLatest(CREATE_PIGEON_HOUSE_START, createPigeonHouseApi)
    yield takeLatest(EDIT_PIGEON_HOUSE_START, editPigeonHouseApi)
    yield takeLatest(DELETE_PIGEON_HOUSE_START, deletePigeonHouseApi)
    yield takeLatest(FETCH_PIGEON_HOUSES_START, fetchPigeonHousesApi)
    yield takeLatest(
        FETCH_PIGEON_HOUSES_SELECT_START,
        fetchPigeonHousesSelectApi
    )
    yield takeLatest(FETCH_MY_PIGEON_HOUSE_START, fetchMyPigeonHouseApi)
    yield takeLatest(EDIT_MY_PIGEON_HOUSE_START, editMyPigeonHouseApi)
}
