import { Form, Formik } from 'formik'
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from 'reactstrap'
import React, { useState } from 'react'
import { MultiValue } from 'react-select'
import { useSelector } from 'react-redux'

import InputForm from '../InputForm'
import { SelectInterface } from '../../models/select'
import {
    findSelected,
    findSelectedMultiple,
    parseSelectOptions,
} from '../../utils/selectHelper'
import {
    ChampionshipsBestPigeonValues,
    ChampionshipsStates,
    ChampionshipsTypeInscriptionValues,
} from '../../models/championship'
import { TypeProfiles, User } from '../../models/user'
import { IRootState } from '../../store/reducers'
import Select from '../Select'

export interface CreateTournamentFormInterface {
    name: string
    isPublic: boolean
    clubId?: number
    state: number
    structure: number
    typeBestPigeon: number
    year: number
    maxPigeons: number
    eyePigeonFancier: boolean
    runLimited: boolean
    runSpecial: boolean
    isIntersocial: boolean
    intersocialClubs?: number[]
    typeInscription: number
}

export interface CreateTournamentFormErrorsInterface {
    name: string
    isPublic: string
    clubId?: string
    state: string
    structure: string
    typeBestPigeon: string
    year: string
    maxPigeons: string
    eyePigeonFancier: string
    runLimited: string
    runSpecial: string
    isIntersocial: string
    intersocialClubs?: string
    typeInscription: string
}

interface FormTournamentProps {
    createChampionship: (values: CreateTournamentFormInterface) => void
    initialValues: CreateTournamentFormInterface
    clubsSelect: SelectInterface[]
    canEdit?: boolean
    disabled?: boolean
    isEdit?: boolean
}

function FormChampionship({
    createChampionship,
    initialValues,
    clubsSelect,
    canEdit = false,
    disabled = false,
    isEdit = false,
}: FormTournamentProps) {
    const [activeTab, setActiveTab] = useState<string>('1')
    const clubsMapped = parseSelectOptions(clubsSelect)

    const userLogged: User = useSelector(
        (state: IRootState) => state.authReducer.user
    )

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }
    const getClubIds = (e: MultiValue<SelectInterface>) => {
        return e && e.length ? e.map((club) => Number(club.value)) : []
    }

    return (
        <Formik
            initialValues={initialValues}
            validate={(values) => {
                const errors: Partial<CreateTournamentFormErrorsInterface> = {}
                if (!values.name) {
                    errors.name = 'Required'
                }
                return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
                const newValues = { ...values, clubId: Number(values.clubId) }
                createChampionship(newValues)
                setSubmitting(false)
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
            }) => (
                <Form className="form" onSubmit={handleSubmit}>
                    {/* <ContForm> */}
                    <Row>
                        <Col>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={
                                            activeTab === '1' ? 'active' : ''
                                        }
                                        onClick={() => toggle('1')}
                                    >
                                        Social
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={
                                            activeTab === '2' ? 'active' : ''
                                        }
                                        onClick={() => toggle('2')}
                                    >
                                        Intersocial
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col xs="6">
                                            <FormGroup>
                                                <Label for="name">Nombre</Label>
                                                <InputForm
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    touched={touched}
                                                    placeholder="Nombre"
                                                    values={values}
                                                    fieldName="name"
                                                    disabled={disabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6">
                                            <FormGroup>
                                                <Label for="clubId">Club</Label>
                                                <div>
                                                    <Select
                                                        fieldName="clubId"
                                                        required
                                                        touched={touched}
                                                        errors={errors}
                                                        options={clubsMapped}
                                                        onChange={({ value }) =>
                                                            setFieldValue(
                                                                'clubId',
                                                                value
                                                            )
                                                        }
                                                        disabled={
                                                            disabled ||
                                                            !canEdit ||
                                                            isEdit ||
                                                            (userLogged &&
                                                                userLogged.profile !==
                                                                    TypeProfiles.ADMIN)
                                                        }
                                                        value={findSelected(
                                                            clubsMapped,
                                                            values.clubId
                                                        )}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="4">
                                            <FormGroup>
                                                <Label for="year">Año</Label>
                                                <InputForm
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    touched={touched}
                                                    placeholder="Año"
                                                    values={values}
                                                    fieldName="year"
                                                    disabled={disabled}
                                                    type="number"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="4">
                                            <FormGroup>
                                                <Label for="typeBestPigeon">
                                                    Tipo de mejor paloma
                                                </Label>
                                                <Select
                                                    fieldName="typeBestPigeon"
                                                    required
                                                    touched={touched}
                                                    errors={errors}
                                                    options={
                                                        ChampionshipsBestPigeonValues
                                                    }
                                                    onChange={({ value }) =>
                                                        setFieldValue(
                                                            'typeBestPigeon',
                                                            Number(value)
                                                        )
                                                    }
                                                    disabled={disabled}
                                                    value={findSelected(
                                                        ChampionshipsBestPigeonValues,
                                                        values.typeBestPigeon
                                                    )}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="4">
                                            <FormGroup>
                                                <Label for="state">
                                                    Tipo inscripción
                                                </Label>
                                                <Select
                                                    fieldName="typeInscription"
                                                    required
                                                    touched={touched}
                                                    errors={errors}
                                                    options={
                                                        ChampionshipsTypeInscriptionValues
                                                    }
                                                    onChange={({ value }) =>
                                                        setFieldValue(
                                                            'typeInscription',
                                                            Number(value)
                                                        )
                                                    }
                                                    disabled={disabled}
                                                    value={findSelected(
                                                        ChampionshipsTypeInscriptionValues,
                                                        values.typeInscription
                                                    )}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {isEdit && (
                                        <Row>
                                            <Col xs="4">
                                                <FormGroup>
                                                    <Label for="state">
                                                        Estado
                                                    </Label>
                                                    <Select
                                                        fieldName="state"
                                                        required
                                                        touched={touched}
                                                        errors={errors}
                                                        options={
                                                            ChampionshipsStates
                                                        }
                                                        onChange={({ value }) =>
                                                            setFieldValue(
                                                                'state',
                                                                Number(value)
                                                            )
                                                        }
                                                        disabled={disabled}
                                                        value={findSelected(
                                                            ChampionshipsStates,
                                                            values.state
                                                        )}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col xs="4">
                                            <label htmlFor="isPublic">
                                                Público
                                            </label>
                                            <div>
                                                <Input
                                                    onChange={() =>
                                                        setFieldValue(
                                                            'isPublic',
                                                            !values.isPublic
                                                        )
                                                    }
                                                    checked={values.isPublic}
                                                    type="checkbox"
                                                    className="ms-2"
                                                />
                                            </div>
                                        </Col>
                                        <Col xs="4">
                                            <label htmlFor="eyePigeonFancier">
                                                Corre ojo Colombófilo
                                            </label>
                                            <div>
                                                <Input
                                                    onChange={() =>
                                                        setFieldValue(
                                                            'eyePigeonFancier',
                                                            !values.eyePigeonFancier
                                                        )
                                                    }
                                                    checked={
                                                        values.eyePigeonFancier
                                                    }
                                                    type="checkbox"
                                                    className="ms-2"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <FormGroup>
                                        <Label for="clubId">Clubs</Label>
                                        <Select
                                            fieldName="intersocialClubs"
                                            required
                                            isMulti
                                            touched={touched}
                                            errors={errors}
                                            options={clubsMapped}
                                            onChange={(
                                                e: MultiValue<SelectInterface>
                                            ) => {
                                                const clubsIds = getClubIds(e)
                                                if (e.length > 0) {
                                                    setFieldValue(
                                                        'isIntersocial',
                                                        true
                                                    )
                                                } else {
                                                    setFieldValue(
                                                        'isIntersocial',
                                                        false
                                                    )
                                                }
                                                setFieldValue(
                                                    'intersocialClubs',
                                                    clubsIds
                                                )
                                            }}
                                            disabled={disabled}
                                            value={findSelectedMultiple(
                                                clubsMapped,
                                                values.intersocialClubs
                                            )}
                                        />
                                    </FormGroup>
                                </TabPane>
                            </TabContent>
                        </Col>
                        {/* </ContForm> */}
                    </Row>
                    {!disabled && (
                        <Row>
                            <Col className="mt-2 d-flex justify-content-end">
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                >
                                    Guardar
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Form>
            )}
        </Formik>
    )
}

FormChampionship.defaultProps = {
    disabled: false,
    canEdit: false,
    isEdit: false,
}

export default FormChampionship
