/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import { Button, Spinner, Row, Col, Input, Label } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FaRedoAlt, FaDownload } from 'react-icons/fa'
import moment from 'moment'

import { IRootState } from '../../store/reducers'
import {
    setRaceId,
    fetchRaceDetailsStart,
    fetchMarkedPigeonsGroupedStart,
} from '../../store/actions/racings'

import { sortNumbers } from '../../utils/tableHelper'
import { findSelected, parseSelectOptions } from '../../utils/selectHelper'
import { formatHoursToMinutes } from '../../utils/dateHelper'

import useSearch from '../../hooks/useSearch'

import CustomTable from '../../components/CustomTable'
import Container from '../../components/Container'

import {
    MarkedPigeonInterface,
    MarkedPigeonPostionInterface,
} from '../../components/FormMarkedPigeons'
import { Racing, RaceStateLabels } from '../../models/racing'
import Select from '../../components/Select'

import { TableButton } from '../../styles/buttons/tableButtons'
import { ColHeader } from '../../styles/conteiners/colHeader'
import { WrapFilters } from '../../styles/conteiners/filters'
import { getMarkedPigeonsGroupedPdf } from '../../services/racings.service'
import { PositionType } from './shared/constants/filters'

function Positions() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [markedPigeons, setMarkedPigeons] = useState<MarkedPigeonInterface[]>(
        []
    )
    const [race, setRace] = useState<Racing>({} as Racing)

    const raceId = useSelector(
        (state: IRootState) => state.racingsReducer.raceId
    )

    const [filter, setFilter] = useState<PositionType | null>(
        raceId == null
            ? PositionType.championship
            : PositionType.racePigeonHouse
    )
    const [groupTeam, setGroupTeam] = useState(true)
    const [championshipId, setChampionshipId] = useState(null)

    const [isLoading, setIsLoading] = useState(true)

    const championships = useSelector(
        (state: IRootState) => state.racingsReducer.championshipId
    )

    const fetchMarkedPigeons = () => {
        if (
            ![PositionType.championship, PositionType.racePigeonHouse].includes(
                filter
            )
        ) {
            setGroupTeam(false)
        }

        setIsLoading(true)
        dispatch(
            fetchMarkedPigeonsGroupedStart(
                raceId,
                (markedFetched) => {
                    setIsLoading(false)
                    setMarkedPigeons(markedFetched)
                },
                filter,
                groupTeam,
                championshipId
            )
        )
    }

    const downloadPdf = async () => {
        const response = await getMarkedPigeonsGroupedPdf(
            raceId,
            filter,
            groupTeam,
            championshipId
        )

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file.pdf')
        document.body.appendChild(link)
        link.click()

        // const blobb = new Blob([response.data])
        // blobb.then((blob: any) => {
        //     // Creating new object of PDF file
        //     const fileURL = window.URL.createObjectURL(blob)
        //     // Setting various property values
        //     const alink = document.createElement('a')
        //     alink.href = fileURL
        //     alink.download = 'SamplePDF.pdf'
        //     alink.click()
        // })

        // console.log(URL.createObjectURL(new Blob([response.data])))

        // window.open(URL.createObjectURL(new Blob([response.data])))
    }

    const fetchRace = (raceId) =>
        dispatch(
            fetchRaceDetailsStart((raceFetched) => setRace(raceFetched), raceId)
        )

    const columnsPigeonRingComplete = [
        {
            name: 'Posición',
            selector: (row: MarkedPigeonPostionInterface) => row.position,
            sortable: true,
            width: '150px',
            sortFunction: sortNumbers('position'),
        },
        {
            name: 'Paloma anillo N°',
            selector: (row: MarkedPigeonPostionInterface) => row.ringNumber,
            sortable: true,
        },
        {
            name: 'Participante',
            selector: (row: MarkedPigeonPostionInterface) => row.user,
            sortable: true,
        },
        {
            name: 'Palomar',
            selector: (row: MarkedPigeonPostionInterface) => row.pigeonHouse,
            sortable: true,
        },
        {
            name: 'Distancia recorrida',
            selector: (row: MarkedPigeonInterface) =>
                `${row.distances?.toFixed(3) ?? '0'} km`,
            sortable: true,
            sortFunction: sortNumbers('distances'),
        },
        {
            name: 'Tiempo de vuelo (minutos)',
            selector: (row: MarkedPigeonInterface) =>
                row.times ? formatHoursToMinutes(row.times) : '00:00',
            sortable: true,
            sortFunction: sortNumbers('time'),
        },
        {
            name: 'Promedio de vuelo',
            selector: (row: MarkedPigeonInterface) =>
                `${row.avgSpeed?.toFixed(2) ?? '0'} km/h`,
            sortable: true,
            sortFunction: sortNumbers('avgSpeed'),
        },
        {
            name: 'Puntos',
            selector: (row: MarkedPigeonInterface) =>
                row.points.toFixed(3) ?? 0,
            sortable: true,
            sortFunction: sortNumbers('points'),
        },
    ]

    const columnsPigeonRing = [
        {
            name: 'Posición',
            selector: (row: MarkedPigeonPostionInterface) => row.position,
            sortable: true,
            width: '150px',
            sortFunction: sortNumbers('position'),
        },
        {
            name: 'Paloma anillo N°',
            selector: (row: MarkedPigeonPostionInterface) => row.ringNumber,
            sortable: true,
        },
        {
            name: 'Participante',
            selector: (row: MarkedPigeonPostionInterface) => row.user,
            sortable: true,
        },
        {
            name: 'Palomar',
            selector: (row: MarkedPigeonPostionInterface) => row.pigeonHouse,
            sortable: true,
        },
        {
            name: 'Puntos',
            selector: (row: MarkedPigeonInterface) =>
                row.points.toFixed(3) ?? 0,
            sortable: true,
            sortFunction: sortNumbers('points'),
        },
    ]

    const columnsPigeonHouse = [
        {
            name: 'Posición',
            selector: (row: MarkedPigeonPostionInterface) => row.position,
            sortable: true,
            width: '150px',
        },
        {
            name: 'Cantidad',
            selector: (row: MarkedPigeonInterface) => row.countMarked,
            sortable: true,
            maxWidth: '100px',
        },
        {
            name: 'Participante',
            selector: (row: MarkedPigeonPostionInterface) => row.user,
            sortable: true,
        },
        {
            name: 'Palomar',
            selector: (row: MarkedPigeonPostionInterface) => row.pigeonHouse,
            sortable: true,
        },
        {
            name: 'Puntos',
            selector: (row: MarkedPigeonInterface) =>
                row.points.toFixed(3) ?? 0,
            sortable: true,
        },
    ]

    React.useEffect(() => {
        if (championships && championships.length > 0) {
            setChampionshipId(championships[0].id)
        }

        if (raceId) {
            fetchRace(raceId)
        }
        fetchMarkedPigeons()
    }, [raceId, championships])

    React.useEffect(() => {
        fetchMarkedPigeons()
    }, [filter, groupTeam, championshipId])

    const goToRacings = () => {
        dispatch(setRaceId(null))
        navigate('/racing')
    }

    const [filterText, setFilterText] = React.useState('')
    const { filteredItems } = useSearch(markedPigeons, filterText)
    const filterOptions =
        raceId == null
            ? [
                  {
                      label: 'Por torneo', // 'Por palomar del torneo',
                      value: PositionType.championship,
                  },
                  {
                      label: 'Anillo de oro', // 'Individual del torneo',
                      value: PositionType.championshipRingPigeon,
                  },
              ]
            : [
                  {
                      label: 'Mayor puntaje de la carrera', // 'Por palomar de la carrera',
                      value: PositionType.racePigeonHouse,
                  },
                  {
                      label: 'Por torneo', // 'Por palomar del torneo',
                      value: PositionType.championship,
                  },
                  {
                      label: 'Individual de la carrera',
                      value: PositionType.raceRingPigeon,
                  },
                  {
                      label: 'Anillo de oro', // 'Individual del torneo',
                      value: PositionType.championshipRingPigeon,
                  },
              ]

    const championshipsMapped = parseSelectOptions(championships)

    const SubHeaderComponentMemo = React.useMemo(() => {
        return (
            <WrapFilters>
                {championships &&
                    championships.length > 1 &&
                    [
                        PositionType.championship,
                        PositionType.championshipRingPigeon,
                    ].includes(filter) && (
                        <div className="d-flex align-items-center me-3">
                            Torneo
                            <div
                                style={{ minWidth: '250px', maxWidth: '100vw' }}
                            >
                                <Select
                                    options={championshipsMapped}
                                    onChange={({ value }) =>
                                        setChampionshipId(Number(value))
                                    }
                                    required
                                    fieldName="championshipId"
                                    touched={{}}
                                    errors={{}}
                                    value={findSelected(
                                        championshipsMapped,
                                        championshipId
                                    )}
                                    className="ms-2"
                                />
                            </div>
                        </div>
                    )}
                <div className="d-flex align-items-center me-3">
                    Tipo de listado
                    <div style={{ minWidth: '250px', maxWidth: '100vw' }}>
                        <Select
                            options={filterOptions}
                            errors={{}}
                            value={findSelected(filterOptions, filter)}
                            onChange={({ value }) => setFilter(value)}
                            fieldName="filterSelect"
                            touched={{}}
                            className="ms-2"
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center me-3">
                    <span
                        onClick={() => {
                            if (
                                ['racePigeonHouse', 'championship'].includes(
                                    filter
                                )
                            )
                                setGroupTeam(!groupTeam)
                        }}
                        style={{ cursor: 'pointer' }}
                    >
                        Por Equipo
                    </span>

                    <Input
                        onChange={() => setGroupTeam(!groupTeam)}
                        checked={groupTeam}
                        type="checkbox"
                        disabled={
                            !['racePigeonHouse', 'championship'].includes(
                                filter
                            )
                        }
                        className="ms-2"
                    />
                </div>
                <div className="d-flex align-items-center me-3">
                    Buscar
                    <Input
                        className="ms-2"
                        onChange={(e) => setFilterText(e.target.value)}
                        value={filterText}
                    />
                </div>
                <div>
                    <TableButton type="button" onClick={downloadPdf}>
                        <FaDownload />
                    </TableButton>
                </div>
                <div style={{ marginLeft: '10px' }}>
                    <TableButton type="button" onClick={fetchMarkedPigeons}>
                        <FaRedoAlt />
                    </TableButton>
                </div>
            </WrapFilters>
        )
    }, [filterText, filterOptions, filter, championshipId])

    const columns = () => {
        let columnReturn = []
        switch (filter) {
            case 'racePigeonHouse':
            case 'championship':
                columnReturn = columnsPigeonHouse
                break
            case 'raceRingPigeon':
                columnReturn = columnsPigeonRingComplete
                break
            default:
                columnReturn = columnsPigeonRing
                break
        }

        if (groupTeam === true) {
            columnReturn.splice(3, 0, {
                name: 'Equipo',
                selector: (row: MarkedPigeonInterface) =>
                    row.team?.toString() ?? '',
                sortable: true,
            })
        }

        return columnReturn
    }

    if (raceId === null && championshipId === null) {
        return <Spinner />
    }
    return (
        <Container>
            <Row>
                <Col className="d-flex justify-content-between">
                    <h3>Posiciones</h3>
                    <div>
                        <Button
                            color="secondary"
                            onClick={() => goToRacings()}
                            style={{
                                marginBottom: '1rem',
                            }}
                            className="me-2"
                        >
                            Volver
                        </Button>
                    </div>
                </Col>
            </Row>
            {race && (
                <Row>
                    <ColHeader>
                        <Label className="me-4">
                            Carrera: <span>{race.name}</span>
                        </Label>
                        <Label className="me-4">
                            Fecha:{' '}
                            <span>
                                {moment(race.date).format('DD/MM/YYYY')}
                            </span>
                        </Label>
                        <Label className="me-4">
                            Hora largada:
                            <span>{race.startTime}</span>
                        </Label>
                        <Label className="me-4">
                            Estado:{' '}
                            <span>
                                {race.state ? RaceStateLabels[race.state] : ''}
                            </span>
                        </Label>
                    </ColHeader>
                </Row>
            )}

            <Row>
                <Col>
                    <CustomTable
                        noHeader
                        striped
                        pagination
                        data={filteredItems}
                        subHeader
                        subHeaderComponent={SubHeaderComponentMemo}
                        progressPending={isLoading}
                        progressComponent={
                            <Spinner color="primary" type="grow">
                                Cargando...
                            </Spinner>
                        }
                        columns={columns()}
                        dense
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default Positions
