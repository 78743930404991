import { call, put, takeLatest } from 'redux-saga/effects'
import { fetchLocationsSelect } from '../../services/locations.service'
import {
    FETCH_LOCATIONS_START,
    fetchLocationsFailed,
    fetchLocationsSuccess,
} from '../actions/locations'

function* fetchLocationsApi({ payload: { onLoad } }: any) {
    try {
        const {
            data: { data: locations },
        } = yield call(fetchLocationsSelect)
        onLoad(locations)
        yield put(fetchLocationsSuccess(locations))
    } catch {
        yield put(fetchLocationsFailed())
    }
}

export function* watchLocationsStart() {
    yield takeLatest(FETCH_LOCATIONS_START, fetchLocationsApi)
}
