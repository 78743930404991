import { call, put, takeLatest } from 'redux-saga/effects'
import {
    CREATE_RACING_START,
    createRacingFailed,
    createRacingSuccess,
    DELETE_RACING_START,
    deleteRacingFailed,
    deleteRacingSuccess,
    EDIT_RACING_START,
    editRacingFailed,
    editRacingSuccess,
    FETCH_RACINGS_START,
    fetchRacingsFailed,
    fetchRacingsSuccess,
    CREATE_MARKED_PIGEON_START,
    createMarkedPigeonFailed,
    createMarkedPigeonSuccess,
    EDIT_MARKED_PIGEON_START,
    editMarkedPigeonFailed,
    editMarkedPigeonSuccess,
    FETCH_MARKED_PIGEON_START,
    fetchMarkedPigeonsFailed,
    fetchMarkedPigeonsSuccess,
    DELETE_MARKED_PIGEON_START,
    deleteMarkedPigeonFailed,
    deleteMarkedPigeonSuccess,
    fetchRaceDetailsSuccess,
    fetchRaceDetailsFailed,
    FETCH_RACE_DETAILS_START,
    FETCH_MARKED_PIGEON_GROUPED_START,
    fetchMarkedPigeonsGroupedSuccess,
    fetchMarkedPigeonsGroupedFailed,
    UPDATE_TIMES_AND_POINTS_START,
    updateTimesAndPointsSuccess,
    updateTimesAndPointsFailed,
} from '../actions/racings'
import {
    createRacing,
    deleteRacing,
    editRacing,
    fetchRacings,
    createMarkedPigeon,
    editMarkedPigeon,
    getMarkedPigeons,
    deleteMarkedPigeon,
    fetchRaceById,
    getMarkedPigeonsGrouped,
    updateTimesAndPoints,
} from '../../services/racings.service'

function* fetchRacingsApi({ payload: { onLoad, championshipId } }: any) {
    try {
        const {
            data: { data: racingList },
        } = yield call(fetchRacings, championshipId)
        onLoad(racingList)
        yield put(fetchRacingsSuccess(racingList))
    } catch {
        yield put(fetchRacingsFailed())
    }
}

function* fetchRaceByIdApi({ payload: { onLoad, raceId } }: any) {
    try {
        const {
            data: { data: race },
        } = yield call(fetchRaceById, raceId)
        onLoad(race)
        yield put(fetchRaceDetailsSuccess(race))
    } catch {
        yield put(fetchRaceDetailsFailed())
    }
}

function* createRacingApi({ payload: { values, onSuccess, onError } }: any) {
    try {
        const {
            data: { data: createdRace },
        } = yield call(createRacing, values)
        yield put(createRacingSuccess(createdRace))
        onSuccess()
    } catch (err) {
        yield put(createRacingFailed())
        onError(err.response.data.message ?? null)
    }
}

function* editRacingApi({
    payload: { values, raceId, onSuccess, onError },
}: any) {
    try {
        const {
            data: { data: editedRace },
        } = yield call(editRacing, raceId, values)
        yield put(editRacingSuccess(editedRace))
        onSuccess()
    } catch (err) {
        yield put(editRacingFailed())
        onError(err.response.data.message ?? null)
    }
}

function* deleteRacingApi({ payload: { raceId, onSuccess, onError } }: any) {
    try {
        yield call(deleteRacing, raceId)
        yield put(deleteRacingSuccess())
        onSuccess()
    } catch (err) {
        yield put(deleteRacingFailed())
        onError(err.response.data.message ?? null)
    }
}

function* fetchMarkedPigeonsApi({ payload: { raceId, onLoad } }: any) {
    try {
        const {
            data: { data: racingList },
        } = yield call(getMarkedPigeons, raceId)
        onLoad(racingList)
        yield put(fetchMarkedPigeonsSuccess(racingList))
    } catch {
        yield put(fetchMarkedPigeonsFailed())
    }
}

function* fetchMarkedPigeonsGroupedApi({
    payload: { raceId, onLoad, championshipId, filter, groupTeam },
}: any) {
    try {
        const {
            data: { data: markedPigeonsList },
        } = yield call(
            getMarkedPigeonsGrouped,
            raceId,
            filter,
            groupTeam,
            championshipId
        )
        onLoad(markedPigeonsList)
        yield put(fetchMarkedPigeonsGroupedSuccess(markedPigeonsList))
    } catch {
        yield put(fetchMarkedPigeonsGroupedFailed())
    }
}

function* createMarkedPigeonApi({
    payload: { values, onSuccess, onError },
}: any) {
    try {
        const {
            data: { data: createdMarkedPigeon },
        } = yield call(createMarkedPigeon, values)
        yield put(createMarkedPigeonSuccess(createdMarkedPigeon))
        onSuccess()
    } catch (err) {
        yield put(createMarkedPigeonFailed())
        onError(err.response.data.message ?? null)
    }
}

function* editMarkedPigeonApi({
    payload: { markedId, values, onSuccess, onError },
}: any) {
    try {
        const {
            data: { data: createdMarkedPigeon },
        } = yield call(editMarkedPigeon, markedId, values)
        yield put(editMarkedPigeonSuccess(createdMarkedPigeon))
        onSuccess()
    } catch (err) {
        yield put(editMarkedPigeonFailed())
        onError(err.response.data.message ?? null)
    }
}

function* deleteMarkedPigeonApi({
    payload: { markedId, onSuccess, onError },
}: any) {
    try {
        const { data } = yield call(deleteMarkedPigeon, markedId)
        yield put(deleteMarkedPigeonSuccess())
        onSuccess()
    } catch (err) {
        yield put(deleteMarkedPigeonFailed())
        onError(err.response.data.message ?? null)
    }
}

function* updateTimesAndPointsApi({ payload: { raceId, onSuccess } }: any) {
    try {
        const {
            data: { success },
        } = yield call(updateTimesAndPoints, raceId)

        yield put(updateTimesAndPointsSuccess())
        onSuccess()
    } catch (err) {
        yield put(updateTimesAndPointsFailed())
    }
}

export function* watchRacingsStart() {
    yield takeLatest(FETCH_RACINGS_START, fetchRacingsApi)
    yield takeLatest(CREATE_RACING_START, createRacingApi)
    yield takeLatest(EDIT_RACING_START, editRacingApi)
    yield takeLatest(DELETE_RACING_START, deleteRacingApi)
    yield takeLatest(CREATE_MARKED_PIGEON_START, createMarkedPigeonApi)
    yield takeLatest(EDIT_MARKED_PIGEON_START, editMarkedPigeonApi)
    yield takeLatest(FETCH_MARKED_PIGEON_START, fetchMarkedPigeonsApi)
    yield takeLatest(DELETE_MARKED_PIGEON_START, deleteMarkedPigeonApi)
    yield takeLatest(FETCH_RACE_DETAILS_START, fetchRaceByIdApi)
    yield takeLatest(
        FETCH_MARKED_PIGEON_GROUPED_START,
        fetchMarkedPigeonsGroupedApi
    )
    yield takeLatest(UPDATE_TIMES_AND_POINTS_START, updateTimesAndPointsApi)
}
