export const customStyles = {
    control: (base) => ({
        ...base,
        boxShadow: 'red',
        border: '1px solid red',
    }),
    menu: (base) => ({
        ...base,
        width: 'max-content',
        minWidth: '100%',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
}

export const basicStyles = {
    menu: (base) => ({
        ...base,
        width: 'max-content',
        minWidth: '100%',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
}

export const fixedHeightStyles = {
    menu: (base) => ({
        ...base,
        width: 'max-content',
        minWidth: '100%',
    }),
    valueContainer: (base) => ({
        ...base,
        // minHeight: 150,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
}
