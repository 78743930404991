import {
    FETCH_PIGEON_HOUSES_FAILED,
    FETCH_PIGEON_HOUSES_START,
    FETCH_PIGEON_HOUSES_SUCCESS,
} from '../actions/pigeonHouses'

interface InitialStateType {
    loadingPigeonHouses: boolean
}

const initialState: InitialStateType = {
    loadingPigeonHouses: false,
}

const pigeonHousesReducer = function (
    // eslint-disable-next-line default-param-last
    state: InitialStateType = initialState,
    action: any
) {
    switch (action.type) {
        case FETCH_PIGEON_HOUSES_START:
            return {
                ...state,
                loadingPigeonHouses: true,
            }
        case FETCH_PIGEON_HOUSES_SUCCESS:
            return {
                ...state,
                loadingPigeonHouses: false,
            }
        case FETCH_PIGEON_HOUSES_FAILED:
            return {
                ...state,
                loadingPigeonHouses: false,
            }
        default:
            return state
    }
}

export default pigeonHousesReducer
