import { CreateClubFormInterface } from '../components/FormClub'
import { MarkedPigeonInterface } from '../components/FormMarkedPigeons'

import { axiosInstance, axiosFilesInstance } from './base.service'

export const createRacing = (values: CreateClubFormInterface) => {
    return axiosInstance.post('/pigeonracing', values)
}

export const editRacing = (
    racingId: string,
    values: CreateClubFormInterface
) => {
    return axiosInstance.put(`/pigeonracing/${racingId}`, values)
}

export const fetchRacings = (championshipId: number) => {
    return axiosInstance.get(
        championshipId
            ? `/pigeonracing?championshipId=${championshipId}`
            : '/pigeonracing'
    )
}

export const fetchRaceById = (raceId: number) => {
    return axiosInstance.get(`/pigeonracing/${raceId}`)
}

export const deleteRacing = (racingId: string) => {
    return axiosInstance.delete(`/pigeonracing/${racingId}`)
}

export const createMarkedPigeon = (values: MarkedPigeonInterface) => {
    return axiosInstance.post(`/pigeonracing/markedPigeons/`, values)
}

export const editMarkedPigeon = (
    markedId: number,
    values: MarkedPigeonInterface
) => {
    return axiosInstance.put(`/pigeonracing/markedPigeons/${markedId}`, values)
}

export const getMarkedPigeons = (racingId: number) => {
    return axiosInstance.post(`/pigeonracing/markedPigeonsRace/${racingId}`)
}

interface IGetMarkedPigeonsGroupde {
    championshipId?: number
    raceId?: number
    ringPigeon?: boolean
    groupTeam?: boolean
    type?: string
}

export const getMarkedPigeonsGrouped = (
    raceId: number,
    filter: string,
    groupTeam?: boolean,
    championshipId?: number
) => {
    let data: IGetMarkedPigeonsGroupde = {}

    switch (filter) {
        case 'championship':
            data = {
                championshipId,
            }
            break
        case 'racePigeonHouse':
            data = {
                raceId,
            }
            break
        case 'raceRingPigeon':
            data = {
                raceId,
                ringPigeon: true,
            }
            break
        case 'championshipRingPigeon':
            data = {
                championshipId,
                ringPigeon: true,
            }
            break
        default:
            data = {
                raceId,
            }
            break
    }

    if (groupTeam === true) data.groupTeam = true

    return axiosInstance.post(`/pigeonracing/markedPigeonsGrouped`, data)
}

export const getMarkedPigeonsGroupedPdf = (
    raceId: number,
    filter: string,
    groupTeam?: boolean,
    championshipId?: number
) => {
    let data: IGetMarkedPigeonsGroupde = {}

    switch (filter) {
        case 'championship':
            data = {
                championshipId,
            }
            break
        case 'racePigeonHouse':
            data = {
                raceId,
            }
            break
        case 'raceRingPigeon':
            data = {
                raceId,
                ringPigeon: true,
            }
            break
        case 'championshipRingPigeon':
            data = {
                championshipId,
                ringPigeon: true,
            }
            break
        default:
            data = {
                raceId,
            }
            break
    }

    data.type = filter
    if (groupTeam === true) data.groupTeam = true

    return axiosFilesInstance.post(
        `/pigeonracing/markedPigeonsGrouped/pdf`,
        data
    )
}

export const deleteMarkedPigeon = (markedId: number) => {
    return axiosInstance.delete(`/pigeonracing/markedPigeons/${markedId}`)
}

export const updateTimesAndPoints = (raceId: number) => {
    return axiosInstance.post(`/pigeonracing/updateTimesAndPoints/${raceId}`)
}
