/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react'
import {
    withGoogleMap,
    withScriptjs,
    GoogleMap,
    Polyline,
} from 'react-google-maps'

import { IGpsPoint, IMap } from './interfaces'

import Marker from './Components/Marker'
import MapModal from './Components/MapModal'

const defaultPoints = {
    lat: -38.09791437193707,
    lng: -62.22334781843193,
}

const Map = withScriptjs(
    withGoogleMap((props: IMap) => {
        const { markers, polylines } = props

        const [centerPoint, setCenterPoint] = useState<IGpsPoint>(defaultPoints)

        useEffect(() => {
            if (markers && markers.length > 0) {
                setCenterPoint(markers[0].point)
            } else if (
                polylines &&
                polylines.length > 0 &&
                polylines[0].route.length > 0
            ) {
                setCenterPoint(polylines[0].route[0])
            }
        }, [markers, polylines])

        const mapStyles = [
            {
                featureType: 'administrative',
                elementType: 'geometry',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'poi',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'road',
                elementType: 'labels.icon',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'transit',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
        ]

        return (
            <div>
                <div style={{ height: '100vh', width: '100%' }}>
                    <GoogleMap
                        defaultZoom={13}
                        center={centerPoint}
                        options={{
                            styles: mapStyles,
                        }}
                    >
                        {markers &&
                            markers.map(
                                ({ point, name, content, defaultIsOpen }) => (
                                    <Marker
                                        title={name}
                                        content={content}
                                        position={{
                                            lat: point.lat,
                                            lng: point.lng,
                                        }}
                                        defaultIsOpen={defaultIsOpen}
                                    />
                                )
                            )}
                        {polylines &&
                            polylines.map(({ route, color, withoutMarker }) => (
                                <>
                                    {withoutMarker !== true && (
                                        <Marker
                                            position={{
                                                lat: route[0].lat,
                                                lng: route[0].lng,
                                            }}
                                        />
                                    )}
                                    <Polyline
                                        path={route}
                                        options={{
                                            strokeColor: color || '#FF0000',
                                            strokeOpacity: 0.8,
                                            strokeWeight: 2,
                                        }}
                                    />
                                    {withoutMarker !== true && (
                                        <Marker
                                            position={{
                                                lat: route[route.length - 1]
                                                    .lat,
                                                lng: route[route.length - 1]
                                                    .lng,
                                            }}
                                        />
                                    )}
                                </>
                            ))}
                    </GoogleMap>
                </div>
            </div>
        )
    })
)

function MapWrapper(props: IMap) {
    const { markers, polylines, height } = props
    return (
        <Map
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBTB6jEO3STC4cqA4pTPPCEvGvFqtJK_94&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: height || '60vh' }} />}
            mapElement={<div style={{ height: `100%` }} />}
            markers={markers}
            polylines={polylines}
            height={height}
        />
    )
}

export default MapWrapper

export { MapModal }
