import { CreateClubFormInterface } from '../components/FormClub'
import { axiosInstance } from './base.service'

export const fetchClubs = () => {
    return axiosInstance.get(`/clubs`)
}

export const fetchClubsSelect = () => {
    return axiosInstance.get(`/clubs/select`)
}

export const deleteClub = (clubId: string) => {
    return axiosInstance.delete(`/clubs/${clubId}`)
}

export const createClub = (values: CreateClubFormInterface) => {
    return axiosInstance.post(`/clubs`, values)
}

export const editClub = (clubId: string, values: CreateClubFormInterface) => {
    return axiosInstance.put(`/clubs/${clubId}`, values)
}

export const fetchMyClub = () => {
    return axiosInstance.get(`/clubs/myclub`)
}

export const editMyClub = (values: CreateClubFormInterface) => {
    return axiosInstance.put(`/clubs/myclub`, values)
}
