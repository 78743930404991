import React, { ReactNode } from 'react'
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

interface ModalConfirmProps {
    isOpen: boolean
    toggle: () => void
    onConfirm: () => void
    onCancel: () => void
    titleHeader: string
    title: string
    confirmButtonText?: string
    cancelButtonText?: string
    children?: ReactNode | ReactNode[]
    className?: string
}

function ModalConfirm({
    isOpen,
    toggle,
    onConfirm,
    onCancel,
    titleHeader,
    title,
    confirmButtonText,
    cancelButtonText,
    children,
    className,
}: ModalConfirmProps) {
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            className={className ?? 'modal-lg'}
        >
            <ModalHeader isOpen={isOpen} toggle={toggle}>
                {titleHeader}
            </ModalHeader>
            <ModalBody>
                <div className="text-center mt-2 mb-4">
                    <h5>{title}</h5>
                </div>
                {children}
                <Row>
                    <Col className="d-flex justify-content-end">
                        <Button
                            color="secondary"
                            onClick={onCancel}
                            style={{ marginRight: '10px' }}
                        >
                            {cancelButtonText}
                        </Button>
                        <Button color="danger" onClick={onConfirm}>
                            {confirmButtonText}
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

ModalConfirm.defaultProps = {
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    children: null,
    className: undefined,
}

export default ModalConfirm
