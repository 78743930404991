/** @format */

import { AxiosRequestConfig } from 'axios'
import { axiosFilesInstance, axiosInstance } from '../services/base.service'

export const triggerAxiosInterceptors = (logout: () => void) => {
    axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
        const request = config
        request.headers.authorization = `${localStorage.getItem('tokenId')}`
        return request
    })
    axiosInstance.interceptors.response.use(
        (response) => {
            if (response?.status === 401) {
                logout()
            }
            return response
        },
        (error) => {
            if (error.response?.status === 401) {
                logout()
            }
            return Promise.reject(error)
        }
    )

    // Files
    axiosFilesInstance.interceptors.request.use(
        (config: AxiosRequestConfig) => {
            const request = config
            request.headers.authorization = `${localStorage.getItem('tokenId')}`
            return request
        }
    )
    axiosFilesInstance.interceptors.response.use(
        (response) => {
            if (response?.status === 401) {
                logout()
            }
            return response
        },
        (error) => {
            if (error.response?.status === 401) {
                logout()
            }
            return Promise.reject(error)
        }
    )
}
