export enum TypeProfiles {
    ADMIN = 1,
    RACEMAKER = 2,
    USER = 3,
}

export enum TypeProfilesText {
    ADMIN = 'Administrador',
    RACEMAKER = 'Admin de Club',
    USER = 'Socio',
}

export enum TypesClubUser {
    PRESIDENTE = 1,
    VICE_PRESIDENTE = 2,
    TESORERO = 3,
    CONTACTO = 4,
    SOCIO = 5,
}

export enum TypesClubUserText {
    PRESIDENTE = 'Presidente',
    VICE_PRESIDENTE = 'Vice Presidente',
    TESORERO = 'Tesorero',
    CONTACTO = 'Contacto',
    SOCIO = 'Socio',
}

export interface User {
    id: string
    firstName: string
    lastName: string
    email: string
    username: string
    password?: string
    locationId: number
    clubId: number
    pigeonHouseId: number
    profile: TypeProfiles
    typeUserClub: TypesClubUser
}
