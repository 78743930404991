import { IPigeonHouse } from '../../models/pigeonHouse'
import { SelectInterface } from '../../models/select'
import { PigeonHousesFormInterface } from '../../components/FormPigeonHouses'

export const FETCH_PIGEON_HOUSES_START = 'FETCH_PIGEON_HOUSES_START'
export const FETCH_PIGEON_HOUSES_SUCCESS = 'FETCH_PIGEON_HOUSES_SUCCESS'
export const FETCH_PIGEON_HOUSES_FAILED = 'FETCH_PIGEON_HOUSES_FAILED'

export const FETCH_PIGEON_HOUSES_SELECT_START =
    'FETCH_PIGEON_HOUSES_SELECT_START'
export const FETCH_PIGEON_HOUSES_SELECT_SUCCESS =
    'FETCH_PIGEON_HOUSES_SELECT_SUCCESS'
export const FETCH_PIGEON_HOUSES_SELECT_FAILED =
    'FETCH_PIGEON_HOUSES_SELECT_FAILED'

export const CREATE_PIGEON_HOUSE_START = 'CREATE_PIGEON_HOUSE_START'
export const CREATE_PIGEON_HOUSE_SUCCESS = 'CREATE_PIGEON_HOUSE_SUCCESS'
export const CREATE_PIGEON_HOUSE_FAILED = 'CREATE_PIGEON_HOUSE_FAILED'

export const EDIT_PIGEON_HOUSE_START = 'EDIT_PIGEON_HOUSE_START'
export const EDIT_PIGEON_HOUSE_SUCCESS = 'EDIT_PIGEON_HOUSE_SUCCESS'
export const EDIT_PIGEON_HOUSE_FAILED = 'EDIT_PIGEON_HOUSE_FAILED'

export const DELETE_PIGEON_HOUSE_START = 'DELETE_PIGEON_HOUSE_START'
export const DELETE_PIGEON_HOUSE_SUCCESS = 'DELETE_PIGEON_HOUSE_SUCCESS'
export const DELETE_PIGEON_HOUSE_FAILED = 'DELETE_PIGEON_HOUSE_FAILED'

export const FETCH_MY_PIGEON_HOUSE_START = 'FETCH_MY_PIGEON_HOUSE_START'
export const FETCH_MY_PIGEON_HOUSE_SUCCESS = 'FETCH_MY_PIGEON_HOUSE_SUCCESS'
export const FETCH_MY_PIGEON_HOUSE_FAILED = 'FETCH_MY_PIGEON_HOUSE_FAILED'

export const EDIT_MY_PIGEON_HOUSE_START = 'EDIT_MY_PIGEON_HOUSE_START'
export const EDIT_MY_PIGEON_HOUSE_SUCCESS = 'EDIT_MY_PIGEON_HOUSE_SUCCESS'
export const EDIT_MY_PIGEON_HOUSE_FAILED = 'EDIT_MY_PIGEON_HOUSE_FAILED'

export const fetchPigeonHousesStart = (
    onLoad: (pigeonHouses: IPigeonHouse[]) => void
) => ({
    type: FETCH_PIGEON_HOUSES_START,
    payload: {
        onLoad,
    },
})

export const fetchPigeonHousesSuccess = (pigeonHouses: IPigeonHouse[]) => ({
    type: FETCH_PIGEON_HOUSES_SUCCESS,
    payload: pigeonHouses,
})

export const fetchPigeonHousesFailed = () => ({
    type: FETCH_PIGEON_HOUSES_FAILED,
})

export const fetchPigeonHousesSelectStart = (
    onLoad: (pigeonHouses: SelectInterface[]) => void
) => ({
    type: FETCH_PIGEON_HOUSES_SELECT_START,
    payload: {
        onLoad,
    },
})

export const fetchPigeonHousesSelectSuccess = (
    pigeonHouses: SelectInterface[]
) => ({
    type: FETCH_PIGEON_HOUSES_SELECT_SUCCESS,
    payload: pigeonHouses,
})

export const fetchPigeonHousesSelectFailed = () => ({
    type: FETCH_PIGEON_HOUSES_SELECT_FAILED,
})

export const deletePigeonHouseStart = (
    pigeonHouseId: string,
    onSuccess: () => void,
    onError: (message?: string) => void
) => ({
    type: DELETE_PIGEON_HOUSE_START,
    payload: { pigeonHouseId, onSuccess, onError },
})

export const deletePigeonHouseSuccess = () => ({
    type: DELETE_PIGEON_HOUSE_SUCCESS,
})

export const deletePigeonHouseFailed = () => ({
    type: DELETE_PIGEON_HOUSE_FAILED,
})

export const createPigeonHouseStart = (
    values: PigeonHousesFormInterface,
    onSuccess: () => void,
    onError: (message?: string) => void
) => ({
    type: CREATE_PIGEON_HOUSE_START,
    payload: {
        values,
        onSuccess,
        onError,
    },
})

export const createPigeonHouseSuccess = () => ({
    type: CREATE_PIGEON_HOUSE_SUCCESS,
})

export const createPigeonHouseFailed = () => ({
    type: CREATE_PIGEON_HOUSE_FAILED,
})

export const editPigeonHouseStart = (
    values: PigeonHousesFormInterface,
    pigeonHouseId: string,
    onSuccess: () => void,
    onError: (message?: string) => void
) => ({
    type: EDIT_PIGEON_HOUSE_START,
    payload: {
        values,
        pigeonHouseId,
        onSuccess,
        onError,
    },
})

export const editPigeonHouseSuccess = () => ({
    type: EDIT_PIGEON_HOUSE_SUCCESS,
})

export const editPigeonHouseFailed = () => ({
    type: EDIT_PIGEON_HOUSE_FAILED,
})

export const fetchMyPigeonHouseStart = (
    onLoad: (pigeonHouse: IPigeonHouse) => void
) => ({
    type: FETCH_MY_PIGEON_HOUSE_START,
    payload: {
        onLoad,
    },
})

export const fetchMyPigeonHouseSuccess = (pigeonHouse: IPigeonHouse) => ({
    type: FETCH_MY_PIGEON_HOUSE_SUCCESS,
    payload: pigeonHouse,
})

export const fetchMyPigeonHouseFailed = () => ({
    type: FETCH_MY_PIGEON_HOUSE_FAILED,
})

export const editMyPigeonHouseStart = (
    values: PigeonHousesFormInterface,
    onSuccess: () => void,
    onError: (message?: string) => void
) => ({
    type: EDIT_MY_PIGEON_HOUSE_START,
    payload: {
        values,
        onSuccess,
        onError,
    },
})

export const editMyPigeonHouseSuccess = () => ({
    type: EDIT_MY_PIGEON_HOUSE_SUCCESS,
})

export const editMyPigeonHouseFailed = () => ({
    type: EDIT_MY_PIGEON_HOUSE_FAILED,
})
